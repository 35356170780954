import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { RestService } from "./services/rest.service";

@Injectable({
  providedIn: "root",
})
export class SupportService {
  constructor(private httpClient: HttpClient, private http: RestService) {}

  public async downloadFile(url: string): Promise<void> {
    const blob = await this.httpClient.get(`${environment.apiUrl}/${url}`, { responseType: "blob", headers: this.http.getHeaders() }).toPromise();
    const objectUrl = window.URL.createObjectURL(blob);
    window.open(objectUrl, "_blank");
    window.URL.revokeObjectURL(objectUrl);
  }

  public addQueryParamsToUrl(url: string, queryParams?: Record<string, any>): string {
    const urlObj = new URL(url);

    if (queryParams == null) {
      return urlObj.pathname;
    }

    for (const [key, value] of Object.entries(queryParams)) {
      urlObj.searchParams.set(key, value);
    }

    return urlObj.pathname + urlObj.search;
  }
}
