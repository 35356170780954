<div class="container-fluid app-container arrival-table-container pt-2 pb-0 m-0 h-100 d-flex flex-column">
  <ng-container *ngIf="loading">
    <div class="d-flex align-items-center justify-content-center" role="status">
      <div class="spinner-border">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!loading">
    <div class="mb-1">
      <a [routerLink]="['/sign-contract', arrivalId]" i18n>&#8592; Back to contract select </a>
    </div>

    <div class="mb-1">
      <a [routerLink]="['/warehouse-history', arrivalId]" i18n>&#8592; Back to arrivals </a>
    </div>

    <ng-container *ngIf="signStep == SignStep.DONE">
      <div class="d-flex align-items-center justify-content-center">
        <p class="m-0" i18n>The contract has been signed. You may close this window now.</p>
      </div>
    </ng-container>

    <div *ngIf="signStep != SignStep.DONE" class="h-100">
      <div *ngIf="pdfFileString" class="d-flex h-100 flex-column">
        <div class="d-flex justify-content-between align-items-end pb-2">
          <div style="flex: 2">
            <h4 class="m-0" i18n>Contract</h4>
            <div>
              <p class="m-0" *ngIf="signStep == SignStep.SIGNATURE_PENDING" i18n>Please review the contract and press the "sign" button to sign it.</p>
              <p class="m-0" *ngIf="signStep == SignStep.REVIEW_SIGNATURE" i18n>
                You have signed the document. Please review the contract again and press the "confirm" button to confirm the signing or "sign again" to sign
                again.
              </p>
            </div>
          </div>

          <div style="flex: 1" class="d-flex justify-content-end">
            <button
              class="btn btn-info mr-1"
              i18n
              (click)="confirmSignature()"
              [disabled]="confirmSignatureInProgress || signingFileInProgress"
              *ngIf="signStep == SignStep.REVIEW_SIGNATURE"
            >
              Confirm
            </button>
            <button class="btn btn-info ml-1" [disabled]="confirmSignatureInProgress || signingFileInProgress" (click)="openSignModal(signModal)">
              <ng-container i18n>Sign</ng-container>
              &nbsp;
              <ng-container *ngIf="signStep == SignStep.REVIEW_SIGNATURE" i18n> again</ng-container>
            </button>
          </div>
        </div>

        <div style="border: 1px solid black; flex: 1; position: relative" class="mb-2">
          <pdf-viewer [src]="pdfFileString" [render-text]="true" [fit-to-page]="true" [show-borders]="true" style="display: block; height: 100%"></pdf-viewer>
        </div>
      </div>
      <div *ngIf="!pdfFileString">
        <p i18n>Insufficient parameters to show the PDF file.</p>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #signModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" i18n>Sign the contract</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss(false)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <app-signature-screen (drawFinish)="onDrawFinish($event)"></app-signature-screen>
  </div>

  <div class="modal-footer d-flex justify-content-between">
    <button type="submit" class="btn" (click)="modal.close(false)">
      <ng-container i18n="Cancel">Cancel</ng-container>
    </button>
    <button type="submit" class="btn btn-info" [disabled]="signatureFile == null || signingFileInProgress" (click)="signContract()">
      <ng-container i18n="Sign">Sign</ng-container>
    </button>
  </div>
</ng-template>
