import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../core/auth.service";

@Component({
  selector: "app-login-error",
  templateUrl: "./login-error.component.html",
  styleUrls: ["./login-error.component.css"],
})
export class LoginErrorComponent implements OnInit {
  constructor(public authService: AuthService) {}

  ngOnInit(): void {
    console.log("error page");
  }
}
