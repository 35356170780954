import { Injectable } from "@angular/core";
import { ScreenSize } from "./models/ScreenSize";

@Injectable({
  providedIn: "root",
})
export class RenderingService {
  private currentScreenLayout: ScreenSize;
  private currentScreenSize: number;

  constructor() {}

  setCurrentSize(size: number) {
    this.currentScreenSize = size;

    if (size < 768) {
      this.currentScreenLayout = ScreenSize.MOBILE;
    } else if (size < 1024) {
      this.currentScreenLayout = ScreenSize.TABLET;
    } else if (size >= 1024) {
      this.currentScreenLayout = ScreenSize.DESKTOP;
    }
  }

  isMobile() {
    return this.currentScreenLayout === ScreenSize.MOBILE;
  }

  isTablet() {
    return this.currentScreenLayout === ScreenSize.TABLET;
  }

  isDesktop() {
    return this.currentScreenLayout === ScreenSize.DESKTOP;
  }

  getCurrentSize() {
    return this.currentScreenSize;
  }
}
