<div>
  <fa-icon style="color: black" class="mr-2" [icon]="faQuestionCircle"></fa-icon>
  <span i18n>Find out for how long ramps were booked per day / week / month / year, and on which warehouse and which warehouse part.</span>
  <small i18n class="text-warning d-block">Calculating statistics may take some time, so it is recommended to do it in the less busy parts of the day.</small>
</div>

<div class="row mt-3">
  <div class="form-group col-3">
    <label for="dateFrom"><ng-container i18n>Date from</ng-container></label>
    <app-pick-date [date]="filter.dateFrom" (dateChange)="onDateChange($event, undefined)"></app-pick-date>
  </div>

  <div class="form-group col-3">
    <label for="dateTo"><ng-container i18n>Date to</ng-container></label>
    <app-pick-date [date]="filter.dateTo" (dateChange)="onDateChange(undefined, $event)"></app-pick-date>
  </div>

  <div class="form-group col-3">
    <label><ng-container i18n>Time period</ng-container></label>
    <select [(ngModel)]="filter.precision" class="form-control">
      <option [value]="BookedRampsStatsDatePrecision.DAILY">
        <ng-container i18n>Daily</ng-container>
      </option>
      <option [value]="BookedRampsStatsDatePrecision.WEEKLY">
        <ng-container i18n>Weekly</ng-container>
      </option>
      <option [value]="BookedRampsStatsDatePrecision.MONTHLY">
        <ng-container i18n>Monthly</ng-container>
      </option>
      <option [value]="BookedRampsStatsDatePrecision.YEARLY">
        <ng-container i18n>Yearly</ng-container>
      </option>
    </select>
  </div>
  <div class="form-group col-3">
    <div class="w-100 align-items-end d-flex justify-content-end h-100">
      <button i18n class="btn btn-primary" (click)="calculate()" [disabled]="isLoading">Calculate</button>
    </div>
  </div>
</div>

<div class="mt-2">
  <div *ngIf="isLoading" class="d-flex justify-content-center">
    <app-spinner></app-spinner>
  </div>

  <div *ngIf="!isLoading && !stats"><i i18n>Stats will appear here.</i></div>

  <div *ngIf="!isLoading && stats">
    <div class="d-flex">
      <b i18n>Total duration of bookings in selected range: </b>
      &nbsp;
      <span>{{ dateService.humanizeDuration(stats.totalBookingsSeconds * 1000) }}</span>
    </div>

    <div class="mt-2" *ngFor="let dayData of stats.data">
      <app-accordion-with-content i18n-label label="{{ formatDate(dayData.forDate, stats.precision) }}">
        <div class="d-flex mb-3">
          <b i18n>Total duration of bookings for {{ formatDate(dayData.forDate, stats.precision) }}: </b>
          &nbsp;
          <span>{{ dateService.humanizeDuration(dayData.totalBookingsSeconds * 1000) }}</span>
        </div>

        <div *ngFor="let warehouse of dayData.bookingsPerWarehouse">
          <div class="d-flex mb-3">
            <b i18n>Warehouse: </b>
            &nbsp;
            <span>
              {{ warehouse.warehouseName }}
            </span>
          </div>

          <div class="ml-2">
            <div class="d-flex mb-3">
              <b i18n>Total duration of bookings for warehouse {{ warehouse.warehouseName }}: </b>
              &nbsp;
              <span>{{ dateService.humanizeDuration(warehouse.totalBookingsSeconds * 1000) }}</span>
            </div>

            <div *ngFor="let warehousePart of warehouse.bookingsPerWarehousePart">
              <div class="d-flex mb-3">
                <b i18n>Warehouse part: </b>
                &nbsp;
                <span>
                  {{ warehousePart.warehousePartName }}
                </span>
              </div>

              <div class="ml-2">
                <div class="d-flex mb-3">
                  <b i18n>Total duration of bookings for warehouse part {{ warehousePart.warehousePartName }}: </b>
                  &nbsp;
                  <span>{{ dateService.humanizeDuration(warehousePart.totalBookingsSeconds * 1000) }}</span>
                </div>

                <div *ngFor="let ramp of warehousePart.bookingsPerRamp">
                  <div class="d-flex mb-3">
                    <b i18n>Ramp: </b>
                    &nbsp;
                    <span>
                      {{ ramp.rampName }}
                    </span>
                  </div>

                  <div class="mb-3 ml-2">
                    <div>
                      <b i18n>Total duration of bookings for ramp {{ ramp.rampName }}: </b>
                      &nbsp;
                      <span>{{ dateService.humanizeDuration(ramp.totalBookingsSeconds * 1000) }}</span>
                    </div>
                    <div>
                      <b i18n>Average duration of bookings for ramp {{ ramp.rampName }}: </b>
                      &nbsp;
                      <span>{{ dateService.humanizeDuration(ramp.avgBookingsSeconds * 1000) }}</span>
                    </div>
                    <div>
                      <b i18n>Minimum duration of bookings for ramp {{ ramp.rampName }}: </b>
                      &nbsp;
                      <span>{{ dateService.humanizeDuration(ramp.minBookingsSeconds * 1000) }}</span>
                    </div>
                    <div>
                      <b i18n>Maximum duration of bookings for ramp {{ ramp.rampName }}: </b>
                      &nbsp;
                      <span>{{ dateService.humanizeDuration(ramp.maxBookingsSeconds * 1000) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-accordion-with-content>
    </div>
  </div>
</div>
