import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../core/auth.service";
import { MessageTranslationService } from "../message-translation.service";
import { User } from "../models/User";
import { Warehouse } from "../models/WarehousePart";
import { RestService } from "../services/rest.service";

@Component({
  selector: "app-profile-yamas-settings",
  templateUrl: "./profile-yamas-settings.component.html",
  styleUrls: ["./profile-yamas-settings.component.css"],
})
export class ProfileYamasSettingsComponent implements OnInit {
  warehouse: number | null;
  warehouses: Warehouse[] = [];

  monthsBeforeExpiryWarning: number | null;
  canEditMonthsBeforeExpiryWarning: boolean = false;

  constructor(private http: RestService, private auth: AuthService, private toast: ToastrService, private msgT: MessageTranslationService) {}

  ngOnInit(): void {
    this.auth.hasLoggedInUserLoaded$.subscribe(async (isDone) => {
      if (isDone) {
        await this.getWarehouses();
        this.warehouse = this.auth.loggedInUser.defaultWarehouse ? this.auth.loggedInUser.defaultWarehouse.id : null;
        this.monthsBeforeExpiryWarning = this.auth.loggedInUser.company.monthsBeforeExpiryWarning;
        this.canEditMonthsBeforeExpiryWarning = this.auth.IsWarehouseAdmin();
      }
    });
  }

  async getWarehouses() {
    try {
      this.warehouses = await this.http.get<Warehouse[]>(`api/authentication/listWarehouses`).toPromise();
    } catch (error) {
      console.log("Find warehouses error");
      console.log(error);
    }
  }

  ChangeWarehouse() {
    this.http
      .post(`api/authentication/changeDefaultWarehouse`, {
        warehouseId: this.warehouse,
      })
      .subscribe(
        (u: User) => {
          this.auth.loggedInUser.defaultWarehouse = u.defaultWarehouse;
          this.toast.success(this.msgT.changeWarehouseSuccess());
        },
        (error) => {
          console.log("Change warehouse error");
          console.log(error.error);
          this.toast.error(this.msgT.changeWarehouseError());
        }
      );
  }

  ChangeMonthsBeforeExpiryWarning() {
    this.http
      .post(`api/company/changeMonthsBeforeExpiryWarning`, {
        monthsBeforeExpiryWarning: this.monthsBeforeExpiryWarning,
      })
      .subscribe(
        () => {
          this.toast.success(this.msgT.success());
        },
        (error) => {
          console.log("ChangeMonthsBeforeExpiryWarning error");
          console.log(error.error);
          this.toast.error(this.msgT.unknownError());
        }
      );
  }
}
