import { Country } from "./Country";
import { User } from "./User";

export interface DriverContractLanguage {
  id: number;
  name: string;
  countryIDs: number[];
  countries: Country[];
  createdAt: Date;
  addedBy: User;
}

export enum DriverContractType {
  ShortTerm = 0,
  LongTerm = 1,
}

export interface DriverContractTemplate {
  id: number;
  name: string;
  createdAt: Date;
  addedBy: User;
  contractLanguage: DriverContractLanguage;
  contractType: DriverContractType;
}
