import { Injectable } from "@angular/core";
import { User } from "./models/User";

@Injectable({
  providedIn: "root",
})
export class UserUtilsService {
  constructor() {}

  public isModified(user: User) {
    return user.name !== user.originalData.name;
  }

  public setOriginalData(user: User) {
    user.originalData = {
      name: user.name,
    };
  }

  public undoChanges(user: User) {
    user.name = user.originalData.name;
    user.isModified = false;
    user.filterString = this.searchString(user);
  }

  public searchString(user: User) {
    return (user.name + " " + user.email).toLowerCase();
  }

  public renderUserId(userId: string, brackets = false) {
    if (!userId) {
      return "";
    }

    if (brackets) {
      return `(${userId})`;
    }

    return userId;
  }
}
