import { RestService } from "../services/rest.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { MessageTranslationService } from "../message-translation.service";
import { ForbiddenDriver } from "../models/ForbiddenDriver";

@Component({
  selector: "app-forbidden-driver",
  templateUrl: "./forbidden-driver.component.html",
  styleUrls: ["./forbidden-driver.component.css"],
})
export class ForbiddenDriverComponent implements OnInit {
  driver: ForbiddenDriver;

  constructor(private route: ActivatedRoute, private http: RestService, private toast: ToastrService, private msgT: MessageTranslationService) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const id = params["id"];
      if (id) {
        this.http.get(`api/driver/getForbiddenDriver/${id}`).subscribe(
          (driver: ForbiddenDriver) => {
            this.driver = driver;
          },
          (error) => {
            console.log("Error fetching driver");
            console.log(error);
            this.toast.error(this.msgT.fetchDriverError());
          }
        );
      }
    });
  }
}
