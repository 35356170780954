<div class="container app-container">
  <ng-container *ngIf="arrival">
    <h1 class="app-heading">
      <ng-container i18n="Arrival">Arrival</ng-container>
    </h1>
    <div class="text-center mb-2">
      <button i18n class="btn btn-info mr-2" *ngIf="arrival.reservationId != null && arrival.reservationCode != null" (click)="navigateToReservation()">
        Reservation
      </button>
      <button i18n="Refresh" class="btn btn-primary" (click)="onRefreshClick()">Refresh</button>
    </div>
    <div class="text-center table-container">
      <table class="table table-borderless table-centralised" style="width: 100% !important">
        <tbody>
          <tr>
            <th><span i18n="Warehouse">Warehouse</span>:</th>
            <td>
              {{ arrival.warehouse.name
              }}<ng-container *ngIf="canChangeWarehouse(arrival)">
                (<span i18n style="text-decoration: underline; cursor: pointer" (click)="openChangeWarehouseModal(changeWarehouseContent, arrival)">change</span
                >)</ng-container
              >
            </td>
          </tr>
          <tr>
            <th><span i18n="Status">Status</span>:</th>
            <td>
              <div [ngSwitch]="arrival.status">
                <span *ngSwitchCase="ArrivalStatus.Arrived" i18n="Arrival">Arrival</span>
                <span *ngSwitchCase="ArrivalStatus.AtRamp">
                  <ng-container i18n="At ramp">At ramp</ng-container>
                  <span class="ramp-span">({{ arrival.currentRamp.name }} {{ arrival.warehouse.suffix }})</span>
                </span>

                <span *ngSwitchCase="ArrivalStatus.UnloadingStarted">
                  <ng-container i18n="Unloading start">Unloading start</ng-container>
                  <span *ngIf="arrival.currentRamp" class="ramp-span">({{ arrival.currentRamp.name }} {{ arrival.warehouse.suffix }})</span>
                </span>

                <span *ngSwitchCase="ArrivalStatus.UnloadingDone">
                  <ng-container i18n="Unloading end">Unloading done</ng-container>
                  <span *ngIf="arrival.arrivalsOnRamp && arrival.arrivalsOnRamp.length > 0" class="ramp-span"
                    >({{ arrival.arrivalsOnRamp[arrival.arrivalsOnRamp.length - 1].ramp.name }} {{ arrival.warehouse.suffix }})</span
                  >
                </span>

                <span *ngSwitchCase="ArrivalStatus.OnHold">
                  <ng-container i18n="On hold">On hold</ng-container>
                  <span *ngIf="arrival.arrivalsOnRamp && arrival.arrivalsOnRamp.length > 0" class="ramp-span"
                    >({{ arrival.arrivalsOnRamp[arrival.arrivalsOnRamp.length - 1].ramp.name }} {{ arrival.warehouse.suffix }})</span
                  >
                </span>

                <span *ngSwitchCase="ArrivalStatus.OnHoldEnd">
                  <ng-container i18n="Issue resolved">Issue resolved</ng-container>
                  <span *ngIf="arrival.arrivalsOnRamp && arrival.arrivalsOnRamp.length > 0" class="ramp-span"
                    >({{ arrival.arrivalsOnRamp[arrival.arrivalsOnRamp.length - 1].ramp.name }} {{ arrival.warehouse.suffix }})</span
                  >
                </span>

                <span *ngSwitchCase="ArrivalStatus.ReturnedToStart">
                  <ng-container i18n="Returned to start">Returned to start</ng-container>
                  <span *ngIf="arrival.arrivalsOnRamp && arrival.arrivalsOnRamp.length > 0" class="ramp-span"
                    >({{ arrival.arrivalsOnRamp[arrival.arrivalsOnRamp.length - 1].ramp.name }} {{ arrival.warehouse.suffix }})</span
                  >
                </span>

                <span *ngSwitchCase="ArrivalStatus.Departed" i18n="Departure">Departure</span>

                <span *ngSwitchCase="ArrivalStatus.EarlyDeparture" i18n="Early departure">Early departure</span>
              </div>
            </td>
          </tr>

          <tr *ngIf="arrival.status === ArrivalStatus.EarlyDeparture">
            <th>
              <ng-container i18n="See reason for early departure">Reason for early departure</ng-container>
            </th>

            <td>
              <span style="white-space: pre-line">
                {{ arrival.earlyDepartureReason }}
              </span>
            </td>
          </tr>

          <tr *ngIf="arrival.status !== ArrivalStatus.Departed && arrival.status !== ArrivalStatus.EarlyDeparture">
            <th>
              <ng-container *ngIf="!arrival.currentRamp"><span i18n="Select ramp">Select ramp</span>:</ng-container>
              <ng-container *ngIf="arrival.currentRamp"><span i18n="Current ramp">Current ramp</span>:</ng-container>
            </th>
            <td>
              <div class="d-flex">
                <button type="button" (click)="applyRampSelect(arrival)" *ngIf="arrival.selectedRamp" class="btn btn-primary d-block mr-2">
                  <ng-container i18n="Select ramp">Select ramp</ng-container>
                </button>
                <select
                  class="form-control"
                  style="width: auto"
                  *ngIf="arrival.currentRamp == null && auth.IsWarehouseManager()"
                  (change)="onRampSelectChange($event.target.value)"
                >
                  <option selected value>/</option>
                  <ng-container *ngIf="freeRampsPerWarehouse[arrival.warehouse.id] && freeRampsPerWarehouse[arrival.warehouse.id].length > 0">
                    <option *ngFor="let ramp of freeRampsPerWarehouse[arrival.warehouse.id]" [value]="ramp.id">
                      {{ ramp.name }} {{ arrival.warehouse.suffix }}
                    </option>
                  </ng-container>
                  <option *ngIf="!freeRampsPerWarehouse[arrival.warehouse.id] || freeRampsPerWarehouse[arrival.warehouse.id].length === 0" disabled>
                    <ng-container i18n="No free ramps">None</ng-container>
                  </option>
                </select>
              </div>
              <span *ngIf="arrival.currentRamp" class="ramp-span">{{ arrival.currentRamp.name }} {{ arrival.warehouse.suffix }}</span>
              <ng-container *ngIf="!arrival.currentRamp && !auth.IsWarehouseManager()">/</ng-container>
            </td>
          </tr>
          <br />
          <tr>
            <th><span i18n="Date of arrival">Date of arrival</span>:</th>
            <td>{{ arrival.arrivalTime | date: "dd.MM.yyyy" }}</td>
          </tr>
          <tr>
            <th><span i18n="Time of arrival">Time of arrival</span>:</th>

            <td>
              <fa-icon style="color: green" [icon]="faCheck"></fa-icon>&nbsp;
              {{ arrival.arrivalTime | date: "HH:mm" }}
              <ng-container i18n="by">by</ng-container>&nbsp;
              <span>
                {{ arrival.gateManagerMarkedArrival.name }}
              </span>
            </td>
          </tr>

          <tr>
            <th><span i18n="Time of departure">Time of departure</span>:</th>

            <td *ngIf="arrival.departureTime && arrival.gateManagerMarkedDeparture">
              <fa-icon style="color: green" [icon]="faCheck"></fa-icon>&nbsp;
              {{ arrival.departureTime | date: "HH:mm" }}
              <ng-container i18n="by">by</ng-container>&nbsp;
              <span>
                {{ arrival.gateManagerMarkedDeparture.name }}
              </span>
            </td>

            <td *ngIf="!arrival.departureTime || !arrival.gateManagerMarkedDeparture">
              <fa-icon style="color: red" [icon]="faTimes"></fa-icon>
            </td>
          </tr>

          <ng-container *ngIf="arrival.arrivalsOnRamp">
            <ng-container *ngFor="let ar of arrival.arrivalsOnRamp">
              <tr>
                <th class="unset-padding-th">
                  <h5 class="margin-top-h5">
                    <ng-container i18n="Ramp">Ramp</ng-container>
                    <span class="ramp-span">{{ ar.ramp.name }} {{ arrival.warehouse.suffix }}</span>
                  </h5>
                </th>
              </tr>

              <tr>
                <th class="unset-padding-th"><span i18n>Time of unloading start</span>:</th>

                <td class="unset-padding-th">
                  <ng-container *ngIf="ar.unloadingStartTime && ar.warehouseManagerMarkedUnloadingStart">
                    <ng-template #warehouseManagerMarkedUnloadingStartTip>{{ ar.warehouseManagerMarkedUnloadingStart.name }}</ng-template>

                    <fa-icon style="color: green" [icon]="faCheck"></fa-icon>&nbsp; {{ ar.unloadingStartTime | date: "HH:mm" }}
                    <ng-container i18n="by">by</ng-container>&nbsp;
                    <span [ngbTooltip]="warehouseManagerMarkedUnloadingStartTip">
                      {{
                        ar.warehouseManagerMarkedUnloadingStartUserId
                          ? userUtils.renderUserId(ar.warehouseManagerMarkedUnloadingStartUserId)
                          : ar.warehouseManagerMarkedUnloadingStart.name
                      }}
                    </span>
                  </ng-container>

                  <ng-container *ngIf="!(ar.unloadingStartTime && ar.warehouseManagerMarkedUnloadingStart)">
                    <fa-icon style="color: red" [icon]="faTimes"></fa-icon>
                  </ng-container>
                </td>
              </tr>

              <tr *ngIf="ar.onHoldTime && ar.warehouseManagerMarkedOnHold">
                <th class="unset-padding-th"><span i18n="Put on hold">Put on hold</span>:</th>

                <td class="unset-padding-th">
                  <ng-container>
                    <ng-template #warehouseManagerMarkedOnHoldTip>{{ ar.warehouseManagerMarkedOnHold.name }}</ng-template>

                    <fa-icon style="color: red" [icon]="faExclamationTriangle"></fa-icon>&nbsp; {{ ar.onHoldTime | date: "HH:mm" }}
                    <ng-container i18n="by">by</ng-container>&nbsp;
                    <span [ngbTooltip]="warehouseManagerMarkedOnHoldTip">
                      {{
                        ar.warehouseManagerMarkedOnHoldUserId
                          ? userUtils.renderUserId(ar.warehouseManagerMarkedOnHoldUserId)
                          : ar.warehouseManagerMarkedOnHold.name
                      }}
                    </span>
                  </ng-container>
                </td>
              </tr>

              <tr *ngIf="ar.onHoldEndTime && ar.warehouseManagerMarkedOnHoldEnd">
                <th class="unset-padding-th"><span i18n="Issue resolved">Issue resolved</span>:</th>

                <td class="unset-padding-th">
                  <ng-container>
                    <ng-template #warehouseManagerMarkedOnHoldEndTip>{{ ar.warehouseManagerMarkedOnHoldEnd.name }}</ng-template>

                    <fa-icon style="color: green" [icon]="faExclamationTriangle"></fa-icon>&nbsp; {{ ar.onHoldEndTime | date: "HH:mm" }}
                    <ng-container i18n="by">by</ng-container>&nbsp;
                    <span [ngbTooltip]="warehouseManagerMarkedOnHoldEndTip">
                      {{
                        ar.warehouseManagerMarkedOnHoldEndUserId
                          ? userUtils.renderUserId(ar.warehouseManagerMarkedOnHoldEndUserId)
                          : ar.warehouseManagerMarkedOnHoldEnd.name
                      }}
                    </span>
                  </ng-container>
                </td>
              </tr>

              <tr *ngIf="ar.returnedToStartTime && ar.warehouseManagerMarkedReturnedToStart">
                <th class="unset-padding-th"><span i18n="Returned to start">Returned to start</span>:</th>

                <td class="unset-padding-th">
                  <ng-container>
                    <ng-template #warehouseManagerMarkedReturnedToStartTip>{{ ar.warehouseManagerMarkedReturnedToStart.name }}</ng-template>

                    <fa-icon style="color: orange" [icon]="faUndo"></fa-icon>
                    &nbsp; {{ ar.returnedToStartTime | date: "HH:mm" }} <ng-container i18n="by">by</ng-container>&nbsp;
                    <span [ngbTooltip]="warehouseManagerMarkedReturnedToStartTip">
                      {{
                        ar.warehouseManagerMarkedReturnedToStartUserId
                          ? userUtils.renderUserId(ar.warehouseManagerMarkedReturnedToStartUserId)
                          : ar.warehouseManagerMarkedReturnedToStart.name
                      }}
                    </span>
                  </ng-container>
                </td>
              </tr>

              <tr *ngIf="!(ar.returnedToStartTime && ar.warehouseManagerMarkedReturnedToStart)">
                <th class="unset-padding-th"><span i18n="Time of unloading end">Time of unloading end</span>:</th>

                <td class="unset-padding-th">
                  <ng-container *ngIf="ar.unloadingEndTime && ar.warehouseManagerMarkedUnloadingEnd">
                    <ng-template #warehouseManagerMarkedUnloadingEndTip>{{ ar.warehouseManagerMarkedUnloadingEnd.name }}</ng-template>

                    <fa-icon style="color: green" [icon]="faCheck"></fa-icon>&nbsp; {{ ar.unloadingEndTime | date: "HH:mm" }}
                    <ng-container i18n="by">by</ng-container>&nbsp;
                    <span [ngbTooltip]="warehouseManagerMarkedUnloadingEndTip">
                      {{
                        ar.warehouseManagerMarkedUnloadingEndUserId
                          ? userUtils.renderUserId(ar.warehouseManagerMarkedUnloadingEndUserId)
                          : ar.warehouseManagerMarkedUnloadingEnd.name
                      }}
                    </span>
                  </ng-container>

                  <ng-container *ngIf="!(ar.unloadingEndTime && ar.warehouseManagerMarkedUnloadingEnd)">
                    <fa-icon style="color: red" [icon]="faTimes"></fa-icon>
                  </ng-container>
                </td>
              </tr>

              <tr *ngIf="ar.unloadingEndTime && ar.unloadingStartTime">
                <th class="unset-padding-th"><span i18n>Duration on ramp</span>:</th>

                <td class="unset-padding-th">{{ getDurationOnRamp(ar.unloadingStartTime, ar.unloadingEndTime) }}</td>
              </tr>
            </ng-container>
          </ng-container>

          <br />

          <ng-container *ngIf="arrival.driver.contract == null && arrival.contractMarkedAsUploaded === false">
            <tr>
              <th>
                <ng-container i18n="Driver hasn't signed a contract yet">Driver hasn't signed a contract yet</ng-container>
              </th>
              <td></td>
            </tr>
            <ng-container *ngIf="auth.IsWarehouseManager() && arrival.status !== ArrivalStatus.EarlyDeparture">
              <tr>
                <th colspan="2">
                  <button type="button" (click)="onMarkContractUploadedClick()" class="btn btn-primary" i18n="Mark contract as signed">
                    Mark contract as signed
                  </button>
                </th>
                <td></td>
              </tr>
            </ng-container>
          </ng-container>
          <br />
          <!-- long term contract -->
          <ng-container *ngIf="arrival.driver.contract != null">
            <tr>
              <th>
                <ng-container i18n="Driver ID">Driver ID</ng-container>
                :
              </th>
              <td>{{ arrival.driver.code }}</td>
            </tr>
            <br />
            <tr>
              <th>
                <ng-container i18n="Long term contract uploaded by">Long term contract uploaded by</ng-container>
                :
              </th>
              <td>
                <span>{{ arrival.driver.contract.uploadedBy.name }}</span>
              </td>
            </tr>
            <tr>
              <th>
                <ng-container i18n="Contract signed on">Long term contract signed on</ng-container>
                :
              </th>
              <td>
                <span>{{ arrival.driver.contract.dateSigned | date: "dd.MM.yyyy" }}</span>
              </td>
            </tr>
            <ng-container *ngIf="!arrival.driver.contract.hasExpired">
              <tr>
                <th><span i18n="Contract expires on">Contract expires on</span>:</th>
                <td>
                  <span [ngStyle]="{ color: contractExpiryColor(arrival.driver) }"
                    >{{ arrival.driver.contract.dateExpires | date: "dd.MM.yyyy" }} ({{ contractExpiryText(arrival.driver) }})</span
                  >
                </td>
              </tr>
            </ng-container>
            <ng-container *ngIf="arrival.driver.contract.hasExpired">
              <tr>
                <th><span i18n="Contract expired on">Contract expired on</span>:</th>
                <td>
                  <span [ngStyle]="{ color: contractExpiryColor(arrival.driver) }"
                    >{{ arrival.driver.contract.dateExpires | date: "dd.MM.yyyy" }} ({{ contractExpiryText(arrival.driver) }})</span
                  >
                </td>
              </tr>
              <tr *ngIf="auth.IsWarehouseManager() && arrival.status !== ArrivalStatus.EarlyDeparture">
                <th colspan="2">
                  <button type="button" (click)="onMarkContractUploadedClick()" class="btn btn-primary" i18n="Mark contract as renewed">
                    Mark contract as renewed
                  </button>
                </th>
              </tr>
            </ng-container>
            <tr *ngIf="arrival.driver.contract.filePath">
              <th colspan="2">
                <button type="button" (click)="onViewContractClick(arrival.driver)" class="btn btn-primary" i18n="View long term contract">
                  View long term contract
                </button>
              </th>
            </tr>
          </ng-container>
          <!-- marked as uploaded -->
          <ng-container *ngIf="arrival.driver.contract == null && arrival.contractMarkedAsUploaded === true">
            <tr>
              <th>
                <ng-container i18n="Contract was marked as uploaded, but no long term contract exists"
                  >Contract was marked as uploaded, but no long term contract exists</ng-container
                >
                .
              </th>
              <td></td>
            </tr>
            <tr>
              <th><span i18n="Contract marked as uploaded by">Contract marked as uploaded by</span>:</th>
              <td>
                <span>{{ arrival.contractMarkedAsUploadedBy.name }} {{ userUtils.renderUserId(arrival.contractMarkedAsUploadedByUserId) }}</span>
              </td>
            </tr>
          </ng-container>

          <br />

          <tr>
            <th><span i18n="Transport company">Transport company</span>:</th>
            <td>
              <span>{{ arrival.driver.transportCompany }}</span>
            </td>
          </tr>
          <tr>
            <th><span i18n="Driver name">Driver name</span>:</th>
            <td>
              <span>{{ arrival.driver.name }}</span>
            </td>
          </tr>
          <tr>
            <th><span i18n="Company country">Company country</span>:</th>
            <td>
              <span>{{ arrival.driver.transportCompanyCountry.name }}</span>
            </td>
          </tr>
          <tr>
            <th><span i18n="Registration number">Registration number</span>:</th>
            <td>
              <span>{{ arrival.registrationNumber }}</span>
            </td>
          </tr>
          <br />
          <tr>
            <th><span i18n="Country of loading">Country of loading</span>:</th>
            <td>
              <span>{{ arrival.loadingCountry.name }}</span>
            </td>
          </tr>
          <br />
          <tr>
            <th><span i18n="Comment">Comment</span>:</th>
            <td>
              <span style="white-space: pre-line">{{ arrival.comment }}</span>
            </td>
          </tr>
          <br />
        </tbody>
      </table>
      <p *ngIf="!arrivalUtils.processingAllowed(arrival)">
        <span i18n="No actions currently available">No actions currently available</span>:
        <ng-container [ngSwitch]="arrivalUtils.processingDisallowedText(arrival)">
          <span *ngSwitchCase="ProcessingStatus.DRIVER_DEPARTED" i18n="Driver has departed">Driver has departed</span>
          <span *ngSwitchCase="ProcessingStatus.DRIVER_NO_CONTRACT" i18n="Driver hasn't signed a contract yet">Driver hasn't signed a contract yet</span>
          <span *ngSwitchCase="ProcessingStatus.CONTRACT_EXPIRED" i18n="Contract has expired">Contract has expired</span>
          <span *ngSwitchCase="ProcessingStatus.UNAUTHORIZED" i18n="Unauthorized to perform next action">Unauthorized to perform next action</span>
          <span *ngSwitchCase="ProcessingStatus.NO_RAMP" i18n="No ramp selected">No ramp selected</span>
        </ng-container>
      </p>

      <button
        type="button"
        (click)="open(earlyDepartureContent)"
        *ngIf="arrivalUtils.earlyDepartureAllowed(arrival)"
        class="btn btn-warning d-block mb-1 btn-wrap"
        i18n="Early departure"
      >
        Early departure
      </button>

      <button
        type="button"
        (click)="onProcessButtonClick()"
        *ngIf="arrivalUtils.processingAllowed(arrival) && arrival.status != ArrivalStatus.OnHold"
        class="btn btn-primary"
      >
        <ng-container>
          <ng-container>
            <ng-container *ngIf="arrival.status === ArrivalStatus.Arrived || arrival.status === ArrivalStatus.AtRamp" i18n="Unloading start"
              >Unloading start</ng-container
            >

            <ng-container *ngIf="arrival.status === ArrivalStatus.UnloadingStarted || arrival.status === ArrivalStatus.OnHoldEnd" i18n="Unloading done"
              >Unloading done</ng-container
            >

            <ng-container *ngIf="arrival.status === ArrivalStatus.UnloadingDone || arrival.status === ArrivalStatus.ReturnedToStart" i18n="Departure"
              >Departure</ng-container
            >
          </ng-container>

          <ng-container *ngIf="arrival.currentRamp">&nbsp;({{ arrival.currentRamp.name }} {{ arrival.warehouse.suffix }})</ng-container>
        </ng-container>
      </button>

      <button
        type="button"
        (click)="onHoldButtonClick()"
        *ngIf="arrivalUtils.processingAllowed(arrival) && (arrival.status == ArrivalStatus.UnloadingStarted || arrival.status == ArrivalStatus.OnHold)"
        class="btn btn-primary mt-2"
        [ngClass]="{
          'btn-danger': arrival.status == ArrivalStatus.UnloadingStarted,
          'btn-success': arrival.status == ArrivalStatus.OnHold
        }"
      >
        <ng-container>
          <ng-container [ngSwitch]="arrival.status">
            <ng-container *ngSwitchCase="ArrivalStatus.UnloadingStarted" i18n="On hold">On hold</ng-container>

            <ng-container *ngSwitchCase="ArrivalStatus.OnHold" i18n="Issue resolved">Issue resolved</ng-container>
          </ng-container>

          <ng-container *ngIf="arrival.currentRamp">&nbsp;({{ arrival.currentRamp.name }} {{ arrival.warehouse.suffix }})</ng-container>
        </ng-container>
      </button>

      <button type="button" (click)="onReturnedToStartClick()" class="btn btn-warning d-block mt-2 btn-wrap" *ngIf="arrival.status === ArrivalStatus.OnHold">
        <ng-container i18n="Return to start">Return to start</ng-container>
        <br />
      </button>

      <button
        type="button"
        (click)="onUndoButtonClick(arrival)"
        *ngIf="arrivalUtils.undoAllowed(arrival) && auth.IsWarehouseAdmin()"
        class="btn btn-warning d-block mt-3 btn-wrap"
      >
        <ng-container>
          <ng-container i18n="Undo">Undo</ng-container>:
          <ng-container [ngSwitch]="arrival.status">
            <ng-container *ngSwitchCase="ArrivalStatus.AtRamp" i18n="Select ramp">Select ramp</ng-container>
            <ng-container *ngSwitchCase="ArrivalStatus.UnloadingStarted" i18n="Unloading start">Unloading start</ng-container>
            <ng-container *ngSwitchCase="ArrivalStatus.OnHold" i18n="On hold">On hold</ng-container>
            <ng-container *ngSwitchCase="ArrivalStatus.OnHoldEnd" i18n="Issue resolved">Issue resolved</ng-container>
            <ng-container *ngSwitchCase="ArrivalStatus.UnloadingDone" i18n="Unloading done">Unloading done</ng-container>
            <ng-container *ngSwitchCase="ArrivalStatus.ReturnedToStart" i18n="Return to start">Return to start</ng-container>
            <ng-container *ngSwitchCase="ArrivalStatus.Departed" i18n="Departure">Departure</ng-container>
            <ng-container *ngSwitchCase="ArrivalStatus.EarlyDeparture" i18n="Early departure">Early departure</ng-container>
          </ng-container>
        </ng-container>
        <br />
      </button>

      <button type="button" (click)="open(content)" *ngIf="auth.IsWarehouseAdmin()" class="btn btn-danger mt-4" i18n="Delete arrival">Delete arrival</button>

      <button
        type="button"
        (click)="open(commentContent)"
        *ngIf="auth.IsWarehouseManager() || auth.IsGateManager()"
        class="btn btn-primary mt-4"
        i18n="Add comment"
      >
        Add comment
      </button>
    </div>

    <ng-template #content let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" i18n="Delete arrival">Delete arrival</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss(false)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <p i8n="Delete arrival? This action is irreversible!">Delete arrival? This action is irreversible!</p>
      </div>

      <div class="modal-footer d-flex justify-content-between">
        <button type="submit" class="btn" (click)="modal.close(false)">
          <ng-container i18n="Cancel">No</ng-container>
        </button>
        <button type="submit" class="btn btn-primary" (click)="RemoveArrival()">
          <ng-container i18n="Add">Yes</ng-container>
        </button>
      </div>
    </ng-template>

    <ng-template #commentContent let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" i18n="Add comment">Add comment</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss(false)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="form-group">
          <label for="reason"><ng-container i18n="add comment">add comment</ng-container></label>
          <textarea
            id="Comment"
            placeholder="Add a comment"
            i18n-placeholder
            class="md-textarea form-control"
            rows="3"
            [(ngModel)]="commentInput"
            [ngModelOptions]="{ standalone: true }"
          ></textarea>
        </div>
      </div>

      <div class="modal-footer d-flex justify-content-between">
        <button type="submit" class="btn" (click)="modal.close(false)">
          <ng-container i18n="Cancel">Cancel</ng-container>
        </button>
        <button type="button" class="btn btn-primary" (click)="onSaveCommentClick()">
          <ng-container i18n="Save">Save</ng-container>
        </button>
      </div>
    </ng-template>

    <ng-template #earlyDepartureContent let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" i18n="Mark early departure">Mark early departure</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss(false)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="form-group">
          <label for="reason"><ng-container i18n="Reason">Reason</ng-container></label>
          <textarea
            id="reason"
            placeholder="Enter reason"
            i18n-placeholder
            class="md-textarea form-control"
            rows="3"
            [(ngModel)]="arrival.earlyDepartureReason"
            [ngModelOptions]="{ standalone: true }"
          ></textarea>
        </div>
      </div>

      <div class="modal-footer d-flex justify-content-between">
        <button type="submit" class="btn" (click)="modal.close(false)">
          <ng-container i18n="Cancel">Cancel</ng-container>
        </button>
        <button
          type="submit"
          class="btn btn-warning"
          (click)="onEarlyDepartureClick()"
          [disabled]="!arrival.earlyDepartureReason || arrival.earlyDepartureReason.length === 0"
        >
          <ng-container i18n="Mark early departure"> Mark early departure </ng-container>
        </button>
      </div>
    </ng-template>

    <ng-template #changeWarehouseContent let-changeWarehouseModal>
      <ng-container *ngIf="changeWarehouseId">
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title" i18n="Change warehouse">Change warehouse</h4>
          <button type="button" class="close" aria-label="Close" (click)="changeWarehouseModal.dismiss(false)">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="form-group">
            <label for="type"><ng-container i18n="Warehouse">Warehouse</ng-container></label>
            <select [(ngModel)]="changeWarehouseId" id="type" name="type" class="form-control">
              <option [value]="w.id" *ngFor="let w of warehouses">
                <ng-container>{{ w.name }}</ng-container>
              </option>
            </select>
          </div>
        </div>

        <div class="modal-footer d-flex justify-content-between">
          <button type="submit" class="btn" (click)="changeWarehouseModal.close(false)">
            <ng-container i18n="Close">Close</ng-container>
          </button>
          <button type="submit" class="btn btn-primary" (click)="saveChangedWarehouse()">
            <ng-container i18n="Save">Save</ng-container>
          </button>
        </div>
      </ng-container>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="!arrival">
    <ng-container i18n="No arrival found">No arrival found</ng-container>
  </ng-container>
</div>
