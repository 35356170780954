import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { merge, Observable, OperatorFunction, Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from "rxjs/operators";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-autocomplete-input",
  templateUrl: "./autocomplete-input.component.html",
  styleUrls: ["./autocomplete-input.component.css"],
})
export class AutocompleteInputComponent implements OnInit {
  @Input() data: any[] = [];

  @Output() onClear: EventEmitter<void> = new EventEmitter<void>();
  @Output() onChange: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSelect: EventEmitter<string> = new EventEmitter<string>();
  @Output() onKeydownEnter: EventEmitter<void> = new EventEmitter<void>();

  @Input() searchKeyword: string | null = null;
  @Input() editable: boolean = true;

  @Input() value: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("instance", { static: true }) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  faTimes = faTimes;
  model: any;

  formatter = (x: any) => {
    if (this.searchKeyword != null) {
      return x[this.searchKeyword];
    }

    return x;
  };

  search: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ""
          ? this.data
          : this.data.filter(
              (v) =>
                this.formatter(v).toLowerCase().indexOf(term.toLowerCase()) > -1
            )
        ).slice(0, 30)
      )
    );
  };

  constructor() {}

  ngOnInit(): void {}
}
