import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-confirmation-modal",
  templateUrl: "./confirmation-modal.component.html",
  styleUrls: ["./confirmation-modal.component.css"],
})
export class ConfirmationModalComponent implements OnInit {
  @Input() header: string = "";
  @Input() subHeader: string = "";
  @Input() cancelButtonText: string = "";
  @Input() confirmButtonText: string = "";

  @Input() cancelButtonClass: string = "";
  @Input() confirmButtonClass: string = "btn-info";

  @Input() isLoading: boolean = false;

  @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() onConfirm: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
