<div class="c-pointer" style="user-select: none" (click)="isOpen = !isOpen">
  <div class="d-flex align-items-center justify-content-between">
    <h4 class="subheading m-0 p-0" i18n>{{ label }}</h4>
    <fa-icon [icon]="isOpen ? faChevronUp : faChevronDown"></fa-icon>
  </div>
  <hr class="mt-1 mb-2" />
</div>

<div [class.d-none]="!isOpen">
  <ng-content></ng-content>
</div>
