<div class="container-fluid app-container arrival-table-container">
  <h1 class="app-heading">
    <ng-container i18n="Drivers">Drivers</ng-container>
  </h1>

  <ng-container *ngIf="filteredDrivers">
    <div class="d-flex" class="table-filter-container">
      <input
        class="form-control table-filter-input"
        i18n-placeholder="Filter..."
        placeholder="Filter..."
        type="text"
        [(ngModel)]="filterText"
        (keyup.enter)="onSearchParamsChange()"
      />
      <button class="btn btn-primary table-refresh-btn" i18n="Search" (click)="onSearchParamsChange()">Search</button>
    </div>

    <div class="form-check form-check-inline mb-3">
      <input
        class="form-check-input"
        type="checkbox"
        id="showOnlyDriversWithLongTermContractSignedCheckbox"
        [(ngModel)]="showOnlyDriversWithLongTermContractSigned"
        (ngModelChange)="onSearchParamsChange()"
      />
      <label class="form-check-label" for="showOnlyDriversWithLongTermContractSignedCheckbox">
        <ng-container i18n>Show only drivers with long term contract signed</ng-container>
      </label>
    </div>

    <div *ngIf="loading" class="mx-2 d-flex justify-content-center align-items-center">
      <div class="spinner-border" *ngIf="!isLoaded">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <ng-container *ngIf="!loading">
      <ng-container *ngIf="filteredDrivers.length > 0">
        <div class="table-responsive" *ngIf="renderingService.isDesktop()">
          <p i18n="Double click on driver table cells to change information">
            Double click on table cells 'Name', 'Company' or 'Country' to change information.
          </p>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th i18n="ID">ID</th>
                <th i18n="Name">Name</th>
                <th i18n="Email">Email</th>
                <th i18n="Date added">Date added</th>
                <th i18n="Company">Company</th>
                <th i18n="Company country">Company country</th>
                <th i18n="Contract information">Contract information</th>
                <th i18n="Upload / renew contract">Upload / renew contract</th>
                <th i18n="Actions">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let driver of filteredDrivers">
                <td>
                  <editable (update)="OnUpdateDriver(driver)" (modeChange)="OnEditableModeChange(driver)">
                    <ng-template viewMode>
                      <ng-container *ngIf="driver.code && driver.code.length > 0">{{ driver.code }}</ng-container>
                      <ng-container *ngIf="!driver.code || driver.code.length === 0">/</ng-container>

                      <div style="color: orange" class="mt-2" *ngIf="isIdValid(driver) === false">
                        <fa-icon [icon]="faExclamationTriangle"></fa-icon>
                        <span style="margin-left: 5px" i18n>ID should be in format [number]_[year]</span>
                      </div>
                    </ng-template>
                    <ng-template editMode>
                      <input focusable editableOnEnter [(ngModel)]="driver.code" />
                    </ng-template>
                  </editable>
                </td>

                <td>
                  <editable (update)="OnUpdateDriver(driver)">
                    <ng-template viewMode>{{ driver.name }}</ng-template>
                    <ng-template editMode>
                      <input focusable editableOnEnter [(ngModel)]="driver.name" />
                    </ng-template>
                  </editable>
                </td>

                <td>
                  <editable (update)="OnUpdateDriver(driver)">
                    <ng-template viewMode>{{ driver.email }}</ng-template>
                    <ng-template editMode>
                      <input focusable editableOnEnter [(ngModel)]="driver.email" />
                    </ng-template>
                  </editable>
                </td>

                <td>
                  {{ toLocalTime(driver.createdAt) | date: "dd.MM.yyyy" }}
                </td>

                <td>
                  <editable (update)="OnUpdateDriver(driver)">
                    <ng-template viewMode>{{ driver.transportCompany }}</ng-template>
                    <ng-template editMode>
                      <input focusable editableOnEnter [(ngModel)]="driver.transportCompany" />
                    </ng-template>
                  </editable>
                </td>

                <td>
                  <editable (update)="OnUpdateDriver(driver)" (modeChange)="OnEditableModeChange(driver)">
                    <ng-template viewMode>{{ driver.transportCompanyCountry.name }}</ng-template>
                    <ng-template editMode>
                      <div class="autocomplete-container">
                        <ng-autocomplete
                          [(ngModel)]="driver.transportCompanyCountryInput"
                          [searchKeyword]="'name'"
                          (selected)="onSelectTransportCompanyCountry(driver, $event)"
                          (inputChanged)="onSelectTransportCompanyCountry(driver, null)"
                          (inputCleared)="onSelectTransportCompanyCountry(driver, null)"
                          [data]="countriesService.countries"
                          [itemTemplate]="itemTemplateCountry"
                        >
                        </ng-autocomplete>

                        <ng-template #itemTemplateCountry let-item>
                          <a [innerHTML]="item.name"></a>
                        </ng-template>
                      </div>
                    </ng-template>
                  </editable>
                </td>

                <td>
                  <ng-container *ngIf="driver.contract">
                    <span><ng-container i18n="Signed at">Signed at</ng-container>: {{ driver.contract.dateSigned | date: "dd.MM.yyyy" }}</span>
                    <br />
                    <span [ngStyle]="{ color: contractExpiryColor(driver) }">
                      <ng-container *ngIf="driver.contract.hasExpired" i18n="Expired">Expired</ng-container>
                      <ng-container *ngIf="!driver.contract.hasExpired" i18n="Expired">Expires</ng-container>:
                      {{ driver.contract.dateExpires | date: "dd.MM.yyyy" }}
                    </span>
                    <br />
                    <span [ngStyle]="{ color: contractExpiryColor(driver) }">({{ contractExpiryText(driver) }})</span>
                    <span style="color: blue; cursor: pointer; text-decoration: underline; display: block" i18n (click)="openContractFile(driver.contract)"
                      ><a>View contract</a></span
                    >
                  </ng-container>

                  <ng-container *ngIf="driver.contract == null">
                    <ng-container i18n="No long term contract yet">No long term contract yet</ng-container>.
                  </ng-container>
                </td>

                <td>
                  <input #contractInput type="file" accept="application/pdf" (change)="processContract(contractInput, driver)" class="file-ipt-margin-bottom" />
                  <br />
                  <div style="margin-bottom: 5px">
                    <b style="margin-right: 5px"><ng-container i18n="Date signed">Date signed</ng-container>:</b>
                    <input type="text" [(ngModel)]="driver.driverListDateSigned" i18n-placeholder="Enter date" placeholder="Enter date" />
                  </div>
                </td>

                <td>
                  <ng-container *ngIf="driver.contractReadyForUpload">
                    <button class="btn btn-primary mb-1" (click)="onUploadContractClick(driver)" *ngIf="driver.contract == null" i18n>
                      Upload long term contract
                    </button>
                    <button class="btn btn-primary mb-1" (click)="onUploadContractClick(driver)" *ngIf="driver.contract != null" i18n="Renew contract">
                      Renew contract
                    </button>
                  </ng-container>
                  <ng-container *ngIf="driver.isModified">
                    <button type="button" (click)="ApplyChanges(driver)" [disabled]="isIdValid(driver) === false" class="btn btn-primary d-block mb-1">
                      <ng-container i18n="Apply changes">Apply changes</ng-container>
                    </button>
                    <button type="button" (click)="UndoChanges(driver)" class="btn btn-primary d-block mb-1">
                      <ng-container i18n="Undo changes">Undo changes</ng-container>
                    </button>
                  </ng-container>
                  <button type="button" (click)="viewDriverDetails(driver)" class="btn btn-primary d-block mb-1">
                    <ng-container i18n>Details</ng-container>
                  </button>
                </td>
              </tr>
            </tbody>

            <br />
          </table>
        </div>

        <div *ngIf="renderingService.isMobile() || renderingService.isTablet()">
          <div class="card mb-4" style="width: 18rem" *ngFor="let driver of filteredDrivers; let driverIndex = index">
            <div class="card-body">
              <h5 class="card-title">{{ driver.name }}</h5>
              <h6 class="card-subtitle mb-2 text-muted">
                <ng-container i18n="Date added">Date added</ng-container>:
                {{ toLocalTime(driver.createdAt) | date: "dd.MM.yyyy" }}
              </h6>

              <p>
                <ng-container i18n="Driver ID">Driver ID</ng-container>:
                {{ driver.code ? driver.code : "/" }}
              </p>

              <span><ng-container i18n="Country">Country</ng-container>: {{ driver.transportCompanyCountry.name }}</span
              ><br />
              <span><ng-container i18n="Company">Company</ng-container>: {{ driver.transportCompany }}</span
              ><br />

              <ng-container *ngIf="driver.contract">
                <span><ng-container i18n="Contract signed at">Contract signed at</ng-container>: {{ driver.contract.dateSigned | date: "dd.MM.yyyy" }}</span>
                <br />
                <span [ngStyle]="{ color: contractExpiryColor(driver) }">
                  <ng-container *ngIf="driver.contract.hasExpired" i18n="Expired">Expired</ng-container>
                  <ng-container *ngIf="!driver.contract.hasExpired" i18n="Expired">Expires</ng-container>:
                  {{ driver.contract.dateExpires | date: "dd.MM.yyyy" }}
                </span>
                <br />
                <span [ngStyle]="{ color: contractExpiryColor(driver) }">({{ contractExpiryText(driver) }})</span>
                <span style="color: blue; cursor: pointer; text-decoration: underline; display: block" i18n (click)="openContractFile(driver.contract)"
                  ><a>View contract</a></span
                >
              </ng-container>

              <span *ngIf="driver.contract == null"> <ng-container i18n="No long term contract yet">No long term contract yet</ng-container>. </span>

              <button type="button" (click)="viewDriverDetails(driver)" class="btn btn-primary d-block mt-2">
                <ng-container i18n>Details</ng-container>
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ngb-pagination
      [(page)]="driversPageNumber"
      [pageSize]="driversPageSize"
      [collectionSize]="driversPageCount * driversPageSize"
      [maxSize]="8"
      [rotate]="true"
      (pageChange)="loadPage($event)"
    ></ngb-pagination>
  </ng-container>

  <p *ngIf="filteredDrivers && filteredDrivers.length === 0"><ng-container i18n="No drivers">No drivers</ng-container>.</p>
</div>
