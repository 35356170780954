import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CountriesService } from "../countries.service";
import { Country } from "../models/Country";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-multi-country-input",
  templateUrl: "./multi-country-input.component.html",
  styleUrls: ["./multi-country-input.component.css"],
})
export class MultiCountryInputComponent implements OnInit {
  @Input() countries: Country[];
  @Output() countriesChange = new EventEmitter<Country[]>();

  selectedCountry: Country | null = null;

  faTimes = faTimes;

  constructor(public countriesService: CountriesService) {}

  ngOnInit(): void {}

  onCountryAdd() {
    if (!this.selectedCountry) {
      return;
    }

    const countryExists = this.countries.some(
      (c) => c.id === this.selectedCountry.id
    );

    if (countryExists) {
      this.selectedCountry = null;
      return;
    }

    this.countries.push(this.selectedCountry);
    this.countriesChange.emit(this.countries);

    this.selectedCountry = null;
  }

  onCountryRemove(country: Country) {
    this.countries = this.countries.filter((c) => c.id !== country.id);
    this.countriesChange.emit(this.countries);
  }
}
