import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-clearable-input",
  templateUrl: "./clearable-input.component.html",
  styleUrls: ["./clearable-input.component.css"],
})
export class ClearableInputComponent implements OnInit {
  faTimes = faTimes;

  @Input() inputModel: string;
  @Output() inputModelChange = new EventEmitter<string>();

  @Output() onEnter = new EventEmitter<void>();
  @Output() onClear = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
