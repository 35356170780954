<div class="position-relative">
  <input
    type="text"
    class="form-control"
    [ngModel]="value"
    (ngModelChange)="valueChange.emit($event); onChange.emit()"
    [ngbTypeahead]="search"
    (keydown.enter)="onKeydownEnter.emit()"
    (focus)="focus$.next($any($event).target.value)"
    (click)="click$.next($any($event).target.value)"
    (selectItem)="onSelect.emit($event.item)"
    [focusFirst]="false"
    [inputFormatter]="formatter"
    [editable]="editable"
    [resultFormatter]="formatter"
    #instance="ngbTypeahead"
  />
  <fa-icon
    class="typeahead-clear-icon"
    *ngIf="value"
    [icon]="faTimes"
    (click)="onClear.emit(); valueChange.emit('')"
  ></fa-icon>
</div>
