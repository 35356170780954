<div class="container-fluid app-container arrival-table-container">
  <h1 class="app-heading"><ng-container i18n="Ramps">Ramps</ng-container></h1>

  <div class="mb-3 d-flex align-items-center justify-content-center" *ngxPermissionsOnly="[Role.COMPANY_ADMIN]">
    <button class="btn btn-primary table-refresh-btn" i18n (click)="modal.open(addOrEditRampContent)">Add ramp</button>
  </div>

  <ng-container *ngIf="filteredRamps">
    <div *ngIf="warehouses" class="d-flex justify-content-center mb-4 checkboxes-container">
      <div class="form-check form-check-inline" *ngFor="let w of warehouses">
        <input
          class="form-check-input"
          type="checkbox"
          id="warehouseFilterCheckbox{{ w.id }}"
          (change)="warehouseCheckboxChange($event, w.id)"
          [checked]="warehouseFilterCheckboxes[w.id]"
        />
        <label class="form-check-label" for="warehouseFilterCheckbox{{ w.id }}">
          <ng-container>{{ w.name }}</ng-container>
        </label>
      </div>
    </div>

    <div class="d-flex" class="table-filter-container">
      <input
        class="form-control table-filter-input"
        i18n-placeholder="Filter..."
        placeholder="Filter..."
        type="text"
        (input)="filterTextChange($event.target.value)"
      />
      <button class="btn btn-primary table-refresh-btn" i18n="Refresh" (click)="onRefreshClick()">Refresh</button>
    </div>

    <ng-container *ngIf="filteredRamps.length > 0">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th i18n="Name">Name</th>
              <th i18n="Warehouse part">Warehouse part</th>
              <th i18n="Warehouse">Warehouse</th>
              <th i18n="Can hold many arrivals">Can hold many arrivals</th>
              <th i18n="Current arrival(s) and driver(s)">Current arrival(s) and driver(s)</th>
              <th i18n="Actions" *ngxPermissionsOnly="[Role.COMPANY_ADMIN]">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let ramp of filteredRamps">
              <td>{{ ramp.name }} {{ ramp.warehousePart.warehouse.suffix }}</td>
              <td>
                {{ ramp.warehousePart.code }}
                {{ ramp.warehousePart.warehouse.suffix ? "(" + ramp.warehousePart.warehouse.suffix + ")" : "" }}
              </td>
              <td>{{ ramp.warehousePart.warehouse.name }}</td>
              <td>
                <fa-icon *ngIf="!ramp.canHoldManyArrivals" style="color: red" [icon]="faTimes"></fa-icon>
                <fa-icon *ngIf="ramp.canHoldManyArrivals" style="color: green" [icon]="faCheck"></fa-icon>
              </td>
              <td *ngIf="ramp.currentArrivals.length > 0">
                <div class="d-flex align-items-center justify-content-between mb-1" *ngFor="let currentArrival of ramp.currentArrivals">
                  <ng-container *ngIf="currentArrival">
                    <div>
                      <div [ngSwitch]="currentArrival.status" class="d-inline-block mr-1">
                        <span *ngSwitchCase="ArrivalStatus.Arrived" i18n="Arrival">Arrival</span>
                        <span *ngSwitchCase="ArrivalStatus.AtRamp" i18n="At ramp">At ramp</span>
                        <span *ngSwitchCase="ArrivalStatus.OnHold" i18n="On hold">On hold</span>
                        <span *ngSwitchCase="ArrivalStatus.OnHoldEnd" i18n="Issue resolved">Issue resolved</span>
                        <span *ngSwitchCase="ArrivalStatus.UnloadingStarted" i18n="Unloading start">Unloading start</span>
                        <span *ngSwitchCase="ArrivalStatus.UnloadingDone" i18n="Unloading end">Unloading done</span>
                        <span *ngSwitchCase="ArrivalStatus.Departed" i18n="Departure">Departure</span>
                      </div>
                      <span>({{ currentArrival.driver.name }})</span>
                    </div>
                    <button (click)="SeeDetails(currentArrival)" i18n="Details" class="btn btn-primary d-block ml-3">Details</button>
                  </ng-container>
                  <ng-container *ngIf="!currentArrival">/</ng-container>
                </div>
              </td>
              <td *ngIf="ramp.currentArrivals.length === 0">
                <ng-container>/</ng-container>
              </td>
              <td *ngxPermissionsOnly="[Role.COMPANY_ADMIN]">
                <button class="btn btn-info mr-2" i18n (click)="setRampForEditing(ramp); modal.open(addOrEditRampContent)">Edit</button>
                <button class="btn btn-danger" i18n (click)="setRampForDeleting(ramp); modal.open(confirmDeleteContent)">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
  </ng-container>

  <p *ngIf="filteredRamps && filteredRamps.length === 0"><ng-container i18n>No ramps</ng-container>.</p>

  <ng-container *ngIf="warehouses">
    <h1 class="app-heading mt-3"><ng-container i18n>Warehouses</ng-container></h1>

    <ng-container *ngIf="warehouses.length > 0">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th i18n>Name</th>
              <th i18n>Suffix</th>
              <th i18n *ngxPermissionsOnly="[Role.COMPANY_ADMIN]">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let warehouse of warehouses">
              <td>{{ warehouse.name }}</td>
              <td>{{ warehouse.suffix || "/" }}</td>
              <td *ngxPermissionsOnly="[Role.COMPANY_ADMIN]">
                <button class="btn btn-info mr-2" i18n (click)="setWarehouseForEditing(warehouse); modal.open(editWarehouseContent)">Edit</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>

    <p *ngIf="warehouses.length === 0"><ng-container i18n>No warehouses</ng-container>.</p>
  </ng-container>
</div>

<ng-template #addOrEditRampContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" *ngIf="rampToEdit == null" i18n>Add ramp</h4>
    <h4 class="modal-title" *ngIf="rampToEdit" i18n>Edit ramp: {{ rampToEdit.name }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeAddOrEditRampModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-group mb-2">
      <label><ng-container i18n>Name</ng-container></label>
      <input
        required
        class="form-control"
        i18n-placeholder
        placeholder="Name"
        [(ngModel)]="addOrEditRampData.name"
        [disabled]="isAddingOrEditingLoading"
        (keydown.enter)="onAddOrEditRamp()"
      />
    </div>

    <app-checkbox [(value)]="addOrEditRampData.canHoldManyArrivals" name="canHoldManyArrivals">
      <ng-container i18n>Can hold many arrivals</ng-container>
    </app-checkbox>

    <div class="form-group">
      <label for="name"><ng-container i18n>Warehouse part</ng-container></label>
      <select [(ngModel)]="addOrEditRampData.warehousePartId" id="warehousePartId" name="warehousePartId" class="form-control">
        <option disabled [value]="null" i18n>Select warehouse part</option>
        <option *ngFor="let warehousePart of warehouseParts" [value]="warehousePart.id">
          <ng-container>{{ warehousePart.code }}</ng-container>
        </option>
      </select>
    </div>
  </div>

  <div class="modal-footer d-flex justify-content-between">
    <button type="submit" class="btn" (click)="closeAddOrEditRampModal()" [disabled]="isAddingOrEditingLoading">
      <ng-container i18n="Cancel">Cancel</ng-container>
    </button>
    <button class="btn btn-primary" [disabled]="isAddingOrEditingLoading || !canAddOrEditRamp()" (click)="onAddOrEditRamp()">
      <ng-container i18n *ngIf="rampToEdit == null">Add</ng-container>
      <ng-container i18n *ngIf="rampToEdit != null">Edit</ng-container>
    </button>
  </div>
</ng-template>

<ng-template #confirmDeleteContent let-modal>
  <app-confirmation-modal
    *ngIf="rampToDelete != null"
    [isLoading]="loadingDelete"
    header="Delete ramp?"
    i18n-header
    subHeader="Confirm deleting ramp {{ rampToDelete.name }}"
    i18n-subHeader
    cancelButtonText="Cancel"
    i18n-cancelButtonText
    confirmButtonText="Delete"
    i18n-confirmButtonText
    confirmButtonClass="btn-danger"
    (onCancel)="dismissAllModals()"
    (onConfirm)="deleteRamp()"
  >
  </app-confirmation-modal>
</ng-template>

<ng-template #editWarehouseContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" *ngIf="warehouseToEdit" i18n>Edit warehouse: {{ warehouseToEdit.name }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeAddOrEditRampModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-group mb-2" *ngIf="warehouseToEdit">
      <label><ng-container i18n>Name</ng-container></label>
      <input
        required
        class="form-control"
        i18n-placeholder
        placeholder="Name"
        disabled
        [(ngModel)]="editWarehouseData.name"
        (keydown.enter)="onEditWarehouse()"
      />
    </div>

    <div class="form-group mb-2">
      <label><ng-container i18n>Suffix</ng-container></label>
      <input
        required
        class="form-control"
        i18n-placeholder
        placeholder="Suffix"
        [(ngModel)]="editWarehouseData.suffix"
        [disabled]="isAddingOrEditingLoading"
        (keydown.enter)="onEditWarehouse()"
      />
    </div>
  </div>

  <div class="modal-footer d-flex justify-content-between">
    <button type="submit" class="btn" (click)="closeEditWarehouseModal()" [disabled]="isAddingOrEditingLoading">
      <ng-container i18n="Cancel">Cancel</ng-container>
    </button>
    <button class="btn btn-primary" [disabled]="isAddingOrEditingLoading" (click)="onEditWarehouse()">
      <ng-container i18n>Edit</ng-container>
    </button>
  </div>
</ng-template>
