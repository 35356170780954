<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" [routerLink]="['/']" (click)="closeNavMenu()">
    <img src="/assets/logo.png" alt="Home page" width="30" height="30" class="d-inline-block align-top" />
    Yamas
  </a>
  <button class="navbar-toggler" type="button" (click)="toggleNavbar = !toggleNavbar">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" [ngbCollapse]="!toggleNavbar">
    <ul class="navbar-nav mr-auto">
      <ng-container *ngIf="auth.IsLoggedIn()">
        <ng-container *ngIf="auth.IsGateManager()">
          <li class="nav-item">
            <a
              class="nav-link"
              (click)="closeNavMenu()"
              [routerLinkActive]="['active']"
              [routerLinkActiveOptions]="{ exact: true }"
              [routerLink]="['/gate-management']"
            >
              <ng-container i18n="Add a new arrival">New arrival</ng-container>
            </a>
          </li>
        </ng-container>

        <ng-container *ngIf="auth.IsWarehouseManager() || auth.IsGateManager()">
          <li class="nav-item">
            <a
              class="nav-link"
              (click)="closeNavMenu()"
              [routerLinkActive]="['active']"
              [routerLinkActiveOptions]="{ exact: true }"
              [routerLink]="['/warehouse-history']"
            >
              <ng-container i18n="Active arrivals">Active arrivals</ng-container>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              (click)="closeNavMenu()"
              [routerLinkActive]="['active']"
              [routerLinkActiveOptions]="{ exact: true }"
              [routerLink]="['/warehouse-archive']"
            >
              <ng-container i18n="Arrivals archive">Arrivals archive</ng-container>
            </a>
          </li>
        </ng-container>

        <li class="nav-item">
          <a
            class="nav-link"
            (click)="closeNavMenu()"
            [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{ exact: true }"
            [routerLink]="['/driver-list']"
          >
            <ng-container i18n="Drivers">Drivers</ng-container>
          </a>
        </li>

        <ng-container *ngIf="auth.IsWarehouseManager()">
          <li class="nav-item">
            <a
              class="nav-link"
              (click)="closeNavMenu()"
              [routerLinkActive]="['active']"
              [routerLinkActiveOptions]="{ exact: true }"
              [routerLink]="['/ramp-list']"
            >
              <ng-container i18n="Ramps">Ramps</ng-container>
            </a>
          </li>
        </ng-container>

        <li class="nav-item">
          <a
            class="nav-link"
            (click)="closeNavMenu()"
            [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{ exact: true }"
            [routerLink]="['/forbidden-driver-list']"
          >
            <ng-container i18n="Forbidden drivers">Forbidden drivers</ng-container>
          </a>
        </li>

        <li class="nav-item">
          <a
            class="nav-link"
            (click)="closeNavMenu()"
            [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{ exact: true }"
            [routerLink]="['/forbidden-transport-company-list']"
          >
            <ng-container i18n="Forbidden companies">Forbidden companies</ng-container>
          </a>
        </li>

        <li class="nav-item">
          <a
            class="nav-link"
            (click)="closeNavMenu()"
            [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{ exact: true }"
            [routerLink]="['/country-combinations-list']"
          >
            <ng-container i18n="Country combinations">Country combinations</ng-container>
          </a>
        </li>

        <ng-container *ngIf="auth.IsWarehouseAdmin()">
          <li class="nav-item">
            <a
              class="nav-link"
              (click)="closeNavMenu()"
              [routerLinkActive]="['active']"
              [routerLinkActiveOptions]="{ exact: true }"
              [routerLink]="['/admin/contract-management']"
            >
              <ng-container i18n="Manage contracts">Manage contracts</ng-container>
            </a>
          </li>
          <li class="nav-item" *ngIf="auth.IsWarehouseAdmin()">
            <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/stats']">
              <ng-container i18n>Stats</ng-container>
            </a>
          </li>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!auth.IsLoggedIn()">
        <li class="nav-item">
          <a class="nav-link" (click)="closeNavMenu()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/login']">
            <ng-container i18n="Log in">Log in</ng-container>
          </a>
        </li>
      </ng-container>
    </ul>

    <ul class="form-inline my-2 my-lg-0 navbar-nav">
      <ng-container *ngIf="auth.IsLoggedIn()">
        <li class="nav-item">
          <a class="nav-link" (click)="closeNavMenu()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/profile']">
            <ng-container>
              {{ auth.loggedInUser.email }}
            </ng-container>
          </a>
        </li>

        <div *ngIf="auth.userId" class="col-auto nav-item" style="padding-right: 2px; padding-left: 2px">
          <span class="nav-link" i18n="Change profile">Profile: {{ auth.userId }}</span>
        </div>

        <div *ngIf="!auth.IsWarehouseAdmin() && auth.IsWarehouseManager()" style="padding-bottom: 10px; padding-top: 10px" class="col-auto nav-item">
          <button class="btn btn-secondary" (click)="open(content)">
            <ng-container i18n="Change profile">Change profile</ng-container>
          </button>
        </div>
        <div class="col-auto nav-item">
          <button class="btn btn-secondary" (click)="Logout(); closeNavMenu()">
            <ng-container i18n="Log out">Log out</ng-container>
          </button>
        </div>

        <app-language-picker></app-language-picker>
      </ng-container>
    </ul>
  </div>
</nav>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update user ID</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss(false)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <p>Current user ID: {{ auth.userId || "-" }}</p>
      <button type="submit" class="btn btn-primary" (click)="modal.close(null)">
        <ng-container i18n="Unset">Unset</ng-container>
      </button>
    </div>

    <div class="form-group">
      <label for="name"><ng-container i18n="Name">User ID</ng-container></label>
      <input
        type="text"
        placeholder="Enter user ID"
        i18n-placeholder
        class="form-control"
        id="name"
        [(ngModel)]="userIdInput"
        [ngModelOptions]="{ standalone: true }"
      />
    </div>
  </div>

  <div class="modal-footer d-flex justify-content-between">
    <button type="submit" class="btn" (click)="modal.close(false)">
      <ng-container i18n="Cancel">Cancel</ng-container>
    </button>
    <button type="submit" class="btn btn-primary" [disabled]="userIdInput.length === 0" (click)="modal.close(true)">
      <ng-container i18n="Change">Change</ng-container>
    </button>
  </div>
</ng-template>
