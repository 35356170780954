import { Component, OnInit } from "@angular/core";
import { RenderingService } from "../rendering.service";
import { RestService } from "../services/rest.service";
import { ToastrService } from "ngx-toastr";
import { MessageTranslationService } from "../message-translation.service";
import { CountriesService } from "../countries.service";
import { Country, CountryCombination } from "../models/Country";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute } from "@angular/router";

interface CountryCombinationRequest {
  thirdCountry: Country;
  typeOfPermit: string;
  weight: string;
  permitQuota: string;
  cabotage: string;
  permissionNotNeeded: boolean;
}

@Component({
  selector: "app-country-combinations-list",
  templateUrl: "./country-combinations-list.component.html",
  styleUrls: ["./country-combinations-list.component.css"],
})
export class CountryCombinationsListComponent implements OnInit {
  combinations: CountryCombination[] = null;
  filteredCombinations: CountryCombination[] = null;

  combinationToEdit: CountryCombination;
  combinationToDelete: CountryCombination;

  loading: boolean;

  combinationForForm: CountryCombinationRequest;

  filterText = "";

  showSeedButton: boolean = false;

  constructor(
    public renderingService: RenderingService,
    private http: RestService,
    private toast: ToastrService,
    private msgT: MessageTranslationService,
    public countriesService: CountriesService,
    public modal: NgbModal,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params.showSeedButton != null) {
        this.showSeedButton = true;
      } else {
        this.showSeedButton = false;
      }
    });

    this.http.get(`api/country/listCombinations`).subscribe(
      (combinations: CountryCombination[]) => {
        this.combinations = combinations;
        for (let i = 0; i < this.combinations.length; i++) {
          this.combinations[i].filterString = this.filterString(this.combinations[i]);
        }
        this.filteredCombinations = this.combinations;
        this.filterTextChange(this.filterText);
      },
      (error) => {
        console.log("Error fetching combinations");
        console.log(error.error);
        this.toast.error(this.msgT.fetchCombinationsError());
      }
    );
  }

  public filterString(combination: CountryCombination) {
    return combination.thirdCountry.name.toLowerCase();
  }

  public filterTextChange(txt) {
    const txtProcessed = txt.trim().toLowerCase();
    this.filterText = txtProcessed;
    if (txtProcessed.length === 0) {
      this.filteredCombinations = this.combinations;
      return;
    }

    this.filteredCombinations = this.combinations.filter((d) => d.filterString.includes(this.filterText));
  }

  public onRefreshClick() {
    this.ngOnInit();
  }

  resetCombinationForForm() {
    this.combinationForForm = {
      cabotage: "",
      permitQuota: "",
      thirdCountry: null,
      typeOfPermit: "",
      weight: "",
      permissionNotNeeded: false,
    };
  }

  setCombinationForForm(combination: CountryCombination) {
    this.combinationForForm = {
      cabotage: combination.cabotage,
      permitQuota: combination.permitQuota,
      thirdCountry: combination.thirdCountry,
      typeOfPermit: combination.typeOfPermit,
      weight: combination.weight,
      permissionNotNeeded: combination.permissionNotNeeded,
    };
  }

  public openAddModal(content) {
    this.resetCombinationForForm();

    this.modal.open(content, { ariaLabelledBy: "modal-basic-title" }).result.then(
      () => {
        this.resetCombinationForForm();
      },
      () => {
        this.resetCombinationForForm();
      }
    );
  }

  public openEditModal(combination: CountryCombination, content) {
    this.combinationToEdit = combination;
    this.setCombinationForForm(combination);

    this.modal.open(content, { ariaLabelledBy: "modal-basic-title" }).result.then(
      () => {
        this.resetCombinationForForm();
        this.combinationToEdit = null;
      },
      () => {
        this.resetCombinationForForm();
        this.combinationToEdit = null;
      }
    );
  }

  public openDeleteModal(combination: CountryCombination, content) {
    this.combinationToDelete = combination;

    this.modal.open(content, { ariaLabelledBy: "modal-basic-title" }).result.then(
      () => {
        this.combinationToDelete = null;
      },
      () => {
        this.combinationToDelete = null;
      }
    );
  }

  public isCombinationForFormValid() {
    return this.combinationForForm.thirdCountry != null;
  }

  public dissmisAllModals() {
    this.modal.dismissAll();
  }

  public async addOrEditCombination() {
    if (!this.isCombinationForFormValid()) {
      return;
    }

    this.loading = true;

    try {
      if (!this.combinationToEdit) {
        const combination = await this.http
          .post<CountryCombination>(`api/country/createCombination`, {
            ...this.combinationForForm,
            thirdCountryId: this.combinationForForm.thirdCountry.id,
          })
          .toPromise();
        this.combinations.unshift(combination);
      } else {
        const combination = await this.http
          .post<CountryCombination>(`api/country/updateCombination/${this.combinationToEdit.id}`, {
            ...this.combinationForForm,
            thirdCountryId: this.combinationForForm.thirdCountry.id,
          })
          .toPromise();
        const combinationIndex = this.combinations.findIndex((c) => c.id === this.combinationToEdit.id);
        if (combinationIndex >= 0) {
          this.combinations[combinationIndex] = combination;
        }
      }

      this.toast.success(this.msgT.success());
      this.dissmisAllModals();
    } catch (e) {
      this.toast.error(this.msgT.unknownError());
    } finally {
      this.loading = false;
    }
  }

  public async deleteCombination() {
    if (!this.combinationToDelete) {
      return;
    }

    this.loading = true;

    try {
      await this.http.post<CountryCombination>(`api/country/deleteCombination/${this.combinationToDelete.id}`, {}).toPromise();
      const combinationIndex = this.combinations.findIndex((c) => c.id === this.combinationToDelete.id);
      if (combinationIndex >= 0) {
        this.combinations.splice(combinationIndex, 1);
      }

      this.toast.success(this.msgT.success());
      this.dissmisAllModals();
    } catch (e) {
      this.toast.error(this.msgT.unknownError());
    } finally {
      this.loading = false;
    }
  }

  public async seedInitData() {
    try {
      await this.http.post<CountryCombination>(`api/country/seedCombinations`, {}).toPromise();

      this.toast.success(this.msgT.success());
    } catch (e) {
      this.toast.error(this.msgT.unknownError());
    }
  }
}
