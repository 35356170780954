<div class="container app-container">
  <ng-container *ngIf="driver">
    <h1 class="app-heading">
      <ng-container>Driver</ng-container>:
      {{ driver.name }}
    </h1>

    <h4 i18n>General information</h4>

    <div class="text-center table-container">
      <table class="table table-borderless table-centralised" style="width: 100% !important">
        <tbody>
          <tr>
            <th><span i18n="ID">ID</span>:</th>
            <td>{{ driver.code || "-" }}</td>
          </tr>
          <tr>
            <th><span i18n="Name">Name</span>:</th>
            <td>{{ driver.name }}</td>
          </tr>

          <tr>
            <th><span i18n="Company">Company</span>:</th>
            <td>{{ driver.transportCompany }}</td>
          </tr>

          <tr>
            <th><span i18n="Company country">Company country</span>:</th>
            <td>{{ driver.transportCompanyCountry.name }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <hr />

    <h4 i18n>Contracts</h4>

    <div class="mb-3">
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="checkbox"
          id="showLongTermContractsCheckbox"
          [(ngModel)]="showLongTermContracts"
          (ngModelChange)="onContractDisplayParamteresChange()"
        />
        <label class="form-check-label" for="showLongTermContractsCheckbox">
          <ng-container i18n>Long term contracts</ng-container>
        </label>
      </div>

      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="checkbox"
          id="showShortTermContractsCheckbox"
          [(ngModel)]="showShortTermContracts"
          (ngModelChange)="onContractDisplayParamteresChange()"
        />
        <label class="form-check-label" for="showShortTermContractsCheckbox">
          <ng-container i18n>Short term contracts</ng-container>
        </label>
      </div>
    </div>

    <div *ngIf="!filteredContracts?.length">
      <span i18n>No contracts.</span>
    </div>

    <ng-container *ngIf="filteredContracts?.length">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th i18n>Type</th>
            <th i18n>Signed</th>
            <th i18n>Expires</th>
            <th i18n>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let contract of filteredContracts">
            <td>
              <app-render-contract-type [contractType]="contract.type"></app-render-contract-type>
              <span *ngIf="contract.id === driver.contract?.id" i18n class="font-weight-bold ml-1">(Active)</span>
            </td>
            <td>{{ contract.dateSigned | date: "dd.MM.yyyy" }}</td>
            <td>
              <span
                [ngStyle]="{
                  color: contract.type === DriverContractType.LongTerm ? contractExpiryColor({ contract: contract }) : undefined
                }"
                >{{ contract.dateExpires | date: "dd.MM.yyyy" }}
                <ng-container *ngIf="contract.type === DriverContractType.LongTerm">({{ contractExpiryText({ contract: contract }) }}) </ng-container>
              </span>
            </td>
            <td>
              <button type="button" class="btn btn-primary" (click)="openContractFile(contract)">
                <ng-container i18n>Download</ng-container>
              </button>
              <button
                type="button"
                class="btn btn-danger ml-2"
                (click)="openDeleteContractModal(deleteTemplateModal, contract)"
                *ngIf="auth.IsWarehouseAdmin()"
              >
                <ng-container i18n>Delete</ng-container>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!driver">
    <ng-container i18n="No driver found">No driver found</ng-container>
  </ng-container>
</div>

<ng-template #deleteTemplateModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" i18n>Delete contract?</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss(false)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <p class="text-danger" i18n>Please confirm deleting contract.</p>
  </div>

  <div class="modal-footer d-flex justify-content-between">
    <button type="submit" class="btn" (click)="modal.close(false)">
      <ng-container i18n="Cancel">Cancel</ng-container>
    </button>
    <button type="submit" class="btn btn-danger" [disabled]="deletingContractInProgress" (click)="onDeleteContract()">
      <ng-container i18n="Delete">Delete</ng-container>
    </button>
  </div>
</ng-template>
