import { RestService } from "../services/rest.service";
import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { DateConverter } from "../DateConverter";
import { MessageTranslationService } from "../message-translation.service";
import { ForbiddenDriver } from "../models/ForbiddenDriver";
import { NgbModal, NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { CountriesService } from "../countries.service";
import { Router } from "@angular/router";
import { contractExpiryText } from "../utils/ArrivalUtils";

interface NewDriver {
  forbiddenAt: NgbDateStruct;
  name: string;
  reason: string;
  transportCompany: string;
}

@Component({
  selector: "app-forbidden-drivers-list",
  templateUrl: "./forbidden-drivers-list.component.html",
  styleUrls: ["./forbidden-drivers-list.component.css"],
})
export class ForbiddenDriversListComponent implements OnInit {
  toLocalTime = DateConverter.toLocalTime;
  contractExpiryText = contractExpiryText;

  drivers: ForbiddenDriver[] = null;
  filteredDrivers: ForbiddenDriver[] = null;

  filterText = "";

  pageNumber = 1;
  pageSize = 10;

  transportCompanies: string[] = [];

  newDriver: NewDriver;

  constructor(
    private http: RestService,
    private toast: ToastrService,
    private msgT: MessageTranslationService,
    private modalService: NgbModal,
    public countriesService: CountriesService,
    private calendar: NgbCalendar,
    private router: Router
  ) {}

  ngOnInit() {
    this.resetNewDriver();

    this.getTransportCompanies();

    this.http.get(`api/driver/listForbidden`).subscribe(
      (drivers: ForbiddenDriver[]) => {
        this.drivers = drivers;
        for (let i = 0; i < this.drivers.length; i++) {
          this.drivers[i].filterString = this.filterString(this.drivers[i]);
          this.setOriginalData(this.drivers[i]);
        }
        this.filteredDrivers = this.drivers;
        this.filterTextChange(this.filterText);
      },
      (error) => {
        console.log("Error fetching drivers");
        console.log(error.error);
        this.toast.error(this.msgT.fetchDriversError());
      }
    );
  }

  getTransportCompanies() {
    this.http.post(`api/driver/getAllTransportCompanies`, {}).subscribe(
      (companies: string[]) => {
        this.transportCompanies = companies;
      },
      (error) => {
        console.log("Error fetching transport companies");
        console.log(error.error);
      }
    );
  }

  public filterString(driver: ForbiddenDriver) {
    return (driver.name + " " + driver.transportCompany).toLowerCase();
  }

  public filterTextChange(txt) {
    const txtProcessed = txt.trim().toLowerCase();
    this.filterText = txtProcessed;
    if (txtProcessed.length === 0) {
      this.filteredDrivers = this.drivers;
      return;
    }

    this.filteredDrivers = this.drivers.filter((d) => d.filterString.includes(this.filterText));
  }

  public isModified(driver: ForbiddenDriver) {
    return (
      driver.originalData.name !== driver.name ||
      driver.originalData.reason !== driver.reason ||
      driver.originalData.transportCompany !== driver.transportCompany
    );
  }

  public OnUpdateDriver(driver: ForbiddenDriver) {
    driver.isModified = this.isModified(driver);
    driver.filterString = this.filterString(driver);
    this.filterTextChange(this.filterText);
  }

  private setOriginalData(driver: ForbiddenDriver) {
    driver.originalData = {
      name: driver.name,
      reason: driver.reason,
      transportCompany: driver.transportCompany,
    };
  }

  ApplyChanges(driver: ForbiddenDriver) {
    this.http
      .post(`api/driver/updateForbiddenDriver/${driver.id}`, {
        name: driver.name,
        transportCompany: driver.transportCompany,
        reason: driver.reason,
      })
      .subscribe(
        (updatedDriver: ForbiddenDriver) => {
          if (updatedDriver) {
            driver.name = updatedDriver.name;
            driver.reason = updatedDriver.reason;

            driver.filterString = this.filterString(driver);
            this.setOriginalData(driver);
            this.filterTextChange(this.filterText);

            this.toast.success(this.msgT.updateDriverSuccess());
            driver.isModified = false;
          }
        },
        (error) => {
          console.log("Update driver error");
          console.log(error);
          this.toast.error(this.msgT.updateDriverError());
        }
      );
  }

  private undoChanges(driver: ForbiddenDriver) {
    driver.name = driver.originalData.name;
    driver.reason = driver.originalData.reason;
    driver.transportCompany = driver.originalData.transportCompany;
  }

  UndoChanges(driver: ForbiddenDriver) {
    if (driver.isModified) {
      this.undoChanges(driver);
      driver.isModified = false;
      driver.filterString = this.filterString(driver);
      this.filterTextChange(this.filterText);
    }
  }

  public onRefreshClick() {
    this.ngOnInit();
  }

  public RemoveDriver(driver: ForbiddenDriver) {
    this.http.post(`api/driver/deleteForbiddenDriver/${driver.id}`, {}).subscribe(
      (result: boolean) => {
        if (result) {
          this.drivers = this.drivers.filter((d) => d.id !== driver.id);
          this.filterTextChange(this.filterText);
          this.toast.success(this.msgT.deleteDriverSuccess());
        }
      },
      (error) => {
        console.log("Delete driver error");
        console.log(error);
        this.toast.error(this.msgT.deleteDriverError());
      }
    );
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" }).result.then(
      (result: boolean | null) => {},
      (_) => {}
    );
  }

  public truncateReason(reason: string) {
    let line = reason;
    if (reason.indexOf("\n") >= 0) {
      line = reason.substr(0, reason.indexOf("\n"));
    } else if (line.length <= 20) {
      return line;
    }

    if (line.length > 20) {
      line = line.slice(0, 20);
    }

    return line + "...";
  }

  public AddDriver() {
    if (!this.newDriverValid()) {
      return;
    }

    this.http
      .post(`api/driver/createForbiddenDriver`, {
        name: this.newDriver.name,
        transportCompany: this.newDriver.transportCompany,
        reason: this.newDriver.reason,
        forbiddenAt: this.newDriver.forbiddenAt.day + ". " + this.newDriver.forbiddenAt.month + ". " + this.newDriver.forbiddenAt.year,
      })
      .subscribe(
        (addedDriver: ForbiddenDriver) => {
          if (addedDriver) {
            this.drivers.unshift(addedDriver);

            addedDriver.filterString = this.filterString(addedDriver);
            this.setOriginalData(addedDriver);

            this.filterTextChange(this.filterText);

            this.resetNewDriver();
            this.modalService.dismissAll();
            this.toast.success(this.msgT.addDriverSuccess());
          } else {
            this.toast.error(this.msgT.addDriverError());
          }
        },
        (error) => {
          console.log("Add driver error");
          console.log(error);
          this.toast.error(this.msgT.addDriverError());
        }
      );
  }

  public resetNewDriver() {
    this.newDriver = {
      forbiddenAt: this.calendar.getToday(),
      name: "",
      reason: "",
      transportCompany: "",
    };
  }

  public newDriverValid(): boolean {
    return (
      this.newDriver.name != null &&
      this.newDriver.name.length > 0 &&
      this.newDriver.reason != null &&
      this.newDriver.reason.length > 0 &&
      this.newDriver.forbiddenAt != null
    );
  }

  public SeeDetails(driver: ForbiddenDriver) {
    this.router.navigate([`forbidden-driver`, driver.id]);
  }
}
