import { RestService } from "../services/rest.service";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../core/auth.service";
import { MessageTranslationService } from "../message-translation.service";
import { Arrival } from "../models/Arrival";
import { ArrivalStatus } from "../models/ArrivalStatus";
import { MultiArrivalRamp, Ramp } from "../models/Ramp";
import { Warehouse, WarehousePart } from "../models/WarehousePart";
import { RenderingService } from "../rendering.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Role } from "../models/Role";

@Component({
  selector: "app-ramp-list",
  templateUrl: "./ramp-list.component.html",
  styleUrls: ["./ramp-list.component.css"],
})
export class RampListComponent implements OnInit {
  ArrivalStatus = ArrivalStatus;
  ramps: MultiArrivalRamp[] = null;
  filteredRamps: MultiArrivalRamp[] = null;
  filterText: string = "";

  faCheck = faCheck;
  faTimes = faTimes;

  Role = Role;

  warehouses: Warehouse[] = null;
  warehouseFilterCheckboxes: Record<number, boolean> = {};

  manyArrivalRamps = [];

  addOrEditRampData = {
    name: "",
    canHoldManyArrivals: false,
    warehousePartId: null,
  };

  warehouseParts: WarehousePart[] = [];

  rampToDelete: Ramp | null = null;
  rampToEdit: Ramp | null = null;
  loadingDelete = false;
  isAddingOrEditingLoading = false;

  editWarehouseData = {
    name: "",
    suffix: "",
  };

  warehouseToEdit: Warehouse | null = null;

  constructor(
    public renderingService: RenderingService,
    private http: RestService,
    private toast: ToastrService,
    public modal: NgbModal,
    private msgT: MessageTranslationService,
    private router: Router,
    public auth: AuthService
  ) {}

  ngOnInit() {
    this.dismissAllModals();
    this.resetAddOrEditData();
    this.http.get(`api/ramps/listRamps`).subscribe(
      (ramps: Ramp[]) => {
        this.ramps = [];

        ramps.map((r) => {
          this.ramps.push({
            currentArrivals: r.currentArrival ? [r.currentArrival] : [],
            canHoldManyArrivals: r.canHoldManyArrivals,
            id: r.id,
            name: r.name,
            filterString: "",
            warehousePart: r.warehousePart,
          });
        });

        this.manyArrivalRamps = ramps.filter((r) => r.canHoldManyArrivals).map((r) => r.name);

        this.ramps.forEach((r) => {
          r.filterString = this.filterString(r);
        });

        this.filteredRamps = this.ramps;
        this.filterTextChange(this.filterText);

        this.fetchMutliArrivalRamps();

        this.GetWarehouses();
      },
      (error) => {
        console.log("Error fetching ramps");
        console.log(error.error);
        this.toast.error(this.msgT.fetchRampsError());
        this.ramps = [];
      }
    );
  }

  GetWarehouses() {
    if (this.warehouses != null) {
      return;
    }

    this.auth.hasLoggedInUserLoaded$.subscribe((isDone) => {
      if (isDone) {
        this.http.get(`api/authentication/listWarehouses`).subscribe(
          (w: Warehouse[]) => {
            this.warehouses = w;
            for (let i = 0; i < this.warehouses.length; i++) {
              this.warehouseFilterCheckboxes[this.warehouses[i].id] = this.auth.loggedInUser.defaultWarehouse?.id === this.warehouses[i].id;
              this.warehouseParts.push(...this.warehouses[i].warehouseParts);
            }

            this.applyFilters();
          },
          (error) => {
            console.log("Find warehouses error");
            console.log(error);
          }
        );
      }
    });
  }

  fetchMutliArrivalRamps() {
    this.http.get(`api/ramps/listMultiArrivalRamps`).subscribe(
      (arrivals: Arrival[]) => {
        for (let j = 0; j < this.manyArrivalRamps.length; j++) {
          const ramp = this.ramps.find((r) => r.name === this.manyArrivalRamps[j]);
          if (!ramp) {
            continue;
          }

          const arrivalsForRamp = arrivals.filter((a) => a.currentRamp.name === this.manyArrivalRamps[j]);
          ramp.currentArrivals = arrivalsForRamp;
        }
      },
      (error) => {
        console.log("Error fetching ramps");
        console.log(error.error);
        this.toast.error(this.msgT.fetchRampsError());
      }
    );
  }

  filterString(ramp: MultiArrivalRamp) {
    return (
      ramp.name +
      " " +
      ramp.warehousePart.code +
      " " +
      ramp.warehousePart.warehouse.name +
      " " +
      ramp.currentArrivals.map((a) => a.driver.name).join(" ")
    ).toLowerCase();
  }

  public onRefreshClick() {
    this.ngOnInit();
  }

  public SeeDetails(arrival: Arrival) {
    this.router.navigate([`warehouse-arrival`, arrival.id]);
  }

  public filterTextChange(txt) {
    const txtProcessed = txt.trim().toLowerCase();
    this.filterText = txtProcessed;
    this.applyFilters();
  }

  public warehouseCheckboxChange(event: any, wId: number) {
    this.warehouseFilterCheckboxes[wId] = event.target.checked;
    this.applyFilters();
  }

  applyFilters() {
    if (this.filterText.length === 0) {
      this.filteredRamps = this.ramps;
    } else {
      this.filteredRamps = this.ramps.filter((r) => r.filterString.includes(this.filterText));
    }

    this.filteredRamps = this.filteredRamps.filter((a) => this.warehouseFilterCheckboxes[a.warehousePart.warehouse.id]);
  }

  resetAddOrEditData() {
    this.addOrEditRampData = {
      name: "",
      canHoldManyArrivals: false,
      warehousePartId: null,
    };

    this.editWarehouseData = {
      name: "",
      suffix: "",
    };

    this.isAddingOrEditingLoading = false;
    this.loadingDelete = false;
    this.rampToDelete = null;
    this.rampToEdit = null;
    this.warehouseToEdit = null;
  }

  setRampForAdding() {
    this.resetAddOrEditData();
  }

  setRampForEditing(ramp: Ramp) {
    this.setRampForAdding();

    this.addOrEditRampData = {
      name: ramp.name,
      canHoldManyArrivals: ramp.canHoldManyArrivals,
      warehousePartId: ramp.warehousePart.id,
    };

    this.rampToEdit = ramp;
  }

  setRampForDeleting(ramp: Ramp) {
    this.resetAddOrEditData();
    this.rampToDelete = ramp;
  }

  public canAddOrEditRamp(): boolean {
    if (this.addOrEditRampData.warehousePartId == null) {
      return false;
    }

    if (this.addOrEditRampData.name.trim().length === 0) {
      return false;
    }

    return true;
  }

  public async onAddOrEditRamp() {
    const isEditing = this.rampToEdit != null;
    if (!this.canAddOrEditRamp()) {
      return;
    }

    this.isAddingOrEditingLoading = true;

    try {
      if (isEditing) {
        await this.editRamp(this.rampToEdit.id);
      } else {
        await this.addRamp();
      }

      this.toast.success(this.msgT.success());
      this.ngOnInit();
    } catch (e) {
      this.toast.error(this.msgT.unknownError());
    }

    this.isAddingOrEditingLoading = false;
  }

  private async addRamp() {
    const r = await this.http
      .post<Ramp>("api/ramps/addRamp", {
        ...this.addOrEditRampData,
      })
      .toPromise();
    return r;
  }

  private async editRamp(rampId: number) {
    return await this.http
      .post<Ramp>("api/ramps/updateRamp/" + rampId, {
        ...this.addOrEditRampData,
      })
      .toPromise();
  }

  async deleteRamp() {
    if (!this.rampToDelete) {
      return;
    }

    try {
      await this.http.delete("api/ramps/deleteRamp/" + this.rampToDelete.id, {}).toPromise();

      this.toast.success(this.msgT.success());
      this.ngOnInit();
    } catch (e) {
      console.error("Error", e);
      this.toast.error(this.msgT.unknownError());
    }
  }

  public dismissAllModals() {
    this.modal.dismissAll();
  }

  public closeAddOrEditRampModal() {
    this.dismissAllModals();
    this.resetAddOrEditData();
  }

  public closeEditPasswordModal() {
    this.closeAddOrEditRampModal();
  }

  public closeDeleteRampModal() {
    this.dismissAllModals();
    this.rampToDelete = null;
  }

  setWarehouseForEditing(warehouse: Warehouse) {
    this.setRampForAdding();

    this.editWarehouseData = {
      name: warehouse.name,
      suffix: warehouse.suffix,
    };

    this.warehouseToEdit = warehouse;
  }

  public async onEditWarehouse() {
    if (!this.warehouseToEdit) {
      return;
    }

    this.isAddingOrEditingLoading = true;

    try {
      await this.http
        .post<Ramp>("api/ramps/updateWarehouse/" + this.warehouseToEdit.id, {
          ...this.editWarehouseData,
        })
        .toPromise();
      this.toast.success(this.msgT.success());
      this.ngOnInit();
    } catch (e) {
      this.toast.error(this.msgT.unknownError());
    }

    this.isAddingOrEditingLoading = false;
  }

  public closeEditWarehouseModal() {
    this.dismissAllModals();
    this.resetAddOrEditData();
  }
}
