import { HttpClient, HttpParams } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { faExclamationTriangle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { AuthService } from "../core/auth.service";
import { CountriesService } from "../countries.service";
import { CountryCombinationsListComponent } from "../country-combinations-list/country-combinations-list.component";
import { MessageTranslationService } from "../message-translation.service";
import { Country } from "../models/Country";
import { GetDriverDataByReservationCodeResponse } from "../models/Driver";
import { ForbiddenDriver } from "../models/ForbiddenDriver";
import { ForbiddenTransportCompany } from "../models/ForbiddenTransportCompany";
import { Warehouse } from "../models/WarehousePart";
import { RestService } from "../services/rest.service";

const DEBOUNCE_TIME = 1500;

@Component({
  selector: "app-gate-management",
  templateUrl: "./gate-management.component.html",
  styleUrls: ["./gate-management.component.css"],
})
export class GateManagementComponent implements OnInit {
  faExclamationTriangle = faExclamationTriangle;
  faTimesCircle = faTimesCircle;

  warehouseId: number | null = null;

  isAddArrivalLoading = false;

  arrival: {
    arrivalDate: string;
    arrivalTime: string;
  };

  loadingCountryId: number;
  registrationNumber: string;

  driver: {
    driverCode: string;
    transportCompany: string;
    driverName: string;
    transportCompanyCountryId: number;
    email: string;
  };

  transportCompanyCountryInput: Country;
  loadingCountryInput: Country;

  reservationCodeSearchLoading = false;

  errors = {
    dateOfArrival: false,
    timeOfArrival: false,
    transportCompany: false,
    driverName: false,
    transportCompanyCountry: false,
    regNumber: false,
    loadingCountry: false,
    warehouseId: false,
  };

  autofill: boolean = false;

  transportCompanyFilled = null;
  nameFilled = null;

  forbiddenCompanyId: number | null = null;
  forbiddenDrivers: ForbiddenDriver[] = [];

  transportCompanyChanged: Subject<string> = new Subject<string>();
  driverNameChanged: Subject<string> = new Subject<string>();

  constructor(
    private http: RestService,
    private msgT: MessageTranslationService,
    private toast: ToastrService,
    private route: ActivatedRoute,
    public countriesService: CountriesService,
    public auth: AuthService
  ) {
    this.auth.hasLoggedInUserLoaded$.subscribe((isDone) => {
      if (isDone) {
        this.warehouseId = this.auth.loggedInUser.defaultWarehouse?.id;
      }
    });

    this.route.queryParams.subscribe((params) => {
      this.autofill = params["au"] === "on" ? true : false;
      const reservationCode = params["rC"];
      if (reservationCode) {
        this.reservationData.code = reservationCode;
        this.searchByReservationCode();
      }
    });

    this.transportCompanyChanged.pipe(debounceTime(DEBOUNCE_TIME), distinctUntilChanged()).subscribe((model) => {
      if (model && model.length > 0) {
        this.checkForbiddenCompany(model);
      } else {
        this.unsetForbiddenCompany();
      }
    });

    this.driverNameChanged.pipe(debounceTime(DEBOUNCE_TIME), distinctUntilChanged()).subscribe((model) => {
      if (model && model.length > 0) {
        this.checkForbiddenDriver(model);
      } else {
        this.unsetForbiddenDriver();
      }
    });
  }

  transportCompanies: string[] = [];
  driverNames: string[] = [];
  registrationNumbers: string[] = [];
  driverCodes: string[] = [];

  driverNotDisplayedWarning = false;

  warehouses: Warehouse[] = [];

  reservationData: {
    code: string;
    id: number | null;
    isRecurring: boolean;
  } = {
    code: "",
    id: null,
    isRecurring: false,
  };

  onChangeSearch(val: string) {
    this.driver.transportCompany = val;
  }

  ngOnInit() {
    this.GetWarehouses();
    this.setEmptyInputs();

    this.getAutocompleteData();

    if (this.autofill) {
      this.driver = {
        driverCode: "",
        transportCompany: "Transports TT",
        driverName: "Janez Novak",
        transportCompanyCountryId: null,
        email: "",
      };

      this.loadingCountryId = null;
      this.registrationNumber = "MB 123ABC";
    }
  }

  GetWarehouses() {
    this.http.get(`api/authentication/listWarehouses`).subscribe(
      (w: Warehouse[]) => {
        this.warehouses = w;
      },
      (error) => {
        console.log("Fidn warehouses error");
        console.log(error);
      }
    );
  }

  getAutocompleteData() {
    this.getTransportCompanies();
    this.getDriverNames();
    this.getRegistrationNumbers();
  }

  getTransportCompanies() {
    this.http
      .post(`api/driver/getAllTransportCompanies`, {
        name: this.nameFilled,
      })
      .subscribe(
        (companies: string[]) => {
          this.transportCompanies = companies;
        },
        (error) => {
          console.log("Error fetching transport companies");
          console.log(error.error);
        }
      );
  }

  getDriverNames() {
    this.http
      .post(`api/driver/getAllNames`, {
        transportCompany: this.transportCompanyFilled,
      })
      .subscribe(
        (names: string[]) => {
          this.driverNames = names;
        },
        (error) => {
          console.log("Error fetching driver names");
          console.log(error.error);
        }
      );
  }

  getRegistrationNumbers() {
    this.http
      .post(`api/driver/getAllRegistrationNumbers`, {
        transportCompany: this.transportCompanyFilled,
        name: this.nameFilled,
      })
      .subscribe(
        (reg: string[]) => {
          this.registrationNumbers = reg;
        },
        (error) => {
          console.log("Error fetching registration numbers");
          console.log(error.error);
        }
      );
  }

  setEmptyInputs() {
    this.loadingCountryId = null;
    this.registrationNumber = "";
    this.reservationData.code = "";
    this.reservationData.isRecurring = false;

    this.arrival = {
      arrivalDate: moment().format("DD. MM. YYYY"),
      arrivalTime: moment().format("HH:mm"),
    };

    this.driver = {
      driverCode: "",
      transportCompany: "",
      driverName: "",
      transportCompanyCountryId: null,
      email: "",
    };

    this.warehouseId = this.auth.loggedInUser?.defaultWarehouse?.id;

    this.nameFilled = null;
    this.transportCompanyFilled = null;

    this.transportCompanyCountryInput = null;
    this.loadingCountryInput = null;

    this.unsetForbiddenDriver();
    this.unsetForbiddenCompany();
  }

  onDropBoxSelect(selectedCode) {
    this.driver.driverCode = selectedCode;
    this.clearDataAndsearchID();
  }

  async searchByReservationCode() {
    if (this.reservationData.code.trim().length === 0) {
      return;
    }

    try {
      this.reservationCodeSearchLoading = true;
      const result = await this.http
        .post<GetDriverDataByReservationCodeResponse>(`api/driver/getDriverDataByReservationCode`, {
          code: this.reservationData.code.trim(),
        })
        .toPromise();
      this.toast.success(this.msgT.reservationAutofilledSuccess());
      this.reservationData = {
        code: result.code,
        id: result.id,
        isRecurring: result.isRecurring,
      };
      this.warehouseId = result.warehouseId;
      this.driver.driverCode = result.driverCode || "";
      this.driver.email = result.driverEmail || "";
      this.driver.driverName = result.driverName || "";
      this.registrationNumber = result.driverRegistration || "";

      if (result.driverCompany) {
        this.driver.transportCompany = result.driverCompany;
        this.transportCompanyChanged.next(result.driverCompany);
      }

      if (result.driverCompanyCounty) {
        this.transportCompanyCountryInput = result.driverCompanyCounty;
        this.driver.transportCompanyCountryId = result.driverCompanyCounty.id;
      }

      console.log(result.loadingCountry);
      if (result.loadingCountry) {
        this.loadingCountryInput = result.loadingCountry;
        this.loadingCountryId = result.loadingCountry.id;
      }
    } catch (e) {
      console.error(e);
      this.toast.error(this.msgT.reservationWithIDNotFound());
    } finally {
      this.reservationCodeSearchLoading = false;
    }
  }

  clearDataAndsearchID() {
    this.GetDriver(false, false, true);
  }

  GetDriver(sendName: boolean = true, sendTransportCompany: boolean = true, sendID: boolean = true) {
    var id = this.driver.driverCode;
    const name = this.driver.driverName;
    const transportCompany = this.driver.transportCompany;
    //var driversCodes;

    if (id == null || (id.length === 0 && name == null)) {
      return;
    }
    let params = new HttpParams();
    if (sendID == true) params = params.set("id", id);
    if (sendName == true) {
      params = params.set("name", name);
    }
    if (sendTransportCompany == true) {
      params = params.set("transportCompany", transportCompany);
    }

    this.http.get(`api/driver/get/`, undefined, params).subscribe(
      (
        d: {
          name: string;
          transportCompanyCountry: Country;
          dateSignedContract: string;
          transportCompany: string;
          email: string;
          code: string;
          id: number;
        }[]
      ) => {
        this.driverNotDisplayedWarning = false;

        if (d == null) {
          this.toast.error(this.msgT.driverWithIDNotFound(id));
        } else if (d.length == 1) {
          this.driver = {
            driverCode: d[0].code,
            driverName: d[0].name,
            transportCompanyCountryId: d[0].transportCompanyCountry.id,
            transportCompany: d[0].transportCompany,
            email: d[0].email,
          };

          this.transportCompanyCountryInput = d[0].transportCompanyCountry;

          this.driverNameChanged.next(d[0].name);
          this.transportCompanyChanged.next(d[0].transportCompany);
        } else if (d.length > 1) {
          let setDriverNotDisplayedWarning = false;
          var i = 0;
          this.driverCodes = [];

          while (i < d.length) {
            if (d[i].code != null && d[i].code.trim().length > 0) {
              this.driverCodes.push(d[i].code);
            } else {
              setDriverNotDisplayedWarning = true;
            }
            i++;
          }

          this.driverNotDisplayedWarning = setDriverNotDisplayedWarning;
          return this.driverCodes;
        } else if (d.length === 0) {
          this.driverCodes = [];
        }
      },
      (error) => {
        console.log("Fetching driver error");
        console.log(error.error);
        this.toast.error(this.msgT.driverWithIDNotFound(id));
      }
    );
  }

  AddArrival() {
    let valid = true;
    let validationErrors = "";
    this.resetErrors();
    let h = new Date().getHours() + ":" + new Date().getMinutes();
    this.arrival.arrivalTime = h;

    if (this.warehouseId == null) {
      valid = false;
      this.errors.warehouseId = true;
    }

    // todo: validate better
    if (!moment(this.arrival.arrivalDate, "DD. MM. YYYY").isValid()) {
      valid = false;
      this.errors.dateOfArrival = true;
    }

    if (!moment(this.arrival.arrivalTime, "HH:mm").isValid()) {
      valid = false;
      this.errors.timeOfArrival = true;
    }

    if (this.loadingCountryId == null) {
      valid = false;
      this.errors.loadingCountry = true;
    }

    if (this.nullOrEmpty(this.driver.transportCompany)) {
      valid = false;
      this.errors.transportCompany = true;
    }

    if (this.nullOrEmpty(this.driver.driverName)) {
      valid = false;
      this.errors.driverName = true;
    }

    if (this.driver.transportCompanyCountryId == null) {
      valid = false;
      this.errors.transportCompanyCountry = true;
    }

    if (this.nullOrEmpty(this.registrationNumber)) {
      valid = false;
      this.errors.regNumber = true;
    }

    if (!valid) {
      this.toast.error(this.msgT.errorValidatingForm());
      return;
    }

    const arrivalTime = moment(this.arrival.arrivalDate + " " + this.arrival.arrivalTime, "DD. MM. YYYY HH:mm")
      .utc()
      .toDate();

    console.log("arrivalTime", arrivalTime);

    this.isAddArrivalLoading = true;

    this.http
      .post(`api/yardManagement/addArrival`, {
        reservationId: this.reservationData.id || null,
        reservationCode: this.reservationData.code.trim() || null,
        isReservationRecurring: this.reservationData.isRecurring,
        loadingCountryId: this.loadingCountryId,
        driver: this.driver,
        arrivalTime,
        registrationNumber: this.registrationNumber,
        warehouseId: this.warehouseId,
      })
      .subscribe(
        (d) => {
          this.toast.success(this.msgT.addNewArrivalSuccess());
          this.setEmptyInputs();
          this.getAutocompleteData();
          this.isAddArrivalLoading = false;
        },
        (error) => {
          console.log("Error adding arrival");
          console.log(error.error);
          this.toast.error(this.msgT.addNewArrivalError());
          this.isAddArrivalLoading = false;
        }
      );
  }

  private nullOrEmpty(val: string): boolean {
    return val == null || val.trim().length === 0;
  }

  onSelectTransportCompany(val) {
    this.transportCompanyFilled = val;
    this.getDriverNames();
    this.getRegistrationNumbers();

    this.transportCompanyChanged.next(val);
  }

  onChangeTransportCompany(cleared: boolean = false) {
    if (cleared) {
      this.driver.transportCompany = "";
    }

    if (this.transportCompanyFilled != null) {
      this.transportCompanyFilled = null;
      this.getDriverNames();
      this.getRegistrationNumbers();
    }

    this.transportCompanyChanged.next(this.driver.transportCompany);
  }

  onSelectName(val) {
    this.nameFilled = val;
    this.getTransportCompanies();
    this.getRegistrationNumbers();

    this.driverNameChanged.next(val);
  }

  onSelectName2(val) {
    this.driver.driverName = val;
    this.GetDriver(true, false, false);
  }

  onChangeName(cleared: boolean = false) {
    if (cleared) {
      this.driver.driverName = "";
    }

    if (this.nameFilled != null) {
      this.nameFilled = null;
      this.getTransportCompanies();
      this.getRegistrationNumbers();
    }

    this.driverNameChanged.next(this.driver.driverName);
  }

  checkForbiddenDriver(name: string) {
    if (!name || name.length === 0) {
      return;
    }

    this.http
      .post(`api/driver/getForbiddenDriversByText`, {
        name: name.trim(),
      })
      .subscribe(
        (drivers: ForbiddenDriver[]) => {
          if (drivers && drivers.length > 0) {
            this.forbiddenDrivers = drivers;
          } else {
            this.unsetForbiddenDriver();
          }
        },
        (error) => {
          this.unsetForbiddenDriver();
        }
      );
  }

  checkForbiddenCompany(name: string) {
    if (!name || name.length === 0) {
      return;
    }

    this.http
      .post(`api/yardManagement/getForbiddenTransportCompanyByText`, {
        name: name.trim(),
      })
      .subscribe(
        (company: ForbiddenTransportCompany) => {
          if (company) {
            this.forbiddenCompanyId = company.id;
          } else {
            this.unsetForbiddenCompany();
          }
        },
        (error) => {
          this.unsetForbiddenCompany();
        }
      );
  }

  unsetForbiddenDriver() {
    this.forbiddenDrivers = [];
  }

  unsetForbiddenCompany() {
    this.forbiddenCompanyId = null;
  }

  onSelectTransportCompanyCountry(e: any) {
    this.driver.transportCompanyCountryId = e ? e.id : null;
  }

  onSelectLoadingCountry(e: Country | null) {
    this.loadingCountryId = e ? e.id : null;
  }

  resetErrors() {
    this.errors = {
      dateOfArrival: false,
      timeOfArrival: false,
      transportCompany: false,
      driverName: false,
      transportCompanyCountry: false,
      regNumber: false,
      loadingCountry: false,
      warehouseId: false,
    };
  }
}
