<div class="autocomplete-container d-flex align-items-center">
  <app-autocomplete-input
    style="flex: 1"
    class="mr-1"
    [searchKeyword]="'name'"
    [(value)]="selectedCountry"
    [data]="countriesService.countries"
    [editable]="false"
    (onKeydownEnter)="onCountryAdd()"
  ></app-autocomplete-input>
  <button
    type="button"
    class="btn btn-primary ml-1"
    i18n
    [disabled]="selectedCountry == null"
    (click)="onCountryAdd()"
  >
    Add
  </button>
</div>

<div class="mt-3"></div>

<div *ngFor="let country of countries" class="d-flex mt-1">
  <div>{{ country.name }}</div>
  <fa-icon
    class="text-danger ml-1"
    style="cursor: pointer"
    [icon]="faTimes"
    (click)="onCountryRemove(country)"
  ></fa-icon>
</div>
