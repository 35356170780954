import { Injectable, Inject, LOCALE_ID } from "@angular/core";
import { ArrivalStatus } from "./models/ArrivalStatus";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class MessageTranslationService {
  translations;

  constructor(@Inject(LOCALE_ID) public locale: string) {
    if (locale === "sl") {
      this.translations = this.translationsSl;
      moment.locale("sl");
    } else {
      this.translations = this.translationsEn;
    }
  }

  private translationsEn = {
    CHANGE_STATUS_SUCCESS: "Status updated!",
    ARRIVAL_MOVED_TO_ARCHIVE: "Marked as departed and moved to archive!",
    CHANGE_STATUS_ERROR: "Error while changing status!",
    FETCH_HISTORY_ERROR: "Error while fetching history!",
    ARRIVAL_ADDED_SUCCESS: "Arrival added!",
    ARRIVAL_ADDED_ERROR: "Error while adding arrival!",
    WELCOME: "Welcome",
    LOGIN_ERROR: "Login error! Check your credentials.",
    REGISTER_SUCCESS: "Account created! Administrator will approve you shortly.",
    REGISTER_ERROR: "Failed to create an account.",
    PASSWORDS_DONT_MATCH: "Passwords don't match!",
    LOGOUT_SUCCESS: "Logout success!",
    LOGOUT_ERROR: "Logout error!",
    ARRIVAL: "Arrival",
    DEPARTURE: "Departure",
    UNLOADING_STARTED: "Unloading start",
    UNLOADING_DONE: "Unloading end",
    FETCH_ARRIVAL_ERROR: "Error fetching the arrival!",
    MARK_CONTRACT_SUCCESS: "Contract marked as uploaded. Don't forget to upload long term contracts in drivers menu!",
    MARK_CONTRACT_ERROR: "Error marking contract as uploaded!",
    CHANGE_NAME_SUCCESS: "Successfuly updated the name!",
    CHANGE_NAME_ERROR: "Error while changing name!",
    CHANGE_ROLE_SUCCESS: "Successfuly updated the role!",
    CHANGE_ROLE_ERROR: "Error while changing role!",
    CHANGE_WAREHOUSE_SUCCESS: "Successfuly updated the warehouse!",
    CHANGE_WAREHOUSE_ERROR: "Error while changing warehouse!",
    CHANGE_PASSWORD_SUCCESS: "Successfuly updated the password!",
    CHANGE_PASSWORD_ERROR: "Error while changing password!",
    UPDATE_ARRIVAL_SUCCESS: "Arrival successfuly updated!",
    UPDATE_ARRIVAL_ERROR: "Error while updating arrival!",
    DELETE_ARRIVAL_SUCCESS: "Arrival successfuly deleted!",
    DELETE_ARRIVAL_ERROR: "Error while deleting arrival!",
    DELETE_ARRIVAL_HAS_RAMP_ERROR: "Error while deleting arrival: arrival is on a ramp!",
    FETCH_DRIVERS_ERROR: "Error while fetching drivers!",
    FETCH_DRIVER_ERROR: "Error while fetching driver!",
    FETCH_COMPANIES_ERROR: "Error while fetching companies!",
    FETCH_COMPANY_ERROR: "Error while fetching company!",
    FILE: "File",
    READY_FOR_UPLOAD_FOR_DRIVER: "is ready for upload for driver",
    UPLOAD_FILE_ERROR: "Error uploading selected file!",
    PLEASE_SELECT_CONTRACT_FILE: "Choose a contract file",
    INVALID_SIGN_DATE_FOR_DRIVER: "Invalid sign date for driver",
    INVALID_DRIVER_CODE: "Assign code to the driver!",
    UPLOAD_CONTRACT_SUCCESS_FOR_DRIVER: "Contract upload success for driver",
    UPLOAD_CONTRACT_ERROR_FOR_DRIVER: "Contract upload error for driver",
    ALL_CONTRACTS_UPLOADED: "All contracts uploaded!",
    ADD_DRIVER_SUCCESS: "Driver added!",
    ADD_DRIVER_ERROR: "Error while adding driver!",
    UPDATE_DRIVER_SUCCESS: "Driver updated!",
    UPDATE_DRIVER_ERROR: "Error while updating driver!",
    DELETE_DRIVER_SUCCESS: "Driver deleted!",
    DELETE_DRIVER_ERROR: "Error while deleting driver!",
    ADD_COMPANY_SUCCESS: "Company added!",
    ADD_COMPANY_ERROR: "Error while adding company!",
    UPDATE_COMPANY_SUCCESS: "Company updated!",
    UPDATE_COMPANY_ERROR: "Error while updating company!",
    DELETE_COMPANY_SUCCESS: "Company deleted!",
    DELETE_COMPANY_ERROR: "Error while deleting company!",
    DATE_RANGE_INVALID: "Invalid date range!",
    DRIVER_WITH_ID: "Driver with id",
    NOT_FOUND: "not found",
    ALREADY_EXISTS: "already exists",
    RESERVATION_AUTOFILLED_SUCCESS: "The data from the reservation was autofilled!",
    RESERVATION_WITH_ID_NOT_FOUND: "Reservation with id not found!",
    ERROR_VALIDATING_FORM: "Error validating form! Please check input data.",
    ADD_ARRIVAL_SUCCESS: "Arrival added!",
    ADD_ARRIVAL_ERROR: "Error adding the arrival!",
    PASSWORD_TOO_WEAK:
      "Your password is too weak. It has to be at least 10 characters long and must contain 3 of the following sets: upper case, lower case, numbers and special characters.",
    FETCH_RAMPS_ERROR: "Error while fetching ramps!",
    FETCH_USERS_ERROR: "Error while fetching users!",
    CONFIRM_USER_SUCCESS: "User confirmed!",
    DISABLE_USER_SUCCESS: "User disabled!",
    UPDATE_USER_SUCCESS: "User updated!",
    UPDATE_USER_ERROR: "Error while updating user!",
    DELETE_USER_SUCCESS: "User deleted!",
    DELETE_USER_ERROR: "Error while deleting user!",
    REGISTER_ADMIN_SUCCESS: "New admin registered!",
    REGISTER_ADMIN_ERROR: "Error while registering new admin!",
    EMAIL_TAKEN_ERROR: "Registration error: email exists!",
    UNDO_STATUS_SUCCESS: "Status undone successfully!",
    UNDO_STATUS_ERROR: "Error while undoing status!",
    AT_RAMP: "At ramp",
    RAMP: "Ramp",
    CURRENTLY_TAKEN: "is currently taken!",
    FETCH_COMBINATIONS_ERROR: "Error while fetching combinations!",
    CONTRACT_DOESNT_EXIST: "Contract with given language and type does not exist yet! Please add it or contact your administrator.",
    ENTER_ARRIVAL_ID: "Enter arrival ID!",
    ARRIVAL_WITH_ID_DOESNT_EXIST: "Arrival with given ID doesn't exist!",
    COULD_NOT_GENERATE_THE_CONTRACT: "Could not generate the contract!",
    LANGUAGE_ADDED: "Language added!",
    LANGUAGE_ADDED_ERROR: "Error while adding language!",
    LANGUAGE_EDITED: "Language edited!",
    LANGUAGE_EDITED_ERROR: "Error while editing language!",
    LANGUAGE_DELETED: "Language deleted!",
    LANGUAGE_DELETED_ERROR: "Error while deleting language!",
    TEMPLATE_ADDED: "Template added!",
    TEMPLATE_ADDED_ERROR_EXISTS_ALREADY: "Error while adding template: template with given parameters exists already!",
    TEMPLATE_ADDED_ERROR_WORD: "Error while adding template: provided file is not Word file!",
    TEMPLATE_ADDED_ERROR: "Error while adding template!",
    TEMPLATE_EDITED: "Template edited!",
    TEMPLATE_EDITED_ERROR: "Error while editing template!",
    TEMPLATE_DELETED: "Template deleted!",
    TEMPLATE_DELETED_ERROR: "Error while deleting template!",
    COULD_NOT_GENERATE_FILE: "Could not generate file!",
    COULD_NOT_CONFIRM_CONTRACT: "Could not confirm the contract!",
    PLEASE_REVIEW_CONTRACT: "Contract has been signed. Please review it and confirm or sign again.",
    SUCCESS_DELETING_CONTRACT: "Contract deleted!",
    ERROR_DELETING_CONTRACT: "Error deleting contract!",
    UNKNOWN_ERROR: "Unknown error has occured!",
    SUCCESS: "Success!",
  };

  private translationsSl = {
    CHANGE_STATUS_SUCCESS: "Status posodobljen!",
    ARRIVAL_MOVED_TO_ARCHIVE: "Odhod označen in prestavljen v arhiv!",
    CHANGE_STATUS_ERROR: "Napaka pri spreminjanju statusa!",
    FETCH_HISTORY_ERROR: "Napaka pri pridobivanju seznama prihodov!",
    ARRIVAL_ADDED_SUCCESS: "Prihod dodan!",
    ARRIVAL_ADDED_ERROR: "Napaka pri dodajanju prihoda!",
    WELCOME: "Dobrodošli",
    LOGIN_ERROR: "Napaka pri vpisu. Preverite mail in geslo.",
    REGISTER_SUCCESS: "Račun ustvarjen. Administrator vas bo kmalu potrdil.",
    REGISTER_ERROR: "Napaka pri ustvarjanju računa.",
    PASSWORDS_DONT_MATCH: "Gesli se ne ujemata!",
    LOGOUT_SUCCESS: "Uspešno ste se odjavili!",
    LOGOUT_ERROR: "Napaka pri odjavi!",
    ARRIVAL: "Prihod",
    DEPARTURE: "Odhod",
    UNLOADING_STARTED: "Pričetek raztovarjanja",
    UNLOADING_DONE: "Konec raztovarjanja",
    FETCH_ARRIVAL_ERROR: "Napaka pri pridobivanju prihoda!",
    MARK_CONTRACT_SUCCESS: "Pogodba označena kot podpisana. Ne pozabite naložiti dologoročne pogodbe v seznamu voznikov!",
    MARK_CONTRACT_ERROR: "Napaka pri označevanju pogodbe!",
    CHANGE_NAME_SUCCESS: "Uspešno spremenjeno ime!",
    CHANGE_NAME_ERROR: "Napaka pri spreminjanju imena!",
    CHANGE_ROLE_SUCCESS: "Uspešno spremenjena vloga!",
    CHANGE_ROLE_ERROR: "Napaka pri spreminjanju vloge!",
    CHANGE_WAREHOUSE_SUCCESS: "Uspešno spremenjeno skladišče!",
    CHANGE_WAREHOUSE_ERROR: "Napaka pri spreminjanju skladišča!",
    CHANGE_PASSWORD_SUCCESS: "Uspešno spremenjeno geslo!",
    CHANGE_PASSWORD_ERROR: "Napaka pri spreminjanju gesla!",
    UPDATE_ARRIVAL_SUCCESS: "Prihod uspešno posodobljen!",
    UPDATE_ARRIVAL_ERROR: "Napaka pri posodabljanju prihoda!",
    DELETE_ARRIVAL_SUCCESS: "Prihod uspešno izbrisan!",
    DELETE_ARRIVAL_ERROR: "Napaka pri brisanju prihoda!",
    DELETE_ARRIVAL_HAS_RAMP_ERROR: "Napaka pri brisanju prihoda: prihod je na rampi!",
    FETCH_DRIVERS_ERROR: "Napaka pri pridobivanju voznikov!",
    FETCH_DRIVER_ERROR: "Napaka pri pridobivanju voznika!",
    FETCH_COMPANIES_ERROR: "Napaka pri pridobivanju podjetij!",
    FETCH_COMPANY_ERROR: "Napaka pri pridobivanju podjetja!",
    FILE: "Datoteka",
    READY_FOR_UPLOAD_FOR_DRIVER: "je pripravljena za nalaganje za voznika",
    UPLOAD_FILE_ERROR: "Napaka pri nalaganju izbrane datoteke!",
    PLEASE_SELECT_CONTRACT_FILE: "Izberite datoteko",
    INVALID_SIGN_DATE_FOR_DRIVER: "Napačen datum podpisa za voznika",
    INVALID_DRIVER_CODE: "Vozniku je potrebno nastaviti kodo!",
    UPLOAD_CONTRACT_SUCCESS_FOR_DRIVER: "Nalaganje pogodbe uspešno za voznika",
    UPLOAD_CONTRACT_ERROR_FOR_DRIVER: "Nalaganje pogodbe neuspešno za voznika",
    ALL_CONTRACTS_UPLOADED: "Vse pogodbe uspešno naložene!",
    ADD_DRIVER_SUCCESS: "Voznik uspešno dodan!",
    ADD_DRIVER_ERROR: "Napaka pri dodajanju voznika!",
    UPDATE_DRIVER_SUCCESS: "Voznik uspešno posodobljen!",
    UPDATE_DRIVER_ERROR: "Napaka pri posodabljanju voznika!",
    DELETE_DRIVER_SUCCESS: "Voznik uspešno izbrisan!",
    DELETE_DRIVER_ERROR: "Napaka pri brisanju voznika!",
    ADD_COMPANY_SUCCESS: "Podjetje uspešno dodano!",
    ADD_COMPANY_ERROR: "Napaka pri dodajanju podjetja!",
    UPDATE_COMPANY_SUCCESS: "Podjetje uspešno posodobljeno!",
    UPDATE_COMPANY_ERROR: "Napaka pri posodabljanju podjetja!",
    DELETE_COMPANY_SUCCESS: "Podjetje uspešno izbrisano!",
    DELETE_COMPANY_ERROR: "Napaka pri brisanju podjetja!",
    DATE_RANGE_INVALID: "Napačen razpon datumov!",
    DRIVER_WITH_ID: "Voznik z id-jem",
    NOT_FOUND: "ni bil najden",
    ALREADY_EXISTS: "že obstaja",
    ERROR_VALIDATING_FORM: "Napaka pri validiranju podatkov. Preverite vnosna polja!",
    RESERVATION_AUTOFILLED_SUCCESS: "Podatki iz rezervacije so se samodejno izpolnili!",
    RESERVATION_WITH_ID_NOT_FOUND: "Rezervacija z id-jem ne obstaja!",
    ADD_ARRIVAL_SUCCESS: "Prihod dodan!",
    ADD_ARRIVAL_ERROR: "Napaka pri dodajanju prihoda!",
    PASSWORD_TOO_WEAK:
      "Geslo ni dovolj močno. Dolgo mora biti vsaj 10 znakov in vsebovati 3 izmed naslednjih skupin: velike črke, male črke, številke in posebni znaki.",
    FETCH_RAMPS_ERROR: "Napaka pri pridobivanju ramp!",
    FETCH_USERS_ERROR: "Napaka pri pridobivanju uporabnikov!",
    CONFIRM_USER_SUCCESS: "Uporabnik potrjen!",
    DISABLE_USER_SUCCESS: "Uporabnik onemogočen!",
    UPDATE_USER_SUCCESS: "Uporabnik posodobljen!",
    UPDATE_USER_ERROR: "Napaka pri posodabljanju uporabnika!",
    DELETE_USER_SUCCESS: "Uporabnik izbrisan!",
    DELETE_USER_ERROR: "Napaka pri brisanju uporabnika!",
    REGISTER_ADMIN_SUCCESS: "Nov administrator registriran!",
    REGISTER_ADMIN_ERROR: "Napaka pri registraciji novega administratorja!",
    EMAIL_TAKEN_ERROR: "Napaka pri registraciji: email že obstaja!",
    UNDO_STATUS_SUCCESS: "Status uspešno razveljavljen!",
    UNDO_STATUS_ERROR: "Napaka pri razveljavitvi statusa!",
    AT_RAMP: "Pri rampi",
    RAMP: "Rampa",
    CURRENTLY_TAKEN: "je trenutno zasedena!",
    FETCH_COMBINATIONS_ERROR: "Napaka pri pridobivanju kombinacij!",
    CONTRACT_DOESNT_EXIST: "Pogodba s podanim jezikom in tipom še ne obstaja! Prosimo dodajte jo ali kontaktirajte administratorja.",
    ENTER_ARRIVAL_ID: "Vnesite ID prihoda!",
    ARRIVAL_WITH_ID_DOESNT_EXIST: "Prihod s podanim ID-jem ne obstaja!",
    COULD_NOT_GENERATE_THE_CONTRACT: "Pogodbe ni bilo mogoče ustvariti!",
    LANGUAGE_ADDED: "Jezik dodan!",
    LANGUAGE_ADDED_ERROR: "Napaka pri dodajanju jezika!",
    LANGUAGE_EDITED: "Jezik urejen!",
    LANGUAGE_EDITED_ERROR: "Napaka pri urejanju jezika!",
    LANGUAGE_DELETED: "Jezik izbrisan!",
    LANGUAGE_DELETED_ERROR: "Napaka pri brisanju jezika!",
    TEMPLATE_ADDED: "Vzorec dodan!",
    TEMPLATE_ADDED_ERROR_EXISTS_ALREADY: "Napaka pri dodajnaju vzorca: vzorec s podanimi parametri že obstaja!",
    TEMPLATE_ADDED_ERROR_WORD: "Napaka pri dodajanju vzorca: podana datoteka ni tipa Word!",
    TEMPLATE_ADDED_ERROR: "Napaka pri dodajanju vzorca!",
    TEMPLATE_EDITED: "Vzorec urejen!",
    TEMPLATE_EDITED_ERROR: "Napaka pri urejanju vzorca!",
    TEMPLATE_DELETED: "Vzorec izbrisan!",
    TEMPLATE_DELETED_ERROR: "Napaka pri brisanju vzorca!",
    COULD_NOT_GENERATE_FILE: "Datoteke ni bilo mogoče zgenerirati!",
    COULD_NOT_CONFIRM_CONTRACT: "Pogodbe ni bilo mogoče potrditi!",
    PLEASE_REVIEW_CONTRACT: "Pogodba je bila podpisana. Prosimo preglejte jo in potrdite ali podpišite še enkrat.",
    SUCCESS_DELETING_CONTRACT: "Pogodba izbrisana!",
    ERROR_DELETING_CONTRACT: "Napaka pri brisanju pogodbe!",
    UNKNOWN_ERROR: "Zgodila se je neznana napaka!",
    SUCCESS: "Uspeh!",
  };

  unknownError(): string {
    return this.translations.UNKNOWN_ERROR;
  }

  success(): string {
    return this.translations.SUCCESS;
  }

  public contractDoesntExist() {
    return this.translations.CONTRACT_DOESNT_EXIST;
  }

  public enterArrivalID() {
    return this.translations.ENTER_ARRIVAL_ID;
  }

  public arrivalWithIDDoesntExist() {
    return this.translations.ARRIVAL_WITH_ID_DOESNT_EXIST;
  }

  public couldNotGenerateContract() {
    return this.translations.COULD_NOT_GENERATE_THE_CONTRACT;
  }

  public languageAdded() {
    return this.translations.LANGUAGE_ADDED;
  }

  public languageAddedError() {
    return this.translations.LANGUAGE_ADDED_ERROR;
  }

  public languageEdited() {
    return this.translations.LANGUAGE_EDITED;
  }

  public languageEditedError() {
    return this.translations.LANGUAGE_EDITED_ERROR;
  }

  public languageDeleted() {
    return this.translations.LANGUAGE_DELETED;
  }

  public languageDeletedError() {
    return this.translations.LANGUAGE_DELETED_ERROR;
  }

  public templateAdded() {
    return this.translations.TEMPLATE_ADDED;
  }

  public templateAddedErrorExistsAlready() {
    return this.translations.TEMPLATE_ADDED_ERROR_EXISTS_ALREADY;
  }

  public templateAddedErrorWord() {
    return this.translations.TEMPLATE_ADDED_ERROR_WORD;
  }

  public templateAddedError() {
    return this.translations.TEMPLATE_ADDED_ERROR;
  }

  public templateEdited() {
    return this.translations.TEMPLATE_EDITED;
  }

  public templateEditedError() {
    return this.translations.TEMPLATE_EDITED_ERROR;
  }

  public templateDeleted() {
    return this.translations.TEMPLATE_DELETED;
  }

  public templateDeletedError() {
    return this.translations.TEMPLATE_DELETED_ERROR;
  }

  public couldNotGenerateFile() {
    return this.translations.COULD_NOT_GENERATE_FILE;
  }

  public couldNotConfirmContract() {
    return this.translations.COULD_NOT_CONFIRM_CONTRACT;
  }

  public pleaseReviewContract() {
    return this.translations.PLEASE_REVIEW_CONTRACT;
  }

  public changeStatusSuccess() {
    return this.translations.CHANGE_STATUS_SUCCESS;
  }

  public arrivalMovedToArchive() {
    return this.translations.ARRIVAL_MOVED_TO_ARCHIVE;
  }

  public changeStatusError() {
    return this.translations.CHANGE_STATUS_ERROR;
  }

  public fetchHistoryError() {
    return this.translations.FETCH_HISTORY_ERROR;
  }

  public fetchArrivalError() {
    return this.translations.FETCH_ARRIVAL_ERROR;
  }

  public arrivalAddedSuccess() {
    return this.translations.ARRIVAL_ADDED_SUCCESS;
  }

  public arrivalAddedError() {
    return this.translations.ARRIVAL_ADDED_ERROR;
  }

  public loginSuccess(userName) {
    return this.translations.WELCOME + ", " + userName;
  }

  public loginError() {
    return this.translations.LOGIN_ERROR;
  }

  public passwordDontMatch() {
    return this.translations.PASSWORDS_DONT_MATCH;
  }

  public registerSuccess() {
    return this.translations.REGISTER_SUCCESS;
  }

  public registerError() {
    return this.translations.REGISTER_ERROR;
  }

  public loggedOutSuccess() {
    return this.translations.LOGOUT_SUCCESS;
  }

  public loggedOutError() {
    return this.translations.LOGOUT_ERROR;
  }

  public arrivalStatusToString = (arr: ArrivalStatus) => {
    switch (arr) {
      case ArrivalStatus.Arrived:
        return this.translations.ARRIVAL;
      case ArrivalStatus.UnloadingStarted:
        return this.translations.UNLOADING_STARTED;
      case ArrivalStatus.UnloadingDone:
        return this.translations.UNLOADING_DONE;
      case ArrivalStatus.Departed:
        return this.translations.DEPARTURE;
      case ArrivalStatus.AtRamp:
        return this.translations.AT_RAMP;
    }
  };

  public markContractSuccess() {
    return this.translations.MARK_CONTRACT_SUCCESS;
  }

  public markContractError() {
    return this.translations.MARK_CONTRACT_ERROR;
  }

  public changeNameSuccess() {
    return this.translations.CHANGE_NAME_SUCCESS;
  }

  public changeNameError() {
    return this.translations.CHANGE_NAME_ERROR;
  }

  public changeRoleSuccess() {
    return this.translations.CHANGE_ROLE_SUCCESS;
  }

  public changeRoleError() {
    return this.translations.CHANGE_ROLE_ERROR;
  }

  public changeWarehouseSuccess() {
    return this.translations.CHANGE_WAREHOUSE_SUCCESS;
  }

  public changeWarehouseError() {
    return this.translations.CHANGE_WAREHOUSE_ERROR;
  }

  public changePasswordSuccess() {
    return this.translations.CHANGE_PASSWORD_SUCCESS;
  }

  public changePasswordError() {
    return this.translations.CHANGE_PASSWORD_ERROR;
  }

  public updateArrivalSuccess() {
    return this.translations.UPDATE_ARRIVAL_SUCCESS;
  }

  public updateArrivalError() {
    return this.translations.UPDATE_ARRIVAL_ERROR;
  }

  public deleteArrivalSuccess() {
    return this.translations.DELETE_ARRIVAL_SUCCESS;
  }

  public deleteArrivalError() {
    return this.translations.DELETE_ARRIVAL_ERROR;
  }

  public deleteArrivalErrorArrivalHasRamp() {
    return this.translations.DELETE_ARRIVAL_HAS_RAMP_ERROR;
  }

  public fetchDriversError() {
    return this.translations.FETCH_DRIVERS_ERROR;
  }

  public fetchDriverError() {
    return this.translations.FETCH_DRIVER_ERROR;
  }

  public readyForUpload(fileName, driverName) {
    return this.translations.FILE + " " + fileName + " " + this.translations.READY_FOR_UPLOAD_FOR_DRIVER + " " + driverName + ".";
  }

  public uploadFileError() {
    return this.translations.UPLOAD_FILE_ERROR;
  }

  public pleaseSelectContactFile() {
    return this.translations.PLEASE_SELECT_CONTRACT_FILE;
  }

  public invalidSignDate(driverName) {
    return this.translations.INVALID_SIGN_DATE_FOR_DRIVER + " " + driverName + "!";
  }

  public invalidDriverCode() {
    return this.translations.INVALID_DRIVER_CODE;
  }

  public uploadContractSuccess(driverName) {
    return this.translations.UPLOAD_CONTRACT_SUCCESS_FOR_DRIVER + " " + driverName + "!";
  }

  public uploadContractError(driverName) {
    return this.translations.UPLOAD_CONTRACT_ERROR_FOR_DRIVER + " " + driverName + "!";
  }

  public allContactsUploaded() {
    return this.translations.ALL_CONTRACTS_UPLOADED;
  }

  public addDriverSuccess() {
    return this.translations.ADD_DRIVER_SUCCESS;
  }

  public addDriverError() {
    return this.translations.ADD_DRIVER_ERROR;
  }

  public updateDriverSuccess() {
    return this.translations.UPDATE_DRIVER_SUCCESS;
  }

  public updateDriverError() {
    return this.translations.UPDATE_DRIVER_ERROR;
  }

  public deleteDriverSuccess() {
    return this.translations.DELETE_DRIVER_SUCCESS;
  }

  public deleteDriverError() {
    return this.translations.DELETE_DRIVER_ERROR;
  }

  public fetchCompaniesError() {
    return this.translations.FETCH_COMPANIES_ERROR;
  }

  public fetchCompanyError() {
    return this.translations.FETCH_COMPANY_ERROR;
  }

  public addCompanySuccess() {
    return this.translations.ADD_COMPANY_SUCCESS;
  }

  public addCompanyError() {
    return this.translations.ADD_COMPANY_ERROR;
  }

  public updateCompanySuccess() {
    return this.translations.UPDATE_COMPANY_SUCCESS;
  }

  public updateCompanyError() {
    return this.translations.UPDATE_COMPANY_ERROR;
  }

  public deleteCompanySuccess() {
    return this.translations.DELETE_COMPANY_SUCCESS;
  }

  public deleteCompanyError() {
    return this.translations.DELETE_COMPANY_ERROR;
  }

  public dateRangeInvalid() {
    return this.translations.DATE_RANGE_INVALID;
  }

  public driverWithIDNotFound(id) {
    return this.translations.DRIVER_WITH_ID + " " + id + " " + this.translations.NOT_FOUND + "!";
  }

  public driverWithIDAlreadyExists(id) {
    return this.translations.DRIVER_WITH_ID + " " + id + " " + this.translations.ALREADY_EXISTS + "!";
  }

  public reservationAutofilledSuccess() {
    return this.translations.RESERVATION_AUTOFILLED_SUCCESS;
  }

  public reservationWithIDNotFound() {
    return this.translations.RESERVATION_WITH_ID_NOT_FOUND;
  }

  public errorValidatingForm() {
    return this.translations.ERROR_VALIDATING_FORM;
  }

  public addNewArrivalSuccess() {
    return this.translations.ADD_ARRIVAL_SUCCESS;
  }

  public addNewArrivalError() {
    return this.translations.ADD_ARRIVAL_ERROR;
  }

  public passwordTooWeak() {
    return this.translations.PASSWORD_TOO_WEAK;
  }

  public fetchRampsError() {
    return this.translations.FETCH_RAMPS_ERROR;
  }

  public fetchUsersError() {
    return this.translations.FETCH_USERS_ERROR;
  }

  public confirmUserSuccess(confirmed: boolean) {
    if (confirmed) {
      return this.translations.CONFIRM_USER_SUCCESS;
    } else {
      return this.translations.DISABLE_USER_SUCCESS;
    }
  }

  public updateUserSuccess() {
    return this.translations.UPDATE_USER_SUCCESS;
  }

  public updateUserError() {
    return this.translations.UPDATE_USER_ERROR;
  }

  public deleteUserSuccess() {
    return this.translations.DELETE_USER_SUCCESS;
  }

  public deleteUserError() {
    return this.translations.DELETE_USER_ERROR;
  }

  public registerAdminSuccess() {
    return this.translations.REGISTER_ADMIN_SUCCESS;
  }

  public registerAdminError() {
    return this.translations.REGISTER_ADMIN_ERROR;
  }

  public emailTakenError() {
    return this.translations.EMAIL_TAKEN_ERROR;
  }

  public undoStatusSuccess() {
    return this.translations.UNDO_STATUS_SUCCESS;
  }

  public undoStatusError() {
    return this.translations.UNDO_STATUS_ERROR;
  }

  public rampIsTakenError(rampId: string) {
    return this.translations.RAMP + " " + rampId + " " + this.translations.CURRENTLY_TAKEN;
  }

  public fetchCombinationsError() {
    return this.translations.FETCH_COMBINATIONS_ERROR;
  }

  public successDeletingContract(): string {
    return this.translations.SUCCESS_DELETING_CONTRACT;
  }

  public errorDeletingContract(): string {
    return this.translations.ERROR_DELETING_CONTRACT;
  }
}
