import { Component, Input, OnInit } from "@angular/core";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-accordion-with-content",
  templateUrl: "./accordion-with-content.component.html",
  styleUrls: ["./accordion-with-content.component.css"],
})
export class AccordionWithContentComponent implements OnInit {
  isOpen: boolean = true;

  @Input() label: string = "";

  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;

  constructor() {}

  ngOnInit(): void {}
}
