import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { RestService } from "../services/rest.service";
import { ForbiddenTransportCompany } from "../models/ForbiddenTransportCompany";
import { ToastrService } from "ngx-toastr";
import { MessageTranslationService } from "../message-translation.service";

@Component({
  selector: "app-forbidden-company",
  templateUrl: "./forbidden-company.component.html",
  styleUrls: ["./forbidden-company.component.css"],
})
export class ForbiddenCompanyComponent implements OnInit {
  company: ForbiddenTransportCompany;

  constructor(
    private route: ActivatedRoute,
    private http: RestService,
    private toast: ToastrService,
    private msgT: MessageTranslationService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const id = params["id"];
      if (id) {
        this.http
          .get(`api/yardManagement/getForbiddenTransportCompany/${id}`)
          .subscribe(
            (company: ForbiddenTransportCompany) => {
              this.company = company;
            },
            (error) => {
              console.log("Error fetching company");
              console.log(error);
              this.toast.error(this.msgT.fetchCompanyError());
            }
          );
      }
    });
  }
}
