<div class="container app-container">
  <ng-container *ngIf="company">
    <h1 class="app-heading">
      <ng-container i18n="Forbidden company">Forbidden company</ng-container>:
      {{ company.name }}
    </h1>
    <div class="text-center table-container">
      <table
        class="table table-borderless table-centralised"
        style="width: 100% !important"
      >
        <tbody>
          <tr>
            <th><span i18n="Name">Name</span>:</th>
            <td>{{ company.name }}</td>
          </tr>

          <tr>
            <th><span i18n="Country">Country</span>:</th>
            <td>{{ company.country.name }}</td>
          </tr>

          <tr>
            <th><span i18n="Forbidden at">Forbidden at</span>:</th>
            <td>{{ company.forbiddenAt | date: "dd.MM.yyyy" }}</td>
          </tr>

          <tr>
            <th><span i18n="Forbidden by">Forbidden by</span>:</th>
            <td>{{ company.addedBy.name }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div style="padding: 10px">
      <h4 i18n="Country">Reason</h4>
      <span style="white-space: pre-line">{{ company.reason }}</span>
    </div>
  </ng-container>
  <ng-container *ngIf="!company">
    <ng-container i18n="No company found">No company found</ng-container>
  </ng-container>
</div>
