<div class="container-fluid app-container arrival-table-container">
  <h1 class="app-heading">
    <ng-container i18n="Forbidden drivers">Forbidden drivers</ng-container>
  </h1>

  <ng-container *ngIf="filteredDrivers">
    <div class="d-flex" class="table-filter-container">
      <input
        class="form-control table-filter-input"
        i18n-placeholder="Filter..."
        placeholder="Filter..."
        type="text"
        (input)="filterTextChange($event.target.value)"
      />
      <button class="btn btn-primary table-refresh-btn" i18n="Refresh" (click)="onRefreshClick()">Refresh</button>
    </div>

    <button class="btn btn-primary mb-2 mt-2" i18n="Add a forbidden driver" (click)="open(content)">Add a forbidden driver</button>

    <ng-container *ngIf="filteredDrivers.length > 0">
      <div class="table-responsive">
        <p i18n="Double click on forbidden driver table cells to change information">
          Double click on table cells 'Name', 'Company', 'Reason' and 'Country' to change information.
        </p>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th i18n="Name">Name</th>
              <th i18n="Company">Company</th>
              <th i18n="Reason">Reason</th>
              <th i18n="Date forbidden">Date forbidden</th>
              <th i18n="Added by">Added by</th>
              <th i18n="Actions">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let driver of filteredDrivers | slice : (pageNumber - 1) * pageSize : (pageNumber - 1) * pageSize + pageSize">
              <td>
                <editable (update)="OnUpdateDriver(driver)">
                  <ng-template viewMode>{{ driver.name }}</ng-template>
                  <ng-template editMode>
                    <input focusable editableOnEnter [(ngModel)]="driver.name" />
                  </ng-template>
                </editable>
              </td>

              <td style="height: 100px">
                <editable style="height: 100%" (update)="OnUpdateDriver(driver)">
                  <ng-template viewMode
                    ><div>{{ driver.transportCompany }}</div></ng-template
                  >
                  <ng-template editMode>
                    <app-autocomplete-input [(value)]="driver.transportCompany" [data]="transportCompanies"></app-autocomplete-input>
                  </ng-template>
                </editable>
              </td>

              <td>
                <editable (update)="OnUpdateDriver(driver)">
                  <ng-template viewMode>{{ truncateReason(driver.reason) }}</ng-template>
                  <ng-template editMode>
                    <textarea
                      focusable
                      id="reason"
                      placeholder="Enter reason"
                      i18n-placeholder
                      class="md-textarea form-control"
                      rows="3"
                      [(ngModel)]="driver.reason"
                      [ngModelOptions]="{ standalone: true }"
                    ></textarea>
                  </ng-template>
                </editable>
              </td>

              <td>{{ driver.forbiddenAt | date : "dd.MM.yyyy" }}</td>

              <td>{{ driver.addedBy ? driver.addedBy.name : "/" }}</td>

              <td>
                <button type="button" (click)="SeeDetails(driver)" i18n="Details" class="btn btn-primary d-block mb-2">Details</button>
                <button type="button" (click)="RemoveDriver(driver)" class="btn btn-danger d-block mb-1">
                  <ng-container i18n="Remove">Remove</ng-container>
                </button>

                <ng-container *ngIf="driver.isModified">
                  <button type="button" (click)="ApplyChanges(driver)" class="btn btn-primary d-block mb-1">
                    <ng-container i18n="Apply changes">Apply changes</ng-container>
                  </button>
                  <button type="button" (click)="UndoChanges(driver)" class="btn btn-primary d-block mb-1">
                    <ng-container i18n="Undo changes">Undo changes</ng-container>
                  </button>
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>

    <br />

    <ngb-pagination [(page)]="pageNumber" [pageSize]="pageSize" [maxSize]="8" [collectionSize]="filteredDrivers.length"></ngb-pagination>
  </ng-container>

  <p *ngIf="filteredDrivers && filteredDrivers.length === 0"><ng-container i18n="No drivers">No drivers</ng-container>.</p>

  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" i18n="Add forbidden driver">Add forbidden driver</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss(false)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <div class="form-group">
        <label for="name"><ng-container i18n="Name">Name</ng-container></label>
        <input
          type="text"
          placeholder="Enter driver name"
          i18n-placeholder
          class="form-control"
          id="name"
          [(ngModel)]="newDriver.name"
          [ngModelOptions]="{ standalone: true }"
        />
      </div>

      <div class="form-group">
        <label for="tCompany" class="d-flex justify-content-between">
          <ng-container i18n="Transport company">Transport company</ng-container>
        </label>

        <div class="autocomplete-container">
          <app-autocomplete-input [(value)]="newDriver.transportCompany" [data]="transportCompanies"></app-autocomplete-input>
        </div>
      </div>

      <div class="form-group">
        <label for="reason"><ng-container i18n="Reason">Reason</ng-container></label>
        <textarea
          id="reason"
          placeholder="Enter reason"
          i18n-placeholder
          class="md-textarea form-control"
          rows="3"
          [(ngModel)]="newDriver.reason"
          [ngModelOptions]="{ standalone: true }"
        ></textarea>
      </div>

      <div class="form-group">
        <label for="dateForbidden"><ng-container i18n="Date to">Date forbidden</ng-container></label>
        <input
          class="form-control"
          placeholder="yyyy-mm-dd"
          name="dateForbidden"
          [(ngModel)]="newDriver.forbiddenAt"
          (click)="dF.toggle()"
          ngbDatepicker
          #dF="ngbDatepicker"
        />
      </div>
    </div>

    <div class="modal-footer d-flex justify-content-between">
      <button type="submit" class="btn" (click)="modal.close(false)">
        <ng-container i18n="Cancel">Cancel</ng-container>
      </button>
      <button type="submit" class="btn btn-primary" [disabled]="!newDriverValid()" (click)="AddDriver()">
        <ng-container i18n="Add">Add</ng-container>
      </button>
    </div>
  </ng-template>
</div>
