export const environment = {
  production: true,
  authServerUrl: "https://login-dev.warehousing.omniopti.eu/auth",
  dashboardUrl: "https://dashboard-dev.warehousing.omniopti.eu",
  owlUrl: "https://owl-dev.warehousing.omniopti.eu",
  languages: [
    {
      name: "SLO 🇸🇮",
      link: "https://sl.yamas-dev.warehousing.omniopti.eu",
    },
    {
      name: "ENG 🇬🇧",
      link: "https://yamas-dev.warehousing.omniopti.eu",
    },
  ],
  apiUrl: "https://api.yamas-dev.warehousing.omniopti.eu",
};