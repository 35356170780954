<div class="container-fluid app-container arrival-table-container">
  <h1 class="app-heading">
    <ng-container i18n="Country combinations">Country combinations</ng-container>
  </h1>

  <ng-container *ngIf="filteredCombinations">
    <div class="d-flex" class="table-filter-container">
      <input
        class="form-control table-filter-input"
        i18n-placeholder="Filter..."
        placeholder="Filter..."
        type="text"
        (input)="filterTextChange($event.target.value)"
      />
      <button class="btn btn-primary mr-1" i18n (click)="openAddModal(addEditContent)">Add</button>
      <button *ngIf="showSeedButton" class="btn btn-primary mr-1" i18n (click)="seedInitData()">Seed</button>
      <button class="btn btn-primary table-refresh-btn mr-1" i18n (click)="onRefreshClick()">Refresh</button>
    </div>

    <ng-container *ngIf="filteredCombinations.length > 0">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th i18n="Country">Country</th>
              <th i18n="Type of permit">Type of permit</th>
              <th i18n="Weight">Weight</th>
              <th i18n="Permit quota">Permit quota</th>
              <th i18n="Cabotage">Cabotage</th>
              <th [width]="'15%'" i18n="Permission not needed?">Permission not needed?</th>
              <th i18n="Actions">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let combination of filteredCombinations">
              <td>{{ combination.thirdCountry.name }}</td>

              <td>{{ combination.typeOfPermit }}</td>

              <td>{{ combination.weight }}</td>

              <td>{{ combination.permitQuota }}</td>

              <td>{{ combination.cabotage }}</td>

              <td>
                <p *ngIf="combination.permissionNotNeeded">Yes</p>
                <p *ngIf="!combination.permissionNotNeeded">No</p>
              </td>

              <td>
                <div class="d-flex flex-column" style="gap: 8px">
                  <button class="btn btn-info" (click)="openEditModal(combination, addEditContent)">Edit</button>
                  <button class="btn btn-danger" (click)="openDeleteModal(combination, deleteContent)">Remove</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
  </ng-container>

  <p *ngIf="filteredCombinations && filteredCombinations.length === 0"><ng-container i18n="No combinations">No combinations</ng-container>.</p>
</div>

<ng-template #addEditContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" i18n>{{ combinationToEdit ? "Edit" : "Add" }} combination</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss(false)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-group">
      <label><ng-container i18n>Third country</ng-container></label>
      <div class="autocomplete-container">
        <app-autocomplete-input
          [searchKeyword]="'name'"
          [(value)]="combinationForForm.thirdCountry"
          [data]="countriesService.countries"
          [editable]="false"
        ></app-autocomplete-input>
      </div>
    </div>

    <div class="form-group">
      <label for="typeOfPermit"><ng-container i18n>Type of permit</ng-container></label>
      <textarea
        class="md-textarea form-control"
        id="typeOfPermit"
        rows="3"
        [(ngModel)]="combinationForForm.typeOfPermit"
        [ngModelOptions]="{ standalone: true }"
      ></textarea>
    </div>

    <div class="form-group">
      <label for="weight"><ng-container i18n>Weight</ng-container></label>
      <textarea
        class="md-textarea form-control"
        id="typeOfPermit"
        rows="3"
        [(ngModel)]="combinationForForm.weight"
        [ngModelOptions]="{ standalone: true }"
      ></textarea>
    </div>

    <div class="form-group">
      <label for="permitQuota"><ng-container i18n>Permit quota</ng-container></label>
      <textarea
        class="md-textarea form-control"
        id="permitQuota"
        rows="3"
        [(ngModel)]="combinationForForm.permitQuota"
        [ngModelOptions]="{ standalone: true }"
      ></textarea>
    </div>

    <div class="form-group">
      <label for="cabotage"><ng-container i18n>Cabotage</ng-container></label>
      <input type="text" class="form-control" id="permitQuota" [(ngModel)]="combinationForForm.cabotage" [ngModelOptions]="{ standalone: true }" />
    </div>

    <app-checkbox [(value)]="combinationForForm.permissionNotNeeded" name="permissionNotNeeded">
      <ng-container i18n>Permission not needed (if unchecked, the combination will be shown as a warning)</ng-container>
    </app-checkbox>
  </div>

  <div class="modal-footer d-flex justify-content-between">
    <button type="submit" class="btn" (click)="modal.close(false)">
      <ng-container i18n>Cancel</ng-container>
    </button>
    <button type="submit" class="btn btn-primary" [disabled]="!isCombinationForFormValid() || loading" (click)="addOrEditCombination()">
      <ng-container i18n>Add</ng-container>
    </button>
  </div>
</ng-template>

<ng-template #deleteContent let-modal>
  <app-confirmation-modal
    *ngIf="combinationToDelete != null"
    [isLoading]="loading"
    header="Delete combination?"
    i18n-header
    subHeader="Confirm deleting the combination for {{ combinationToDelete.thirdCountry.name }}"
    i18n-subHeader
    cancelButtonText="Cancel"
    i18n-cancelButtonText
    confirmButtonText="Delete"
    i18n-confirmButtonText
    confirmButtonClass="btn-danger"
    (onCancel)="dissmisAllModals()"
    (onConfirm)="deleteCombination()"
  >
  </app-confirmation-modal>
</ng-template>
