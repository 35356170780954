<div class="container app-container">
  <ng-container *ngIf="combination">
    <h1 class="app-heading">
      <ng-container i18n="Combination">Combination</ng-container>:
      {{ combination.thirdCountry.name }}
    </h1>
    <div class="table-responsive" *ngIf="renderingService.isDesktop()">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th i18n="Country">Country</th>
            <th i18n="Type of permit">Type of permit</th>
            <th i18n="Weight">Weight</th>
            <th i18n="Permit quota">Permit quota</th>
            <th i18n="Cabotage">Cabotage</th>
            <th i18n="Permission not needed?">Permission not needed?</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ combination.thirdCountry.name }}</td>

            <td>{{ combination.typeOfPermit }}</td>

            <td>{{ combination.weight }}</td>

            <td>{{ combination.permitQuota }}</td>

            <td>{{ combination.cabotage }}</td>

            <td>
              <p *ngIf="combination.permissionNotNeeded">Yes</p>
              <p *ngIf="!combination.permissionNotNeeded">No</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="table-responsive" *ngIf="renderingService.isMobile() || renderingService.isTablet()">
      <table class="table table-bordered">
        <tr>
          <th i18n="Country">Country</th>
          <td>{{ combination.thirdCountry.name }}</td>
        </tr>
        <tr>
          <th i18n="Type of permit">Type of permit</th>
          <td>{{ combination.typeOfPermit }}</td>
        </tr>
        <tr>
          <th i18n="Weight">Weight</th>
          <td>{{ combination.weight }}</td>
        </tr>
        <tr>
          <th i18n="Permit quota">Permit quota</th>
          <td>{{ combination.permitQuota }}</td>
        </tr>
        <tr>
          <th i18n="Cabotage">Cabotage</th>
          <td>{{ combination.cabotage }}</td>
        </tr>
      </table>
    </div>
  </ng-container>
  <ng-container *ngIf="!combination">
    <ng-container i18n="No combination found">No combination found</ng-container>
  </ng-container>
</div>
