export enum ArrivalStatus {
  Arrived = 0,
  UnloadingStarted = 1,
  UnloadingDone = 2,
  Departed = 3,
  AtRamp = 4,
  OnHold = 5,
  OnHoldEnd = 6,
  ReturnedToStart = 7,
  EarlyDeparture = 8,
}
