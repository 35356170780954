import { RestService } from "../services/rest.service";
import { Component, OnInit } from "@angular/core";
import {
  NgbDateStruct,
  NgbModal,
  NgbCalendar,
} from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { CountriesService } from "../countries.service";
import { DateConverter } from "../DateConverter";
import { MessageTranslationService } from "../message-translation.service";
import { ForbiddenTransportCompany } from "../models/ForbiddenTransportCompany";
import { RenderingService } from "../rendering.service";
import { Router } from "@angular/router";
import { Country } from "../models/Country";

interface NewCompany {
  country: Country;
  transportCompanyCountryInput: string;
  forbiddenAt: NgbDateStruct;
  name: string;
  reason: string;
}

@Component({
  selector: "app-forbidden-companies-list",
  templateUrl: "./forbidden-companies-list.component.html",
  styleUrls: ["./forbidden-companies-list.component.css"],
})
export class ForbiddenCompaniesListComponent implements OnInit {
  toLocalTime = DateConverter.toLocalTime;

  companies: ForbiddenTransportCompany[] = null;
  filteredCompanies: ForbiddenTransportCompany[] = null;

  filterText = "";

  pageNumber = 1;
  pageSize = 10;

  newCompany: NewCompany;

  constructor(
    public renderingService: RenderingService,
    private http: RestService,
    private toast: ToastrService,
    private msgT: MessageTranslationService,
    private modalService: NgbModal,
    public countriesService: CountriesService,
    private calendar: NgbCalendar,
    private router: Router
  ) {}

  ngOnInit() {
    this.resetNewCompany();

    this.http
      .get(`api/yardManagement/listForbiddenTransportCompanies`)
      .subscribe(
        (companies: ForbiddenTransportCompany[]) => {
          this.companies = companies;
          for (let i = 0; i < this.companies.length; i++) {
            this.companies[i].filterString = this.filterString(
              this.companies[i]
            );
            this.companies[i].transportCompanyCountryInput =
              this.companies[i].country.name;
            this.setOriginalData(this.companies[i]);
          }
          this.filteredCompanies = this.companies;
          this.filterTextChange(this.filterText);
        },
        (error) => {
          console.log("Error fetching companies");
          console.log(error.error);
          this.toast.error(this.msgT.fetchCompaniesError());
        }
      );
  }

  public filterString(company: ForbiddenTransportCompany) {
    return (company.name + " " + company.country).toLowerCase();
  }

  public filterTextChange(txt) {
    const txtProcessed = txt.trim().toLowerCase();
    this.filterText = txtProcessed;
    if (txtProcessed.length === 0) {
      this.filteredCompanies = this.companies;
      return;
    }

    this.filteredCompanies = this.companies.filter((d) =>
      d.filterString.includes(this.filterText)
    );
  }

  public isModified(company: ForbiddenTransportCompany) {
    return (
      company.originalData.name !== company.name ||
      company.originalData.reason !== company.reason ||
      company.originalData.country.id !== company.country.id
    );
  }

  public OnUpdateCompany(company: ForbiddenTransportCompany) {
    company.isModified = this.isModified(company);
    company.filterString = this.filterString(company);
    this.filterTextChange(this.filterText);
  }

  private setOriginalData(company: ForbiddenTransportCompany) {
    company.originalData = {
      name: company.name,
      reason: company.reason,
      country: company.country,
    };
  }

  public truncateReason(reason: string) {
    let line = reason;
    if (reason.indexOf("\n") >= 0) {
      line = reason.substr(0, reason.indexOf("\n"));
    } else if (line.length <= 20) {
      return line;
    }

    if (line.length > 20) {
      line = line.slice(0, 20);
    }

    return line + "...";
  }

  ApplyChanges(company: ForbiddenTransportCompany) {
    this.http
      .post(
        `api/yardManagement/updateForbiddenTransportCompany/${company.id}`,
        {
          name: company.name,
          reason: company.reason,
          countryId: company.country.id,
        }
      )
      .subscribe(
        (updatedCompany: ForbiddenTransportCompany) => {
          if (updatedCompany) {
            company.name = updatedCompany.name;
            company.reason = updatedCompany.reason;

            company.filterString = this.filterString(company);
            this.setOriginalData(company);
            this.filterTextChange(this.filterText);

            this.toast.success(this.msgT.updateCompanySuccess());
            company.isModified = false;
          }
        },
        (error) => {
          console.log("Update company error");
          console.log(error);
          this.toast.error(this.msgT.updateCompanyError());
        }
      );
  }

  private undoChanges(company: ForbiddenTransportCompany) {
    company.name = company.originalData.name;
    company.reason = company.originalData.reason;
    company.country = company.originalData.country;
  }

  UndoChanges(company: ForbiddenTransportCompany) {
    if (company.isModified) {
      this.undoChanges(company);
      company.isModified = false;
      company.filterString = this.filterString(company);
      this.filterTextChange(this.filterText);
    }
  }

  public onRefreshClick() {
    this.ngOnInit();
  }

  public RemoveCompany(company: ForbiddenTransportCompany) {
    this.http
      .post(
        `api/yardManagement/deleteForbiddenTransportCompany/${company.id}`,
        {}
      )
      .subscribe(
        (result: boolean) => {
          if (result) {
            this.companies = this.companies.filter((d) => d.id !== company.id);
            this.filterTextChange(this.filterText);
            this.toast.success(this.msgT.deleteCompanySuccess());
          }
        },
        (error) => {
          console.log("Delete company error");
          console.log(error);
          this.toast.error(this.msgT.deleteCompanyError());
        }
      );
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result: boolean | null) => {},
        (_) => {}
      );
  }

  public AddCompany() {
    if (!this.newCompanyValid()) {
      return;
    }

    this.http
      .post(`api/yardManagement/createForbiddenTransportCompany`, {
        countryId: this.newCompany.country.id,
        name: this.newCompany.name,
        reason: this.newCompany.reason,
        forbiddenAt:
          this.newCompany.forbiddenAt.day +
          ". " +
          this.newCompany.forbiddenAt.month +
          ". " +
          this.newCompany.forbiddenAt.year,
      })
      .subscribe(
        (addedCompany: ForbiddenTransportCompany) => {
          if (addedCompany) {
            this.companies.unshift(addedCompany);

            addedCompany.filterString = this.filterString(addedCompany);
            this.setOriginalData(addedCompany);
            this.filterTextChange(this.filterText);

            this.resetNewCompany();
            this.modalService.dismissAll();
            this.toast.success(this.msgT.addCompanySuccess());
          } else {
            this.toast.error(this.msgT.addCompanyError());
          }
        },
        (error) => {
          console.log("Add company error");
          console.log(error);
          this.toast.error(this.msgT.addCompanyError());
        }
      );
  }

  public resetNewCompany() {
    this.newCompany = {
      transportCompanyCountryInput: "",
      country: null,
      forbiddenAt: this.calendar.getToday(),
      name: "",
      reason: "",
    };
  }

  public newCompanyValid(): boolean {
    return (
      this.newCompany.name != null &&
      this.newCompany.name.length > 0 &&
      this.newCompany.country != null &&
      this.newCompany.reason != null &&
      this.newCompany.reason.length > 0 &&
      this.newCompany.forbiddenAt != null
    );
  }

  public SeeDetails(company: ForbiddenTransportCompany) {
    this.router.navigate([`forbidden-transport-company`, company.id]);
  }

  public onSelectTransportCompanyCountry(
    company: ForbiddenTransportCompany,
    country: Country | null
  ) {
    if (country) {
      company.transportCompanyCountryInput = country.name;
      company.country = country;
    }
  }

  public onSelectTransportCompanyCountrySingle(country: Country | null) {
    if (country) {
      this.newCompany.transportCompanyCountryInput = country.name;
    }

    this.newCompany.country = country;
  }

  public OnEditableModeChange(company: ForbiddenTransportCompany = null) {
    if (company) {
      company.transportCompanyCountryInput = company.country.name;
    }
  }
}
