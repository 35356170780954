<div class="w-100">
  <div class="d-flex justify-content-end mb-1">
    <div class="btn btn-secondary" (click)="clear()">
      <ng-container i18n>Clear</ng-container>
    </div>
  </div>

  <div class="d-flex align-items-center justify-content-center">
    <div class="spinner-border" *ngIf="!isLoaded">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div class="w-100" style="height: 58vh" #container>
    <signature-pad
      [ngClass]="{ 'd-none': !isLoaded }"
      style="background-color: white"
      [options]="signaturePadOptions"
      (drawEnd)="drawComplete()"
    ></signature-pad>
  </div>
</div>
