import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-stats-dashboard",
  templateUrl: "./stats-dashboard.component.html",
  styleUrls: ["./stats-dashboard.component.css"],
})
export class StatsDashboardComponent implements OnInit {
  active = 1;

  constructor() {}

  ngOnInit(): void {}
}
