import { RestService } from "../services/rest.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { MessageTranslationService } from "../message-translation.service";
import { HttpClient } from "@angular/common/http";

enum SignStep {
  SIGNATURE_PENDING,
  REVIEW_SIGNATURE,
  DONE,
}

@Component({
  selector: "app-preview-contract",
  templateUrl: "./preview-contract.component.html",
  styleUrls: ["./preview-contract.component.css"],
})
export class PreviewContractComponent implements OnInit {
  arrivalId: number;
  contractType: number;
  languageId: number;

  SignStep = SignStep;
  signStep: SignStep = SignStep.SIGNATURE_PENDING;

  pdfFileString = null;

  fileName: string;

  signatureFile: File | null = null;
  signingFileInProgress = false;

  confirmSignatureInProgress = false;

  loading = true;

  constructor(
    private http: RestService,
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    private toast: ToastrService,
    private modalService: NgbModal,
    private msgT: MessageTranslationService
  ) {}

  onDrawFinish(a: any) {
    console.log(a);
    this.signatureFile = a;
  }

  async ngOnInit(): Promise<void> {
    this.route.params.subscribe(async (params) => {
      this.arrivalId = params["arrivalId"];
      this.contractType = params["contractType"];
      this.languageId = params["languageId"];

      await this.autofillContract();
    });
  }

  openSignModal(content: any) {
    this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      size: "xl",
      windowClass: "extra-large-modal",
    });
  }

  areParamsValid() {
    return !(this.arrivalId == null || this.contractType == null || this.languageId == null);
  }

  async autofillContract() {
    if (!this.areParamsValid()) {
      return;
    }

    this.loading = true;

    try {
      const blob = await this.httpClient
        .get(
          `${window.location.origin}/api/driverContract/fillDriverContractTemplateForSigning?arrivalId=${this.arrivalId}&languageId=${this.languageId}&contractType=${this.contractType}`,
          { responseType: "blob", headers: this.http.getHeaders() }
        )
        .toPromise();
      this.pdfFileString = URL.createObjectURL(blob);
    } catch (e) {
      this.toast.error(this.msgT.couldNotGenerateFile());
      console.log(e);
    }

    this.loading = false;
    this.signStep = SignStep.SIGNATURE_PENDING;
  }

  async signContract() {
    if (!this.areParamsValid()) {
      return;
    }

    if (this.signatureFile == null) {
      return;
    }

    this.signingFileInProgress = true;

    try {
      const formData = new FormData();
      formData.append("signatureImage", this.signatureFile, this.signatureFile.name);

      const response = await this.httpClient
        .post(
          `${window.location.origin}/api/driverContract/signDriverContractTemplate?arrivalId=${this.arrivalId}&languageId=${this.languageId}&contractType=${this.contractType}`,
          formData,
          {
            responseType: "blob",
            observe: "response",
            headers: this.http.getFormDataHeaders(),
          }
        )
        .toPromise();
      this.modalService.dismissAll();

      this.fileName = response.headers.get("Content-Disposition").split("filename=")[1].split(";")[0];
      this.pdfFileString = URL.createObjectURL(response.body);
      this.signStep = SignStep.REVIEW_SIGNATURE;

      console.log(this.fileName);
      this.toast.info(this.msgT.pleaseReviewContract());
    } catch (e) {
      this.toast.error(this.msgT.couldNotGenerateContract());
      console.log(e);
    }

    this.signingFileInProgress = false;
  }

  async confirmSignature() {
    if (!this.areParamsValid()) {
      return;
    }

    this.confirmSignatureInProgress = true;

    try {
      await this.http
        .post(`${window.location.origin}/api/driverContract/confirmContract`, {
          fileName: this.fileName,
          arrivalId: this.arrivalId,
          type: this.contractType,
        })
        .toPromise();

      this.signStep = SignStep.DONE;
    } catch (e) {
      this.toast.error(this.msgT.couldNotConfirmContract());
      console.log(e);
    }

    this.confirmSignatureInProgress = false;
  }
}
