import { Component, OnInit } from "@angular/core";
import { CountryCombination } from "../models/Country";
import { ActivatedRoute } from "@angular/router";
import { RestService } from "../services/rest.service";
import { ToastrService } from "ngx-toastr";
import { MessageTranslationService } from "../message-translation.service";
import { RenderingService } from "../rendering.service";

@Component({
  selector: "app-country-combination",
  templateUrl: "./country-combination.component.html",
  styleUrls: ["./country-combination.component.css"],
})
export class CountryCombinationComponent implements OnInit {
  combination: CountryCombination;

  constructor(
    private route: ActivatedRoute,
    private http: RestService,
    private toast: ToastrService,
    private msgT: MessageTranslationService,
    public renderingService: RenderingService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const id = params["id"];
      if (id) {
        this.http.get(`api/country/getCombination/${id}`).subscribe(
          (combination: CountryCombination) => {
            this.combination = combination;
          },
          (error) => {
            console.log("Error fetching combination");
            console.log(error);
            this.toast.error(this.msgT.fetchCombinationsError());
          }
        );
      }
    });
  }
}
