import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { CountriesService } from "../countries.service";
import { MessageTranslationService } from "../message-translation.service";
import { DriverContractLanguage, DriverContractTemplate, DriverContractType } from "../models/Contract";
import { Country } from "../models/Country";
import { RestService } from "../services/rest.service";
import { SupportService } from "../support.service";

@Component({
  selector: "app-contract-management",
  templateUrl: "./contract-management.component.html",
  styleUrls: ["./contract-management.component.css"],
})
export class ContractManagementComponent implements OnInit {
  languages: DriverContractLanguage[] = [];
  newLanguageName: string = "";
  newLanguageCountries: Country[] = [];
  addingLanguageInProgress = false;

  editingLanguage: DriverContractLanguage | null = null;
  editingLanguageInProgress = false;

  deletingLanguage: DriverContractLanguage | null = null;
  deletingLanguageInProgress = false;

  //

  DriverContractType = DriverContractType;

  contractTemplates: DriverContractTemplate[] = [];

  newTemplate: {
    name: string;
    type: DriverContractType;
    languageId: number;
    contractFile: File;
    alreadyExistsError: boolean;
    overrideExistingContractTemplate: boolean;
  };

  addingOrEditingTemplateInProgress = false;

  editingTemplate: DriverContractTemplate | null = null;

  deletingTemplate: DriverContractTemplate | null = null;
  deletingTemplateInProgress = false;

  constructor(
    private http: RestService,
    private toast: ToastrService,
    private support: SupportService,
    private modalService: NgbModal,
    private countriesService: CountriesService,
    private msgT: MessageTranslationService
  ) {}

  async ngOnInit() {
    await this.fetchLanguages();
    this.resetNewTemplateInput();
    await this.fetchTemplates();
  }

  async fetchLanguages() {
    try {
      this.languages = await this.http.get<DriverContractLanguage[]>(`api/driverContract/getContractLanguages`).toPromise();
      this.assignCountriesToLanguages();
    } catch (e) {
      console.log(e);
    }
  }

  assignCountriesToLanguages() {
    for (let i = 0; i < this.languages.length; i++) {
      this.assignCountriesToLanguage(this.languages[i]);
    }
  }

  assignCountriesToLanguage(language: DriverContractLanguage) {
    if (language.countryIDs == null) {
      language.countries = [];
    } else {
      language.countries = language.countryIDs.map((countryID) => this.countriesService.getCountryById(countryID)).filter((c) => c != null);
    }
  }

  openAddLanguageModal(content: any) {
    this.newLanguageName = "";
    this.newLanguageCountries = [];
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" });
  }

  openEditLanguageModal(content: any, language: DriverContractLanguage) {
    this.editingLanguage = language;
    this.newLanguageName = this.editingLanguage.name;
    this.newLanguageCountries = this.editingLanguage.countries;
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" });
  }

  openDeleteLanguageModal(content: any, language: DriverContractLanguage) {
    this.deletingLanguage = language;
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" });
  }

  async addLanguage() {
    if (this.newLanguageName.trim().length === 0) {
      return;
    }

    this.addingLanguageInProgress = true;

    try {
      const newLanguage = await this.http
        .post<DriverContractLanguage>(`api/driverContract/addContractLanguage`, {
          name: this.newLanguageName,
          countryIDs: this.newLanguageCountries.map((c) => c.id),
        })
        .toPromise();

      this.assignCountriesToLanguage(newLanguage);
      this.languages.unshift(newLanguage);
      if (this.languages.length === 1) {
        this.resetNewTemplateInput();
      }

      this.toast.success(this.msgT.languageAdded());

      this.newLanguageName = "";
      this.newLanguageCountries = [];
      this.modalService.dismissAll();
    } catch (e) {
      console.log(e);
      this.toast.error(this.msgT.languageAddedError());
    }

    this.addingLanguageInProgress = false;
  }

  async editLanguage() {
    if (this.newLanguageName.trim().length === 0 || !this.editingLanguage) {
      return;
    }

    this.editingLanguageInProgress = true;

    try {
      const editedLanguage = await this.http
        .post<DriverContractLanguage>(`api/driverContract/updateContractLanguage/${this.editingLanguage.id}`, {
          name: this.newLanguageName,
          countryIDs: this.newLanguageCountries.map((c) => c.id),
        })
        .toPromise();
      this.assignCountriesToLanguage(editedLanguage);

      let index = this.languages.findIndex((l) => l.id === editedLanguage.id);
      if (index >= 0) {
        this.languages[index] = editedLanguage;
      }

      this.toast.success(this.msgT.languageEdited());

      this.editingLanguage = null;
      this.newLanguageName = "";
      this.newLanguageCountries = [];
      this.modalService.dismissAll();
    } catch (e) {
      console.log(e);
      this.toast.error(this.msgT.languageEditedError());
    }

    this.editingLanguageInProgress = false;
  }

  async deleteLanguage() {
    if (!this.deletingLanguage) {
      return;
    }

    this.deletingLanguageInProgress = true;

    try {
      await this.http.post(`api/driverContract/deleteContractLanguage/${this.deletingLanguage.id}`, {}).toPromise();

      let index = this.languages.findIndex((l) => l.id === this.deletingLanguage.id);
      if (index >= 0) {
        this.languages.splice(index, 1);
      }

      this.toast.success(this.msgT.languageDeleted());

      this.deletingLanguage = null;
      this.modalService.dismissAll();
    } catch (e) {
      console.log(e);
      this.toast.error(this.msgT.languageDeletedError());
    }

    this.deletingLanguageInProgress = false;
  }

  //

  resetNewTemplateInput() {
    let languageId = 0;
    if (this.languages.length === 0) {
      languageId = 0;
    }

    this.newTemplate = {
      contractFile: null,
      languageId,
      name: "",
      type: DriverContractType.ShortTerm,
      alreadyExistsError: false,
      overrideExistingContractTemplate: false,
    };
  }

  populateNewTemplateInput(template: DriverContractTemplate) {
    this.newTemplate = {
      contractFile: null,
      languageId: template.contractLanguage.id,
      name: template.name,
      type: template.contractType,
      alreadyExistsError: false,
      overrideExistingContractTemplate: false,
    };
  }

  newTemplateInputValid() {
    return (
      (this.editingTemplate != null || this.newTemplate.contractFile != null) && this.newTemplate.languageId > 0 && this.newTemplate.name.trim().length > 0
    );
  }

  async fetchTemplates() {
    try {
      this.contractTemplates = await this.http.get<DriverContractTemplate[]>(`api/driverContract/getContractTemplates`).toPromise();
    } catch (e) {
      console.log(e);
    }
  }

  openAddTemplateModal(content: any) {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" });
  }

  openEditTemplateModal(content: any, template: DriverContractTemplate) {
    this.editingTemplate = template;
    this.populateNewTemplateInput(template);

    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" }).result.then(
      () => {
        this.editingTemplate = null;
        this.resetNewTemplateInput();
      },
      () => {
        this.editingTemplate = null;
        this.resetNewTemplateInput();
      }
    );
  }

  openDeleteTemplateModal(content: any, template: DriverContractTemplate) {
    this.deletingTemplate = template;
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" });
  }

  async addOrEditTemplate() {
    if (!this.newTemplateInputValid()) {
      return;
    }

    this.addingOrEditingTemplateInProgress = true;

    const isEditing = !!this.editingTemplate;

    try {
      const formData: FormData = new FormData();
      if (this.newTemplate.contractFile) {
        formData.append("contractTemplateFile", this.newTemplate.contractFile, this.newTemplate.contractFile.name);
      }

      formData.append("name", this.newTemplate.name);
      formData.append("type", this.newTemplate.type.toString());
      formData.append("languageId", this.newTemplate.languageId.toString());

      if (this.newTemplate.alreadyExistsError && this.newTemplate.overrideExistingContractTemplate) {
        formData.append("overrideExistingContractTemplate", "true");
      }

      await this.http
        .postFormData(`api/driverContract/${this.editingTemplate ? `updateContractTemplate/${this.editingTemplate.id}` : "addContractTemplate"}`, formData)
        .toPromise();

      if (this.editingTemplate) {
        this.toast.success(this.msgT.templateEdited());
      } else {
        this.toast.success(this.msgT.templateAdded());
      }

      this.modalService.dismissAll();
      this.resetNewTemplateInput();
      this.editingTemplate = null;
      await this.fetchTemplates();
    } catch (e) {
      console.log(e);
      if (e.error?.includes("Contract template already exists")) {
        if (this.editingTemplate) {
          this.toast.error(this.msgT.templateAddedErrorExistsAlready());
          this.newTemplate.alreadyExistsError = false;
        } else {
          this.newTemplate.alreadyExistsError = true;
        }
      } else if (e.error?.includes("Incorrect file type!")) {
        this.toast.error(this.msgT.templateAddedErrorWord());
        this.newTemplate.alreadyExistsError = false;
      } else {
        this.toast.error(this.msgT.templateAddedError());
        this.newTemplate.alreadyExistsError = false;
      }
    }

    this.addingOrEditingTemplateInProgress = false;
  }

  async deleteTemplate() {
    if (!this.deletingTemplate) {
      return;
    }

    this.deletingTemplateInProgress = true;

    try {
      await this.http.post(`api/driverContract/deleteContractTemplate/${this.deletingTemplate.id}`, {}).toPromise();

      let index = this.contractTemplates.findIndex((l) => l.id === this.deletingTemplate.id);
      if (index >= 0) {
        this.contractTemplates.splice(index, 1);
      }

      this.toast.success(this.msgT.templateDeleted());

      this.deletingTemplate = null;
      this.modalService.dismissAll();
    } catch (e) {
      console.log(e);
      this.toast.error(this.msgT.templateDeletedError());
    }

    this.deletingTemplateInProgress = false;
  }

  openContractTemplateFile(template: DriverContractTemplate) {
    this.support.downloadFile(`api/driverContract/getContractTemplateFile/${template.id}`);
  }
}
