<div class="container app-container">
  <h1 class="app-heading">
    <ng-container i18n="Add a new arrival">Add a new arrival</ng-container>
  </h1>

  <div class="form-group">
    <label for="type"><ng-container i18n="Warehouse">Warehouse</ng-container></label>
    <select [(ngModel)]="warehouseId" id="type" name="type" class="form-control">
      <option [value]="w.id" *ngFor="let w of warehouses">
        <ng-container>{{ w.name }}</ng-container>
      </option>
    </select>
    <small *ngIf="errors.warehouseId" class="form-text text-danger">
      <ng-container i18n>Select warehouse</ng-container>
    </small>
  </div>

  <div class="form-group">
    <label for="arrivalDate"><ng-container i18n="Name">Date of arrival</ng-container></label>
    <input type="text" class="form-control" id="arrivalDate" [(ngModel)]="arrival.arrivalDate" />

    <small *ngIf="errors.dateOfArrival" class="form-text text-danger">
      <ng-container i18n="Enter valid date of arrival">Enter valid date of arrival</ng-container>
    </small>
  </div>

  <div class="form-group">
    <label for="arrivalTime"><ng-container *ngIf="auth.IsWarehouseAdmin()" i18n="Name">Time of arrival</ng-container></label>
    <input *ngIf="auth.IsWarehouseAdmin()" type="text" class="form-control" id="arrivalTime" [(ngModel)]="arrival.arrivalTime" />

    <small *ngIf="errors.timeOfArrival" class="form-text text-danger">
      <ng-container i18n="Enter valid time of arrival">Enter valid time of arrival</ng-container>
    </small>
  </div>

  <br *ngIf="auth.IsWarehouseAdmin()" />

  <div class="form-group">
    <label for="name"><ng-container i18n>Reservation ID (if existing)</ng-container></label>
    <div class="d-flex align-items-center">
      <app-clearable-input
        (onEnter)="searchByReservationCode()"
        (onClear)="reservationData.code = ''"
        [(inputModel)]="reservationData.code"
        style="width: 100%"
      ></app-clearable-input>
      <button
        class="btn btn-primary btn-wrap-text ml-2"
        [disabled]="reservationCodeSearchLoading || reservationData.code.trim().length === 0"
        (click)="searchByReservationCode()"
        i18n
      >
        Fill data
      </button>
    </div>
  </div>

  <div class="form-group">
    <label for="name"><ng-container i18n="Driver ID">Driver ID (if existing)</ng-container></label>
    <div class="d-flex align-items-center">
      <app-clearable-input
        (onEnter)="clearDataAndsearchID()"
        (onClear)="driver.driverCode = ''"
        [(inputModel)]="driver.driverCode"
        style="width: 100%"
      ></app-clearable-input>
      <button class="btn btn-primary btn-wrap-text ml-2" (click)="clearDataAndsearchID()" i18n="Fill data">Fill data</button>
      <div *ngIf="driverCodes.length > 0" class="d-flex align-items-center">
        <select id="selectDriver" name="selectDriver" class="form-control ml-2 mr-2" (change)="onDropBoxSelect($event.target.value)">
          <option disabled selected value>
            <ng-container i18n>Select driver ID...</ng-container>
          </option>
          <option *ngFor="let codes of driverCodes" value="{{ codes }}">
            {{ codes }}
          </option>
        </select>
        <fa-icon (click)="driverCodes = []" style="color: red; cursor: pointer" [icon]="faTimesCircle"></fa-icon>
      </div>
    </div>
    <div style="color: orange" class="d-flex justify-content-end mt-3" *ngIf="driverNotDisplayedWarning">
      <fa-icon [icon]="faExclamationTriangle"></fa-icon>
      <span style="margin-left: 5px" i18n>Some drivers are not displayed due to lack of ID</span>
    </div>
  </div>

  <br />

  <div class="form-group">
    <label for="tCompany" class="d-flex justify-content-between">
      <ng-container i18n="Transport company">Transport company</ng-container>

      <div class="text-danger ml-1" *ngIf="forbiddenCompanyId">
        <fa-icon [icon]="faExclamationTriangle"></fa-icon>
        <span class="text-danger">
          (<a i18n="possible forbidden company" target="_blank" class="text-danger" [routerLink]="['/forbidden-transport-company/' + forbiddenCompanyId]"
            ><u>possible forbidden company</u></a
          >)
        </span>
      </div>
    </label>

    <div class="autocomplete-container">
      <app-autocomplete-input
        [(value)]="driver.transportCompany"
        (onSelect)="onSelectTransportCompany($event)"
        (onChange)="onChangeTransportCompany()"
        (onClear)="onChangeTransportCompany(true)"
        [data]="transportCompanies"
      ></app-autocomplete-input>

      <small *ngIf="errors.transportCompany" class="form-text text-danger">
        <ng-container i18n="Enter transport company">Enter transport company</ng-container>
      </small>
    </div>
  </div>

  <div class="form-group">
    <label for="tName" class="d-flex justify-content-between">
      <ng-container i18n="Driver name and surname">Driver name and surname</ng-container>
    </label>

    <div class="autocomplete-container">
      <form (submit)="GetDriver(true, false, false)">
        <app-autocomplete-input
          [(value)]="driver.driverName"
          (keyup.enter)="GetDriver(true, false, false)"
          (onSelect)="onSelectName2($event)"
          (onChange)="onChangeName()"
          (onClear)="onChangeName(true)"
          [data]="driverNames"
        ></app-autocomplete-input>
      </form>

      <small *ngIf="errors.driverName" class="form-text text-danger">
        <ng-container i18n="Enter driver name and surname">Enter driver name and surname</ng-container>
      </small>
    </div>

    <div class="text-danger ml-1 text-end" *ngFor="let forbiddenDriver of forbiddenDrivers">
      <fa-icon [icon]="faExclamationTriangle"></fa-icon>
      <span class="text-danger">
        (<a i18n="possible forbidden driver" target="_blank" class="text-danger" [routerLink]="['/forbidden-driver/' + forbiddenDriver.id]"
          ><u>possible forbidden driver</u>: {{ forbiddenDriver.name }}
          <span *ngIf="forbiddenDriver.transportCompany != null && forbiddenDriver.transportCompany.length > 0"
            >({{ forbiddenDriver.transportCompany }})</span
          ></a
        >)
      </span>
    </div>
  </div>

  <div class="form-group">
    <label for="tCompany"><ng-container i18n="Transport company country">Transport company country</ng-container></label>
    <div class="autocomplete-container">
      <app-autocomplete-input
        [searchKeyword]="'name'"
        [(value)]="transportCompanyCountryInput"
        (valueChange)="onSelectTransportCompanyCountry($event)"
        (onClear)="onSelectTransportCompanyCountry(null)"
        [data]="countriesService.countries"
        [editable]="false"
      ></app-autocomplete-input>

      <small *ngIf="errors.transportCompanyCountry" class="form-text text-danger">
        <ng-container i18n="Select transport company country">Select transport company country</ng-container>
      </small>
    </div>
  </div>

  <div class="form-group">
    <label for="tCompany"><ng-container i18n="Registration number">Registration number</ng-container></label>
    <div class="autocomplete-container">
      <app-autocomplete-input [(value)]="registrationNumber" [data]="registrationNumbers"></app-autocomplete-input>

      <small *ngIf="errors.regNumber" class="form-text text-danger">
        <ng-container i18n="Enter registration number">Enter registration number</ng-container>
      </small>
    </div>
  </div>

  <div class="form-group">
    <label for="tCompany"><ng-container i18n="Loading country">Loading country</ng-container></label>
    <div class="autocomplete-container" style="margin-bottom: 30px">
      <app-autocomplete-input
        [searchKeyword]="'name'"
        [(value)]="loadingCountryInput"
        (valueChange)="onSelectLoadingCountry($event)"
        (onClear)="onSelectLoadingCountry(null)"
        [data]="countriesService.countries"
        [editable]="false"
      ></app-autocomplete-input>

      <small *ngIf="errors.loadingCountry" class="form-text text-danger">
        <ng-container i18n="Select loading country">Select loading country</ng-container>
      </small>
    </div>
  </div>

  <div class="form-group">
    <label for="name"><ng-container i18n>Driver email</ng-container></label>
    <app-clearable-input (onClear)="driver.email = ''" [(inputModel)]="driver.email" style="width: 100%"></app-clearable-input>
  </div>

  <button class="btn btn-primary" (click)="AddArrival()" [disabled]="isAddArrivalLoading" i18n="Add an arrival">Add an arrival</button>
</div>
