<div class="container-fluid app-container arrival-table-container">
  <h1 class="app-heading">
    <ng-container i18n>Sign contract</ng-container>
  </h1>

  <ng-container *ngIf="languages != null">
    <ng-container i18n *ngIf="languages.length === 0">
      Please contact your admin to add languages and contract templates first.
    </ng-container>

    <ng-container *ngIf="languages.length > 0">
      <div class="d-flex align-items-center">
        <h4 i18n class="m-0">Select data</h4>
      </div>

      <hr />

      <div class="form-row justify-content-start flex-column">
        <div class="form-group mb-2 col">
          <label for="name"
            ><ng-container i18n="Language">Language</ng-container></label
          >
          <select
            [(ngModel)]="contract.languageId"
            id="type"
            name="type"
            class="form-control"
          >
            <option *ngFor="let language of languages" [value]="language.id">
              <ng-container>{{ language.name }}</ng-container>
            </option>
          </select>
        </div>

        <div class="form-group mb-2 col">
          <label for="name"
            ><ng-container i18n="Type">Type</ng-container></label
          >
          <select
            [(ngModel)]="contract.type"
            id="type"
            name="type"
            class="form-control"
          >
            <option [value]="DriverContractType.ShortTerm">
              <ng-container i18n>Short term</ng-container>
            </option>
            <option [value]="DriverContractType.LongTerm">
              <ng-container i18n>Long term</ng-container>
            </option>
          </select>
        </div>

        <div class="col mt-3">
          <button
            class="btn btn-info"
            [disabled]="gettingContractInProgress"
            (click)="getContract()"
            i18n
          >
            Get contract
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
