import { Component, OnInit } from "@angular/core";
import { DateConverter } from "../DateConverter";
import { contractExpiryText, contractExpiryColor } from "../utils/ArrivalUtils";
import { Driver } from "../models/Driver";
import { RestService } from "../services/rest.service";
import * as moment from "moment";
import { RenderingService } from "../rendering.service";
import { ToastrService } from "ngx-toastr";
import { MessageTranslationService } from "../message-translation.service";
import { CountriesService } from "../countries.service";
import { Country } from "../models/Country";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { DriverContract } from "../models/DriverContract";
import { Router } from "@angular/router";
import { AuthService } from "../core/auth.service";
import { SupportService } from "../support.service";

class ContractSnippet {
  constructor(public src: string, public file: File, public driverId: number) {}
}

class DriverListFinishedResponse {
  drivers: Driver[];
  totalPages: number;
}

@Component({
  selector: "app-driver-list",
  templateUrl: "./driver-list.component.html",
  styleUrls: ["./driver-list.component.css"],
})
export class DriverListComponent implements OnInit {
  toLocalTime = DateConverter.toLocalTime;
  contractExpiryText = contractExpiryText;

  faExclamationTriangle = faExclamationTriangle;

  selectedContracts: ContractSnippet[] = [];

  loading = true;
  filteredDrivers: Driver[] = null;

  filterText = "";

  driversPageNumber = 1;
  driversPageCount = 0;
  previousPage = 0;
  driversPageSize = 10;

  driversPreviousPage = 1;

  showOnlyDriversWithLongTermContractSigned = false;

  constructor(
    public renderingService: RenderingService,
    private http: RestService,
    private toast: ToastrService,
    private msgT: MessageTranslationService,
    public countriesService: CountriesService,
    private router: Router,
    private auth: AuthService,
    private support: SupportService
  ) {}

  ngOnInit() {
    this.fetchDrivers();
  }

  public fetchDrivers() {
    this.loading = true;

    this.http
      .get(
        `api/driver/list?pageNumber=${this.driversPageNumber}&pageSize=${this.driversPageSize}&searchString=${this.filterText}&onlyLongTermContract=${this.showOnlyDriversWithLongTermContractSigned}`
      )
      .subscribe(
        (arr: DriverListFinishedResponse) => {
          this.driversPageCount = arr.totalPages;
          this.filteredDrivers = arr.drivers;
          for (let i = 0; i < this.filteredDrivers.length; i++) {
            this.setOriginalData(this.filteredDrivers[i]);
            this.filteredDrivers[i].driverListDateSigned = moment().format("DD. MM. YYYY");
          }

          this.loading = false;
        },
        (error) => {
          console.log("Error fetching drivers");
          console.log(error.error);
          this.toast.error(this.msgT.fetchDriversError());
          this.loading = false;
        }
      );
  }

  public filterString(driver: Driver) {
    return (driver.name + " " + driver.transportCompany + " " + driver.transportCompanyCountry.name + " " + (driver.code ? driver.code : "")).toLowerCase();
  }

  public processContract(imageInput: any, driver: Driver) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener("load", (event: any) => {
      const existingContractIndex = this.findPreparedContractIndexForDriver(driver.id);

      if (existingContractIndex >= 0) {
        this.selectedContracts.splice(existingContractIndex, 1);
      }

      const contractSnippet = new ContractSnippet(event.target.result, file, driver.id);
      this.selectedContracts.push(contractSnippet);
      driver.contractReadyForUpload = true;
      this.toast.info(this.msgT.readyForUpload(contractSnippet.file.name, driver.name));
    });

    reader.addEventListener("error", (err: any) => {
      console.log("Upload error");
      console.log(err);
      this.toast.error(this.msgT.uploadFileError());
    });

    reader.readAsDataURL(file);
  }

  private findPreparedContractIndexForDriver(driverId: number): number {
    return this.selectedContracts.findIndex((c) => c.driverId === driverId);
  }

  public onUploadContractClick(driver: Driver, isMultiple = false) {
    const selectedContractIndex = this.findPreparedContractIndexForDriver(driver.id);

    if (selectedContractIndex < 0) {
      this.toast.info(this.msgT.pleaseSelectContactFile());
      return;
    }

    // todo: validate better
    if (!moment(driver.driverListDateSigned, "DD. MM. YYYY", true).isValid()) {
      this.toast.error(this.msgT.invalidSignDate(driver.name));
      return;
    }

    let code = "";
    if (driver.code != null) {
      code = driver.code;
    } else {
      if (!driver.codeInput || driver.codeInput.trim().length === 0) {
        this.toast.error(this.msgT.invalidDriverCode());
        return;
      } else {
        code = driver.codeInput.trim();
      }
    }

    const contractForDriver = this.selectedContracts[selectedContractIndex];

    const formData: FormData = new FormData();
    formData.append("contract", contractForDriver.file, contractForDriver.file.name);
    formData.append("dateSigned", driver.driverListDateSigned);
    formData.append("driverCode", code);

    this.http.postFormData(`api/driver/uploadContract/${driver.id}`, formData).subscribe(
      () => {
        if (!isMultiple) {
          this.ngOnInit();
          this.selectedContracts.splice(selectedContractIndex, 1);
          this.toast.success(this.msgT.uploadContractSuccess(driver.name));
        }
      },
      (error) => {
        if (error.error && error.error.includes("Driver with this code already exists")) {
          this.toast.error(this.msgT.driverWithIDAlreadyExists(driver.codeInput));
        } else {
          console.log("Upload image error");
          console.log(error.error);
          this.toast.error(this.msgT.uploadContractError(driver.name));
        }
      }
    );
  }

  public onSearchParamsChange() {
    this.fetchDrivers();
  }

  public onViewContractClick(driver: Driver) {
    window.open(`api/driver/getContract/${driver.id}`, "_blank");
  }

  public isModified(driver: Driver) {
    return (
      driver.originalData.name !== driver.name ||
      driver.originalData.email !== driver.email ||
      driver.originalData.transportCompany !== driver.transportCompany ||
      driver.originalData.transportCompanyCountry.id !== driver.transportCompanyCountry.id ||
      driver.originalData.code !== driver.code
    );
  }

  public OnUpdateDriver(driver: Driver) {
    driver.isModified = this.isModified(driver);
    driver.filterString = this.filterString(driver);
  }

  private setOriginalData(driver: Driver) {
    driver.originalData = {
      name: driver.name,
      email: driver.email,
      transportCompany: driver.transportCompany,
      transportCompanyCountry: driver.transportCompanyCountry,
      code: driver.code,
      id: driver.id,
    };
  }

  ApplyChanges(driver: Driver) {
    this.http
      .post(`api/driver/updateDriver/${driver.originalData.id}`, {
        name: driver.name,
        email: driver.email,
        company: driver.transportCompany,
        transportCompanyCountryId: driver.transportCompanyCountry.id,
        code: driver.code,
      })
      .subscribe(
        (updatedDriver: Driver) => {
          if (updatedDriver) {
            driver.name = updatedDriver.name;
            driver.email = updatedDriver.email;
            driver.transportCompany = updatedDriver.transportCompany;
            driver.code = updatedDriver.code;

            driver.filterString = this.filterString(driver);
            this.setOriginalData(driver);

            this.toast.success(this.msgT.updateDriverSuccess());
            driver.isModified = false;
          }
        },
        (error) => {
          if (error.error && error.error.includes("Driver with this code already exists")) {
            this.toast.error(this.msgT.driverWithIDAlreadyExists(driver.codeInput));
          } else {
            console.log("Update driver error");
            console.log(error);
            this.toast.error(this.msgT.updateDriverError());
          }
        }
      );
  }

  private undoChanges(driver: Driver) {
    driver.name = driver.originalData.name;
    driver.email = driver.originalData.email;
    driver.transportCompany = driver.originalData.transportCompany;
    driver.code = driver.originalData.code;
  }

  UndoChanges(driver: Driver) {
    if (driver.isModified) {
      this.undoChanges(driver);
      driver.isModified = false;
      driver.filterString = this.filterString(driver);
    }
  }

  public OnEditableModeChange(driver: Driver = null) {
    if (driver) {
      driver.transportCompanyCountryInput = driver.transportCompanyCountry.name;
      driver.codeInput = driver.code;
    }
  }

  public onSelectTransportCompanyCountry(driver: Driver, country: Country | null) {
    if (country) {
      driver.transportCompanyCountryInput = country.name;
      driver.transportCompanyCountry = country;
    }
  }

  public loadPage(page: number) {
    if (page !== this.driversPreviousPage) {
      this.driversPreviousPage = page;
      this.driversPageNumber = page;
      this.fetchDrivers();
    }
  }

  public isIdValid(driver: Driver): boolean {
    if (!driver.isModified) {
      return true;
    }

    const regexpDriverCode: RegExp = /\d+_\d{4}/;
    return regexpDriverCode.test(driver.code);
  }

  openContractFile(contract: DriverContract) {
    this.support.downloadFile(`api/driver/getContractFile/${contract.id}`);
  }

  viewDriverDetails(driver: Driver) {
    this.router.navigate([`driver`, driver.id]);
  }

  public contractExpiryColor(driver: Driver) {
    contractExpiryColor(driver, this.auth.loggedInUser.company);
  }
}
