import { RestService } from "../services/rest.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { MessageTranslationService } from "../message-translation.service";
import { Arrival } from "../models/Arrival";
import { DriverContractLanguage, DriverContractTemplate, DriverContractType } from "../models/Contract";

@Component({
  selector: "app-sign-contract",
  templateUrl: "./sign-contract.component.html",
  styleUrls: ["./sign-contract.component.css"],
})
export class SignContractComponent implements OnInit {
  languages: DriverContractLanguage[] | null = null;
  DriverContractType = DriverContractType;

  gettingContractInProgress = false;

  contract: {
    languageId: number;
    arrivalId: number;
    type: DriverContractType;
  } = {
    languageId: 0,
    arrivalId: null,
    type: DriverContractType.ShortTerm,
  };

  constructor(
    private http: RestService,
    private toast: ToastrService,
    private msgT: MessageTranslationService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    await this.fetchLanguages();
    if (this.languages?.length > 0) {
      this.resetContractInput();
    }

    this.route.params.subscribe(async (params) => {
      if (params.arrivalId) {
        this.contract.arrivalId = params["arrivalId"];
        this.getArrivalAndSetDefaultLanguage(this.contract.arrivalId);
      }
    });
  }

  async getArrivalAndSetDefaultLanguage(id: number) {
    try {
      const arrival = await this.http.get<Arrival>(`api/yardManagement/get/${id}`).toPromise();
      const transportCompanyCountryId = arrival.driver.transportCompanyCountry.id;

      const language = this.languages.find((l) => l.countryIDs.includes(transportCompanyCountryId));
      if (language) {
        this.contract.languageId = language.id;
      }
    } catch (e) {
      console.log(e);
    }
  }

  resetContractInput() {
    this.contract = {
      languageId: this.languages[0].id,
      arrivalId: null,
      type: DriverContractType.ShortTerm,
    };
  }

  async fetchLanguages() {
    try {
      this.languages = await this.http.get<DriverContractLanguage[]>(`api/driverContract/getContractLanguages`).toPromise();
    } catch (e) {
      console.log(e);
    }
  }

  async getContract() {
    if (!(this.contract.arrivalId > 0)) {
      this.toast.warning(this.msgT.enterArrivalID());
      return;
    }

    this.gettingContractInProgress = true;

    try {
      const template = await this.http
        .get<DriverContractTemplate | null>(
          `api/driverContract/GetDriverContractTemplate?languageId=${this.contract.languageId}&contractType=${this.contract.type}`
        )
        .toPromise();
      if (!template) {
        this.toast.warning(this.msgT.contractDoesntExist());
        this.gettingContractInProgress = false;
        return;
      }

      const arrivalExists = await this.http.get<{ exists: boolean }>(`api/driverContract/arrivalExists/${this.contract.arrivalId}`).toPromise();
      if (!arrivalExists.exists) {
        this.toast.warning(this.msgT.arrivalWithIDDoesntExist());
        this.gettingContractInProgress = false;
        return;
      }

      this.router.navigate(["/preview-contract", this.contract.languageId, this.contract.arrivalId, this.contract.type]);
    } catch (e) {
      this.toast.error(this.msgT.couldNotGenerateContract());
    }

    this.gettingContractInProgress = false;
  }
}
