/* eslint-disable brace-style */

import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";
import * as moment from "moment";
import { NgxPermissionsService } from "ngx-permissions";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { isProductNotPermittedError } from "../error-interceptor";
import { Role } from "../models/Role";
import { User } from "../models/User";
import { LocalStorageItems, LocalStorageService } from "../services/local-storage.service";
import { RestService } from "../services/rest.service";
import { getAuthConfig } from "./auth-config";

@Injectable({ providedIn: "root" })
export class AuthService {
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new BehaviorSubject<boolean>(false);
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  public loggedInUser: User | null = null;

  private hasLoggedInUserLoadedSubject$ = new BehaviorSubject<boolean>(false);
  public hasLoggedInUserLoaded$ = this.hasLoggedInUserLoadedSubject$.asObservable();

  public selectedCompanyDoesntExist: boolean = false;
  public notPermittedToAccessProduct: boolean = false;

  USER_ID = "userId";
  public userId: string | null = null;

  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errorred, and (b) the user ended up
   * being authenticated.
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   */
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([this.isAuthenticated$, this.isDoneLoading$]).pipe(
    map((values) => values.every((b) => b))
  );

  private navigateToLoginPage() {
    // TODO: Remember current URL
    this.router.navigateByUrl("/should-login");
  }

  public navigateToCompanyLoginPage(company?: string) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    this.router.navigate(["/login"], { queryParams: { company: company || urlSearchParams.get("company") } });
  }

  private navigateToHomeIfOnLogin() {
    if (this.router.url === "/login") {
      this.navigateToHome();
    }
  }

  public navigateToHome() {
    this.router.navigate(["/"]);
  }

  public navigateToErrorPage() {
    this.router.navigate(["/login-error"]);
  }

  public navigateToProductNotPermitted() {
    this.router.navigate(["/not-permitted"]);
  }

  constructor(
    private oauthService: OAuthService,
    private rest: RestService,
    private router: Router,
    private permissionsService: NgxPermissionsService,
    private route: ActivatedRoute
  ) {
    this.userId = localStorage.getItem(this.USER_ID);
    // This is tricky, as it might cause race conditions (where access_token is set in another
    // tab before everything is said and done there.
    // TODO: Improve this setup. See: https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/issues/2
    window.addEventListener("storage", (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== "access_token" && event.key !== null) {
        return;
      }

      console.warn("Noticed changes to access_token (most likely from another tab), updating isAuthenticated");
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

      if (!this.oauthService.hasValidAccessToken()) {
        this.navigateToLoginPage();
      }
    });

    this.oauthService.events.subscribe((_) => {
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
    });

    this.oauthService.events.pipe(filter((e) => ["token_received"].includes(e.type))).subscribe((e) => this.oauthService.loadUserProfile());

    this.oauthService.events.pipe(filter((e) => ["session_terminated", "session_error"].includes(e.type))).subscribe((e) => {
      console.log("error");
      // this.navigateToLoginPage();
    });

    this.oauthService.setupAutomaticSilentRefresh();

    this.isDoneLoading$.subscribe(async (isDone) => {
      this.permissionsService.flushPermissions();

      if (!isDone) {
        this.hasLoggedInUserLoadedSubject$.next(false);
        return;
      }

      const isAuthenticated = this.isAuthenticatedSubject$.getValue();
      if (!isAuthenticated) {
        this.hasLoggedInUserLoadedSubject$.next(true);
        return;
      }

      await this.fetchLoggedInUser();
      this.navigateToHomeIfOnLogin();
    });

    this.route.queryParams.subscribe((params) => {
      if (this.loggedInUser != null) {
        return;
      }

      const companyFromParams = params["company"];
      if (companyFromParams == null || companyFromParams.trim().length === 0) {
        return;
      }

      LocalStorageService.setItem(LocalStorageItems.SSO_REALM, companyFromParams);
      this.runInitialLoginSequence();
    });
  }

  private async fetchLoggedInUser() {
    try {
      await this.rest.post("api/sync/sync", {}).toPromise();
    } catch (e) {
      console.error(e);
      if (isProductNotPermittedError(e)) {
        this.navigateToProductNotPermitted();
        this.notPermittedToAccessProduct = true;
        return;
      }
    }

    const appProfile = await this.rest.get<User>("api/user/getProfile").toPromise();
    this.loggedInUser = appProfile;

    this.permissionsService.flushPermissions();
    for (const role of appProfile.roles) {
      this.permissionsService.addPermission(role);
    }

    this.hasLoggedInUserLoadedSubject$.next(true);
  }

  public Refresh() {
    this.fetchLoggedInUser();
  }

  public async runInitialLoginSequence(userSubmit = false): Promise<void> {
    this.selectedCompanyDoesntExist = false;
    this.notPermittedToAccessProduct = false;

    const company = LocalStorageService.getItem(LocalStorageItems.SSO_REALM);
    if (!company) {
      this.isDoneLoadingSubject$.next(true);
      return;
    }

    let exists = false;
    try {
      const existsResponse = await this.rest.post<{ exists: boolean }>("api/company/companyExists", { company }, false).toPromise();
      exists = existsResponse.exists;
    } catch (e) {
      console.error("Exists error", e);
    }

    if (!exists) {
      if (userSubmit) {
        this.selectedCompanyDoesntExist = true;
      }
      this.isDoneLoadingSubject$.next(true);
      return;
    }

    this.oauthService.configure(getAuthConfig(company));

    // 0. LOAD CONFIG:
    // First we have to check to see how the IdServer is
    // currently configured:
    return (
      this.oauthService
        .loadDiscoveryDocument()

        // 1. HASH LOGIN:
        // Try to log in via hash fragment after redirect back
        // from IdServer from initImplicitFlow:
        .then(() => {
          return this.oauthService.tryLogin();
        })

        .then(() => {
          if (this.oauthService.hasValidAccessToken()) {
            return Promise.resolve();
          }

          // 2. SILENT LOGIN:
          // Try to log in via a refresh because then we can prevent
          // needing to redirect the user:
          return this.oauthService
            .silentRefresh()
            .then(() => Promise.resolve())
            .catch((result) => {
              // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
              // Only the ones where it's reasonably sure that sending the
              // user to the IdServer will help.
              let errorMessage = null;
              if (result?.reason) {
                errorMessage = result.reason.error;

                if (errorMessage == null && result.reason.params) {
                  errorMessage = result.reason.params.error;
                }
              }

              const errorResponsesRequiringUserInteraction = ["interaction_required", "login_required", "account_selection_required", "consent_required"];
              if (errorResponsesRequiringUserInteraction.indexOf(errorMessage) >= 0) {
                // 3. ASK FOR LOGIN:
                // At this point we know for sure that we have to ask the
                // user to log in, so we redirect them to the IdServer to
                // enter credentials.
                //
                // Enable this to ALWAYS force a user to login.
                // this.login();
                //
                // Instead, we'll now do this:
                console.warn("User interaction is needed to log in, we will wait for the user to manually log in.");
                return Promise.resolve();
              }

              // We can't handle the truth, just pass on the problem to the
              // next handler.
              return Promise.reject(result);
            });
        })

        .then(() => {
          this.isDoneLoadingSubject$.next(true);

          // Check for the strings 'undefined' and 'null' just to be sure. Our current
          // login(...) should never have this, but in case someone ever calls
          // initImplicitFlow(undefined | null) this could happen.
          if (this.oauthService.state && this.oauthService.state !== "undefined" && this.oauthService.state !== "null") {
            let stateUrl = this.oauthService.state;
            if (stateUrl.startsWith("/") === false) {
              stateUrl = decodeURIComponent(stateUrl);
            }
            console.log(`There was state of ${this.oauthService.state}, so we are sending you to: ${stateUrl}`);
            this.router.navigateByUrl(stateUrl);
          }
        })
        .catch((e) => {
          this.isDoneLoadingSubject$.next(true);

          const ignoredErrors = ["silent_refresh_error"];
          if (ignoredErrors.includes(e?.type)) {
            return;
          }

          this.navigateToErrorPage();
        })
    );
  }

  public login(targetUrl?: string) {
    const company = LocalStorageService.getItem(LocalStorageItems.SSO_REALM);
    if (!company) {
      this.navigateToCompanyLoginPage();
      return;
    }

    const hasLoginExpired = this.hasLoginExpired();
    if (hasLoginExpired) {
      this.navigateToCompanyLoginPage(company);
      return;
    }

    // Note: before version 9.1.0 of the library you needed to
    // call encodeURIComponent on the argument to the method.
    this.oauthService.initLoginFlow(targetUrl || this.router.url);
  }

  private hasLoginExpired(): boolean {
    const lastLoginAtTimestampStr = LocalStorageService.getItem(LocalStorageItems.LAST_LOGIN_AT);
    const lastLoginAtTimestamp = Number(lastLoginAtTimestampStr);
    if (lastLoginAtTimestamp == null || isNaN(lastLoginAtTimestamp)) {
      return false;
    }

    const lastLoginAtDate = moment(lastLoginAtTimestamp);
    const minutesSinceLastLogin = moment().diff(lastLoginAtDate, "minutes");
    return minutesSinceLastLogin > 5;
  }

  public logout() {
    this.loggedInUser = null;
    this.permissionsService.flushPermissions();

    LocalStorageService.deleteItem(LocalStorageItems.SSO_REALM);

    this.oauthService.logOut();
    this.navigateToCompanyLoginPage();
  }

  public refresh() {
    this.oauthService.silentRefresh();
  }

  public hasValidToken() {
    return this.oauthService.hasValidAccessToken();
  }

  // These normally won't be exposed from a service like this, but
  // for debugging it makes sense.
  public get accessToken() {
    return this.oauthService.getAccessToken();
  }

  public get refreshToken() {
    return this.oauthService.getRefreshToken();
  }

  public get identityClaims() {
    return this.oauthService.getIdentityClaims();
  }

  public get idToken() {
    return this.oauthService.getIdToken();
  }

  public get logoutUrl() {
    return this.oauthService.logoutUrl;
  }

  IsLoggedIn() {
    return this.loggedInUser != null;
  }

  IsWarehouseAdmin() {
    return this.loggedInUser != null && this.loggedInUser.roles.includes(Role.COMPANY_ADMIN);
  }

  IsGateManager() {
    if (this.IsWarehouseAdmin()) {
      return true;
    }

    return this.loggedInUser != null && this.loggedInUser.roles.includes(Role.YAMAS_GATE_MANAGER);
  }

  IsWarehouseManager() {
    if (this.IsWarehouseAdmin()) {
      return true;
    }

    return this.loggedInUser != null && this.loggedInUser.roles.includes(Role.YAMAS_WAREHOUSE_MANAGER);
  }

  public setUserId(userId: string) {
    this.userId = userId;
    localStorage.setItem(this.USER_ID, userId);
  }

  public removeUserId() {
    this.userId = null;
    localStorage.removeItem(this.USER_ID);
  }
}
