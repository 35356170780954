import { Component, OnInit } from "@angular/core";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import * as moment from "moment";
import { DateService } from "src/app/services/date.service";
import { BookedRampsStats, BookedRampsStatsDatePrecision, StatsService } from "src/app/services/stats.service";

export interface BookedRampsDateFilter {
  dateFrom: Date | null;
  dateTo: Date | null;
  precision: BookedRampsStatsDatePrecision;
}

@Component({
  selector: "app-stats-ramps-booking-time",
  templateUrl: "./stats-ramps-booking-time.component.html",
  styleUrls: ["./stats-ramps-booking-time.component.css"],
})
export class StatsRampsBookingTimeComponent implements OnInit {
  BookedRampsStatsDatePrecision = BookedRampsStatsDatePrecision;
  faQuestionCircle = faQuestionCircle;

  isLoading = false;
  stats: BookedRampsStats | null = null;

  filter: BookedRampsDateFilter = {
    dateFrom: moment().subtract(1, "week").toDate(),
    dateTo: new Date(),
    precision: BookedRampsStatsDatePrecision.WEEKLY,
  };

  constructor(public dateService: DateService, private statsService: StatsService) {}

  ngOnInit(): void {}

  async calculate() {
    this.isLoading = true;
    this.stats = await this.statsService.getBookedRampsStats(this.filter);
    this.isLoading = false;
  }

  onDateChange(dateFrom: Date | null | undefined, dateTo: Date | null | undefined) {
    dateFrom = dateFrom || this.filter.dateFrom;
    dateTo = dateTo || this.filter.dateTo;

    ({ dateFrom, dateTo } = this.dateService.swapDatesIfFromLaterThanTo(dateFrom, dateTo));

    this.filter.dateFrom = dateFrom;
    this.filter.dateTo = dateTo;
  }

  formatDate(date: Date, precision: BookedRampsStatsDatePrecision): string {
    switch (precision) {
      case BookedRampsStatsDatePrecision.YEARLY:
        return moment(date).format("YYYY");
      case BookedRampsStatsDatePrecision.MONTHLY:
        return moment(date).format("MMM YYYY");
      case BookedRampsStatsDatePrecision.WEEKLY:
        return moment(date).format("W[W] YYYY");
      case BookedRampsStatsDatePrecision.DAILY:
      default:
        return moment(date).format("D MMM YYYY (dddd)");
    }
  }
}
