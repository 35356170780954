import { Component } from "@angular/core";
import { UserType } from "../models/User";
import { RestService } from "../services/rest.service";
import { ToastrService } from "ngx-toastr";
import { MessageTranslationService } from "../message-translation.service";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "../core/auth.service";

@Component({
  selector: "app-nav-menu",
  templateUrl: "./nav-menu.component.html",
  styleUrls: ["./nav-menu.component.css"],
})
export class NavMenuComponent {
  public toggleNavbar = false;
  userType = UserType;
  userIdInput = "";

  constructor(
    private http: RestService,
    public auth: AuthService,
    private toast: ToastrService,
    private msgT: MessageTranslationService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {}

  Logout() {
    this.http.post("api/authentication/logout", {}).subscribe(
      (d) => {
        this.onLogout();
      },
      (error) => {
        console.log("Log out error");
        console.log(error);
        this.onLogout();
      }
    );
  }

  closeNavMenu() {
    this.toggleNavbar = false;
  }

  onLogout() {
    this.auth.logout();
    this.toast.success(this.msgT.loggedOutSuccess());
    this.auth.removeUserId();
  }

  open(content) {
    this.userIdInput = "";

    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result: boolean | null) => {
          if (result == null) {
            this.auth.removeUserId();
          }

          if (result && this.userIdInput.length > 0) {
            this.auth.setUserId(this.userIdInput);
          }
        },
        (_) => {}
      );
  }
}
