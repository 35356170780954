import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../core/auth.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
})
export class HomeComponent {
  constructor(private auth: AuthService, private router: Router) {
    this.auth.hasLoggedInUserLoaded$.subscribe((isDone) => {
      if (isDone) {
        this.Redirect();
      }
    });
  }

  Redirect() {
    if (this.auth.IsLoggedIn()) {
      this.router.navigate(["/warehouse-history"]);
    }
  }
}
