<app-accordion-with-content i18n-label label="YAMAS preferences">
  <ng-container *ngIf="warehouses.length === 0"><i i18n>No warehouses available.</i></ng-container>
  <ng-container *ngIf="warehouses.length > 0">
    <div class="form-group">
      <label for="type"><ng-container i18n>Default warehouse</ng-container></label>
      <select [(ngModel)]="warehouse" id="type" name="type" class="form-control">
        <option disabled [value]="null" i18n>Select warehouse</option>
        <option [value]="w.id" *ngFor="let w of warehouses">
          <ng-container>{{ w.name }}</ng-container>
        </option>
      </select>
    </div>

    <button class="my-2 btn btn-primary" (click)="ChangeWarehouse()">
      <ng-container i18n> Change warehouse</ng-container>
    </button>
  </ng-container>

  <ng-container>
    <div class="form-group">
      <label for="type"><ng-container i18n>Months before showing warning for contract expiry</ng-container></label>
      <input [disabled]="!canEditMonthsBeforeExpiryWarning" type="number" class="form-control" [(ngModel)]="monthsBeforeExpiryWarning" />
    </div>

    <button class="my-2 btn btn-primary" (click)="ChangeMonthsBeforeExpiryWarning()" [disabled]="!canEditMonthsBeforeExpiryWarning">
      <ng-container i18n> Change months</ng-container>
    </button>
  </ng-container>
</app-accordion-with-content>
