import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/core/auth.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-product-not-permitted",
  templateUrl: "./product-not-permitted.component.html",
  styleUrls: ["./product-not-permitted.component.css"],
})
export class ProductNotPermittedComponent implements OnInit {
  encodeURIComponent = encodeURIComponent;
  dashboardUrl = "";

  constructor(public auth: AuthService) {
    this.dashboardUrl = environment.dashboardUrl;
    this.auth.hasLoggedInUserLoaded$.subscribe(() => {
      if (this.auth.loggedInUser) {
        this.auth.navigateToHome();
      }
    });
  }

  ngOnInit(): void {}
}
