import { Injectable } from "@angular/core";
import { Arrival } from "./models/Arrival";
import { ArrivalStatus } from "./models/ArrivalStatus";
import { ProcessingStatus } from "./models/ProcessingStatus";
import { MessageTranslationService } from "./message-translation.service";
import { DateConverter } from "./DateConverter";
import { AuthService } from "./core/auth.service";

@Injectable({
  providedIn: "root",
})
export class ArrivalUtilsService {
  constructor(
    private auth: AuthService,
    private msgT: MessageTranslationService
  ) {}

  public processingAllowed(arrival: Arrival) {
    if (arrival.permitFurtherActions === false) {
      return false;
    }

    if (arrival.status === ArrivalStatus.Departed) {
      return false;
    }

    if (
      arrival.status === ArrivalStatus.AtRamp ||
      arrival.status === ArrivalStatus.UnloadingStarted ||
      arrival.status === ArrivalStatus.OnHold ||
      arrival.status === ArrivalStatus.OnHoldEnd
    ) {
      if (this.auth.IsWarehouseManager()) {
        return true;
      }
    }

    if (
      arrival.status === ArrivalStatus.UnloadingDone ||
      arrival.status === ArrivalStatus.ReturnedToStart ||
      arrival.status === ArrivalStatus.EarlyDeparture
    ) {
      if (this.auth.IsGateManager()) {
        return true;
      }
    }

    return false;
  }

  public earlyDepartureAllowed(arrival: Arrival) {
    if (arrival.status !== ArrivalStatus.Arrived) {
      return false;
    }

    if (this.auth.IsWarehouseManager()) {
      return true;
    }

    return false;
  }

  public undoAllowed(arrival: Arrival) {
    // only gate manager can undo departure
    if (
      arrival.status === ArrivalStatus.Departed &&
      this.auth.IsGateManager() === false
    ) {
      return false;
    }

    // only warehouse manager can undo previous statuses
    if (
      arrival.status !== ArrivalStatus.Departed &&
      this.auth.IsWarehouseManager() === false
    ) {
      return false;
    }

    if (arrival.status === ArrivalStatus.Arrived) {
      return false;
    }

    return true;
  }

  public processingDisallowedText(arrival: Arrival) {
    if (arrival.status === ArrivalStatus.Departed) {
      return ProcessingStatus.DRIVER_DEPARTED;
    }

    if (arrival.currentRamp == null) {
      return ProcessingStatus.NO_RAMP;
    }

    if (
      arrival.driver.contract == null &&
      arrival.contractMarkedAsUploaded === false
    ) {
      return ProcessingStatus.DRIVER_NO_CONTRACT;
    }

    if (arrival.driver.contract != null && arrival.driver.contract.hasExpired) {
      return ProcessingStatus.CONTRACT_EXPIRED;
    }

    return ProcessingStatus.UNAUTHORIZED;
  }

  public isModified(arrival: Arrival) {
    return (
      arrival.driver.name !== arrival.originalData.driverName ||
      arrival.driver.transportCompany !==
        arrival.originalData.driverTransportCompany ||
      arrival.driver.transportCompanyCountry.id !==
        arrival.originalData.transportCompanyCountry.id ||
      arrival.loadingCountry.id !== arrival.originalData.loadingCountry.id ||
      arrival.registrationNumber !== arrival.originalData.registrationNumber ||
      arrival.arrivalTime !== arrival.originalData.arrivalTime
    );
  }

  public setOriginalData(arrival: Arrival) {
    arrival.originalData = {
      driverName: arrival.driver.name,
      driverTransportCompany: arrival.driver.transportCompany,
      registrationNumber: arrival.registrationNumber,
      loadingCountry: arrival.loadingCountry,
      transportCompanyCountry: arrival.driver.transportCompanyCountry,
      arrivalTime: arrival.arrivalTime,
    };
  }

  public undoChanges(arrival: Arrival) {
    arrival.driver.name = arrival.originalData.driverName;
    arrival.driver.transportCompany =
      arrival.originalData.driverTransportCompany;
    arrival.registrationNumber = arrival.originalData.registrationNumber;
    arrival.loadingCountry = arrival.originalData.loadingCountry;
    arrival.driver.transportCompanyCountry =
      arrival.originalData.transportCompanyCountry;
    arrival.isModified = false;
    arrival.filterString = this.searchString(arrival);
    arrival.arrivalTime = arrival.originalData.arrivalTime;
  }

  public searchString(arrival: Arrival) {
    return (
      arrival.driver.name +
      " " +
      (arrival.driver.code ? arrival.driver.code : "") +
      " " +
      arrival.driver.transportCompany +
      " " +
      arrival.loadingCountry.name +
      " " +
      arrival.registrationNumber +
      " " +
      arrival.driver.transportCompanyCountry.name +
      " " +
      arrival.gateManagerMarkedArrival.name +
      " " +
      (arrival.gateManagerMarkedDeparture
        ? arrival.gateManagerMarkedDeparture.name
        : "") +
      " " +
      this.msgT.arrivalStatusToString(arrival.status)
    ).toLowerCase();
  }

  public setTimezoneTimes(arrival: Arrival) {
    arrival.arrivalTime = DateConverter.toLocalTime(arrival.arrivalTime);
    arrival.earlyDepartureTime = DateConverter.toLocalTime(
      arrival.earlyDepartureTime
    );
    arrival.departureTime = DateConverter.toLocalTime(arrival.departureTime);

    arrival.arrivalsOnRamp.forEach((ar) => {
      ar.unloadingEndTime = DateConverter.toLocalTime(ar.unloadingEndTime);
      ar.unloadingStartTime = DateConverter.toLocalTime(ar.unloadingStartTime);
      ar.onHoldTime = DateConverter.toLocalTime(ar.onHoldTime);
      ar.onHoldEndTime = DateConverter.toLocalTime(ar.onHoldEndTime);
      ar.returnedToStartTime = DateConverter.toLocalTime(
        ar.returnedToStartTime
      );
    });
  }
}
