<div class="container-fluid app-container arrival-table-container">
  <h1 class="app-heading">
    <ng-container *ngIf="isArchive" i18n="Arrival archive">Arrival archive</ng-container>
    <ng-container *ngIf="!isArchive" i18n="Active arrivals">Arrivals</ng-container>
  </h1>

  <ng-container *ngIf="!isArchive">
    <div *ngIf="warehouses" class="d-flex justify-content-center mb-4 checkboxes-container">
      <div class="form-check form-check-inline" *ngFor="let w of warehouses">
        <input
          class="form-check-input"
          type="checkbox"
          id="warehouseFilterCheckbox{{ w.id }}"
          (change)="warehouseCheckboxChange($event.target.checked, w.id)"
          [checked]="warehouseFilterCheckboxes[w.id]"
        />
        <label class="form-check-label" for="warehouseFilterCheckbox{{ w.id }}">
          <ng-container>{{ w.name }}</ng-container>
        </label>
      </div>
    </div>

    <div *ngIf="warehouseParts" class="d-flex justify-content-center mt-4 mb-4">
      <ul ngbNav #nav="ngbNav" (navChange)="navChange($event.nextId)" class="nav-pills justify-content-center">
        <li [ngbNavItem]="0">
          <a ngbNavLink i18n="All arrivals">All arrivals</a>
          <ng-template ngbNavContent>
            <p>ALL</p>
          </ng-template>
        </li>
        <li [ngbNavItem]="-1">
          <a ngbNavLink i18n="Unsorted">Unsorted</a>
          <ng-template ngbNavContent>
            <p>UNSORTED</p>
          </ng-template>
        </li>
        <li [ngbNavItem]="wp.id" *ngFor="let wp of warehouseParts">
          <ng-container *ngIf="warehouseFilterCheckboxes[wp.warehouse.id]">
            <a ngbNavLink>{{ wp.code }} {{ wp.warehouse.suffix ? "(" + wp.warehouse.suffix + ")" : "" }}</a>
            <ng-template ngbNavContent>
              <p>{{ wp.code }}</p>
            </ng-template></ng-container
          >
        </li>
        <li [ngbNavItem]="-2">
          <a ngbNavLink i18n="Latest">Latest updated</a>
          <ng-template ngbNavContent>
            <p>LATEST UPDATED</p>
          </ng-template>
        </li>
      </ul>
    </div>

    <div class="d-flex justify-content-center mb-4 checkboxes-container">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="arrivalFilterCheckbox" (change)="checkboxFilterChange($event, ArrivalStatus.Arrived)" checked />
        <label class="form-check-label" for="arrivalFilterCheckbox">
          <ng-container i18n="Arrival">Arrival</ng-container>
        </label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="atRampFilterCheckbox" (change)="checkboxFilterChange($event, ArrivalStatus.AtRamp)" checked />
        <label class="form-check-label" for="atRampFilterCheckbox">
          <ng-container i18n="At ramp">At ramp</ng-container>
        </label>
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="checkbox"
          id="unloadingStartedFilterCheckbox"
          (change)="checkboxFilterChange($event, ArrivalStatus.UnloadingStarted)"
          checked
        />
        <label class="form-check-label" for="unloadingStartedFilterCheckbox">
          <ng-container i18n="Unloading start">Unloading start</ng-container>
        </label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="uEndFilterCheckbox" (change)="checkboxFilterChange($event, ArrivalStatus.UnloadingDone)" checked />
        <label class="form-check-label" for="uEndFilterCheckbox">
          <ng-container i18n="Unloading end">Unloading done</ng-container>
        </label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="onHoldFilterCheckbox" (change)="checkboxFilterChange($event, ArrivalStatus.OnHold)" checked />
        <label class="form-check-label" for="onHoldFilterCheckbox">
          <ng-container i18n="On hold">On hold</ng-container>
        </label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="onHoldEndFilterCheckbox" (change)="checkboxFilterChange($event, ArrivalStatus.OnHoldEnd)" checked />
        <label class="form-check-label" for="onHoldEndFilterCheckbox">
          <ng-container i18n="Issue resolved">Issue resolved</ng-container>
        </label>
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="checkbox"
          id="returnedToStartFilterCheckbox"
          (change)="checkboxFilterChange($event, ArrivalStatus.ReturnedToStart)"
          checked
        />
        <label class="form-check-label" for="returnedToStartFilterCheckbox">
          <ng-container i18n="Returned to start">Returned to start</ng-container>
        </label>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="filteredArrivals">
    <div class="d-flex" class="table-filter-container">
      <input
        class="form-control table-filter-input"
        i18n-placeholder="Filter..."
        placeholder="Filter..."
        type="text"
        [(ngModel)]="filterTextRaw"
        (input)="filterTextChange($event.target.value)"
        (keyup.enter)="applyFilters()"
      />
      <button *ngIf="!isArchive" class="btn btn-primary table-refresh-btn" i18n="Refresh" (click)="onRefreshClick()">Refresh</button>
    </div>

    <br />

    <ng-container *ngIf="isArchive">
      <h4 i18n="Additional filters">Additional filters</h4>

      <div>
        <form class="d-flex flex-wrap">
          <div class="form-group mr-4">
            <label for="dateFrom"><ng-container i18n="Date from">Date from</ng-container></label>
            <input
              class="form-control"
              placeholder="yyyy-mm-dd"
              name="dateFrom"
              [(ngModel)]="dateFrom"
              (click)="dT.toggle()"
              ngbDatepicker
              #dT="ngbDatepicker"
            />
          </div>

          <div class="form-group mr-4">
            <label for="dateTo"><ng-container i18n="Date to">Date to</ng-container></label>
            <input class="form-control" placeholder="yyyy-mm-dd" name="dateTo" [(ngModel)]="dateTo" (click)="dF.toggle()" ngbDatepicker #dF="ngbDatepicker" />
          </div>

          <div class="form-group mr-4">
            <label for="type"><ng-container i18n="Arrivals per page">Arrivals per page</ng-container></label>
            <select [(ngModel)]="archivePageSizeInput" id="type" name="type" class="form-control">
              <option *ngFor="let p of possibleArchivePageSIzes" [value]="p">
                {{ p }}
              </option>
            </select>
          </div>

          <div class="form-group" *ngIf="warehouses">
            <label for="type"><ng-container i18n="Warehouse">Warehouse</ng-container></label>
            <hr class="m-0 mb-2" />
            <div class="d-flex checkboxes-container">
              <div style="margin-bottom: unset !important" class="form-check form-check-inline" *ngFor="let w of warehouses">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="warehouseFilterCheckbox{{ w.id }}"
                  (change)="warehouseCheckboxChange($event.target.checked, w.id)"
                  [checked]="warehouseFilterCheckboxes[w.id]"
                />
                <label class="form-check-label" for="warehouseFilterCheckbox{{ w.id }}">
                  <ng-container>{{ w.name }}</ng-container>
                </label>
              </div>
            </div>
          </div>

          <div class="form-group ml-2" *ngIf="warehouses">
            <label for="type"><ng-container i18n>Warehouse parts</ng-container></label>
            <hr class="m-0 mb-2" />
            <div class="d-flex checkboxes-container">
              <ng-container *ngFor="let w of warehouses">
                <div style="margin-bottom: unset !important" class="form-check form-check-inline" *ngFor="let part of w.warehouseParts">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="warehousePartFilterCheckbox{{ part.id }}"
                    (change)="warehousePartCheckboxChange($event.target.checked, part.id)"
                    [checked]="warehousePartFilterCheckboxes[part.id]"
                    [disabled]="warehouseFilterCheckboxes[w.id] == false"
                  />
                  <label class="form-check-label" for="warehousePartFilterCheckbox{{ part.id }}">
                    <ng-container>{{ part.code }} ({{ w.name }})</ng-container>
                  </label>
                </div>
              </ng-container>
            </div>
          </div>

          <div class="form-group ml-2" *ngIf="warehouses">
            <label for="type"><ng-container i18n>Ramps</ng-container></label>
            <hr class="m-0 mb-2" />
            <div class="d-flex checkboxes-container">
              <ng-container *ngFor="let w of warehouses">
                <ng-container *ngFor="let part of w.warehouseParts">
                  <div style="margin-bottom: unset !important" class="form-check form-check-inline" *ngFor="let ramp of part.ramps">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="rampFilterCheckbox{{ ramp.id }}"
                      (change)="rampCheckboxChange($event.target.checked, ramp.id)"
                      [checked]="rampFilterCheckboxes[ramp.id]"
                      [disabled]="warehousePartFilterCheckboxes[part.id] == false"
                    />
                    <label class="form-check-label" for="rampFilterCheckbox{{ ramp.id }}">
                      <ng-container>{{ ramp.name }} ({{ part.code }})</ng-container>
                    </label>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </form>
      </div>

      <div>
        <button class="btn btn-primary mb-3" (click)="applyFilters()">
          <ng-container i18n="Apply filters">Apply filters</ng-container>
        </button>
        <button class="btn btn-primary mb-3 ml-2" (click)="resetFilters()">
          <ng-container i18n="Reset filters">Reset filters</ng-container>
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="renderingService.isMobile()">
      <br />
      <ngb-pagination *ngIf="!isArchive" [(page)]="pageNumber" [pageSize]="pageSize" [collectionSize]="filteredArrivals.length" [maxSize]="8"></ngb-pagination>

      <ngb-pagination
        *ngIf="isArchive"
        [collectionSize]="archivePageCount * archivePageSize"
        [maxSize]="8"
        [pageSize]="archivePageSize"
        [(page)]="archivePageNumber"
        [rotate]="true"
        (pageChange)="loadPage($event)"
      ></ngb-pagination>
    </ng-container>

    <ng-container *ngIf="filteredArrivals.length > 0">
      <div class="table-responsive" *ngIf="renderingService.isDesktop()">
        <div class="d-flex flex-wrap align-items-end justify-content-between">
          <p i18n="Double click on table cells to change information">
            Double click on table cells 'Arrival', 'Driver', 'Company', 'Company country', 'Loading county' and 'Registration number' to change information.
          </p>
          <div class="form-group" *ngIf="!isArchive">
            <label for="type"><ng-container i18n="Arrivals per page">Arrivals per page</ng-container></label>
            <select [(ngModel)]="pageSize" id="type" name="type" class="form-control">
              <option *ngFor="let p of possibleArchivePageSIzes" [value]="p">
                {{ p }}
              </option>
            </select>
          </div>
        </div>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th i18n="Date">Date</th>
              <th i18n="Arrival">Arrival</th>
              <th i18n="Unloading start">Unloading start</th>
              <th i18n="Unloading end">Unloading end</th>
              <th i18n="Departure" *ngIf="isArchive">Departure</th>
              <th i18n="Driver ID">Driver ID</th>
              <th i18n="Driver">Driver</th>
              <th i18n="Company">Company</th>
              <th i18n="Registration number">Registration number</th>
              <th i18n="Company country">Company country</th>
              <th i18n="Loading country">Loading country</th>
              <th i18n="Contract information">Contract information</th>
              <th i18n="Status">Status</th>
              <th i18n="Ramp">Ramp</th>
              <th i18n="Warehouse">Warehouse</th>
              <th i18n="Actions">Actions</th>
              <th i18n="Details">Details</th>
            </tr>
          </thead>
          <tbody>
            <tr
              tabindex="1"
              #arrivalTr
              *ngFor="
                let arrival of isArchive ? filteredArrivals : (filteredArrivals | slice : (pageNumber - 1) * pageSize : (pageNumber - 1) * pageSize + pageSize);
                let arrivalIndex = index
              "
            >
              <td>
                {{ arrival.arrivalTime | date : "dd.MM.yyyy" }}
              </td>
              <td *ngIf="arrival.arrivalTime && arrival.gateManagerMarkedArrival">
                <fa-icon style="color: green" [icon]="faCheck"></fa-icon>

                <editable (update)="OnUpdateArrival(arrival)" (modeChange)="OnEditableModeChange($event)">
                  <ng-template viewMode>{{ arrival.arrivalTime | date : "HH:mm" }}</ng-template>
                  <ng-template editMode *ngIf="auth.IsWarehouseAdmin()">
                    <input focusable editableOnEnter [(ngModel)]="arrival.arrivalTime" />
                  </ng-template>
                </editable>

                <ng-container i18n="by">by</ng-container>
                {{ arrival.gateManagerMarkedArrival.name }}
              </td>

              <td *ngIf="!(arrival.arrivalTime && arrival.gateManagerMarkedArrival)">
                <fa-icon style="color: red" [icon]="faTimes"></fa-icon>
              </td>

              <ng-container *ngIf="arrival.earlyDepartureTime == null || arrival.warehouseManagerMarkedEarlyDeparture == null">
                <ng-container *ngIf="!arrival.arrivalsOnRamp || arrival.arrivalsOnRamp.length === 0">
                  <td>
                    <fa-icon style="color: red" [icon]="faTimes"></fa-icon>
                  </td>
                  <td>
                    <fa-icon style="color: red" [icon]="faTimes"></fa-icon>
                  </td>
                </ng-container>

                <ng-container *ngIf="arrival.arrivalsOnRamp && arrival.arrivalsOnRamp.length > 0">
                  <td>
                    <div *ngFor="let ar of arrival.arrivalsOnRamp" style="margin-bottom: 7px">
                      <span class="line-span">
                        <ng-container *ngIf="ar.unloadingStartTime && ar.warehouseManagerMarkedUnloadingStart">
                          <ng-template #warehouseManagerMarkedUnloadingStartTip>{{ ar.warehouseManagerMarkedUnloadingStart.name }}</ng-template>

                          <fa-icon style="color: green" [icon]="faCheck"></fa-icon>
                          {{ ar.unloadingStartTime | date : "HH:mm" }}
                          <ng-container i18n="by">by</ng-container>
                          <span [ngbTooltip]="warehouseManagerMarkedUnloadingStartTip">
                            {{
                              ar.warehouseManagerMarkedUnloadingStartUserId
                                ? userUtils.renderUserId(ar.warehouseManagerMarkedUnloadingStartUserId)
                                : ar.warehouseManagerMarkedUnloadingStart.name
                            }}
                          </span>
                        </ng-container>

                        <ng-container *ngIf="!(ar.unloadingStartTime && ar.warehouseManagerMarkedUnloadingStart)">
                          <fa-icon style="color: red" [icon]="faTimes"></fa-icon>
                        </ng-container>

                        <span class="ramp-span"> ({{ ar.ramp.name }} {{ arrival.warehouse.suffix }}) </span>
                      </span>

                      <span class="line-span" *ngIf="ar.onHoldTime && ar.warehouseManagerMarkedOnHold">
                        <ng-container>
                          <ng-template #warehouseManagerMarkedOnHoldTip>{{ ar.warehouseManagerMarkedOnHold.name }}</ng-template>

                          <fa-icon style="color: red" [icon]="faExclamationTriangle"></fa-icon>
                          {{ ar.onHoldTime | date : "HH:mm" }}
                          <ng-container i18n="by">by</ng-container>
                          <span [ngbTooltip]="warehouseManagerMarkedOnHoldTip">
                            {{
                              ar.warehouseManagerMarkedOnHoldUserId
                                ? userUtils.renderUserId(ar.warehouseManagerMarkedOnHoldUserId)
                                : ar.warehouseManagerMarkedOnHold.name
                            }}
                          </span>
                        </ng-container>

                        <span class="ramp-span"> ({{ ar.ramp.name }} {{ arrival.warehouse.suffix }}) </span>
                      </span>
                    </div>
                  </td>

                  <td>
                    <div *ngFor="let ar of arrival.arrivalsOnRamp" style="margin-bottom: 7px">
                      <span class="line-span">
                        <ng-container *ngIf="ar.onHoldEndTime && ar.warehouseManagerMarkedOnHoldEnd">
                          <ng-template #warehouseManagerMarkedOnHoldEndTip>{{ ar.warehouseManagerMarkedOnHoldEnd.name }}</ng-template>

                          <fa-icon style="color: green" [icon]="faExclamationTriangle"></fa-icon>
                          {{ ar.onHoldEndTime | date : "HH:mm" }}
                          <ng-container i18n="by">by</ng-container>
                          <span [ngbTooltip]="warehouseManagerMarkedOnHoldEndTip">
                            {{
                              ar.warehouseManagerMarkedOnHoldEndUserId
                                ? userUtils.renderUserId(ar.warehouseManagerMarkedOnHoldEndUserId)
                                : ar.warehouseManagerMarkedOnHoldEnd.name
                            }}
                          </span>

                          <span class="ramp-span"> ({{ ar.ramp.name }} {{ arrival.warehouse.suffix }}) </span>
                        </ng-container>

                        <ng-container *ngIf="!(ar.onHoldEndTime && ar.warehouseManagerMarkedOnHoldEnd)">
                          <ng-container *ngIf="ar.returnedToStartTime && ar.warehouseManagerMarkedReturnedToStart">
                            <ng-template #warehouseManagerMarkedReturnedToStartTip>{{ ar.warehouseManagerMarkedReturnedToStart.name }}</ng-template>

                            <fa-icon style="color: orange" [icon]="faUndo"></fa-icon>
                            {{ ar.returnedToStartTime | date : "HH:mm" }}
                            <ng-container i18n="by">by</ng-container>
                            <span [ngbTooltip]="warehouseManagerMarkedReturnedToStartTip">
                              {{
                                ar.warehouseManagerMarkedReturnedToStartUserId
                                  ? userUtils.renderUserId(ar.warehouseManagerMarkedReturnedToStartUserId)
                                  : ar.warehouseManagerMarkedReturnedToStart.name
                              }}
                            </span>

                            <span class="ramp-span"> ({{ ar.ramp.name }} {{ arrival.warehouse.suffix }}) </span>
                          </ng-container>
                        </ng-container>
                      </span>

                      <span class="line-span">
                        <ng-container *ngIf="ar.unloadingEndTime && ar.warehouseManagerMarkedUnloadingEnd">
                          <ng-template #warehouseManagerMarkedUnloadingEndTip>{{ ar.warehouseManagerMarkedUnloadingEnd.name }}</ng-template>

                          <fa-icon style="color: green" [icon]="faCheck"></fa-icon>
                          {{ ar.unloadingEndTime | date : "HH:mm" }}
                          <ng-container i18n="by">by</ng-container>
                          <span [ngbTooltip]="warehouseManagerMarkedUnloadingEndTip">
                            {{
                              ar.warehouseManagerMarkedUnloadingEndUserId
                                ? userUtils.renderUserId(ar.warehouseManagerMarkedUnloadingEndUserId)
                                : ar.warehouseManagerMarkedUnloadingEnd.name
                            }}
                          </span>

                          <span class="ramp-span"> ({{ ar.ramp.name }} {{ arrival.warehouse.suffix }}) </span>
                        </ng-container>

                        <ng-container *ngIf="!(ar.unloadingEndTime && ar.warehouseManagerMarkedUnloadingEnd)">
                          <ng-container *ngIf="ar.returnedToStartTime && ar.warehouseManagerMarkedReturnedToStart">
                            <span class="ramp-span"></span>
                          </ng-container>
                          <ng-container *ngIf="!(ar.returnedToStartTime && ar.warehouseManagerMarkedReturnedToStart)">
                            <fa-icon style="color: red" [icon]="faTimes"></fa-icon>

                            <span class="ramp-span"> ({{ ar.ramp.name }} {{ arrival.warehouse.suffix }}) </span>
                          </ng-container>
                        </ng-container>
                      </span>
                    </div>
                  </td>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="arrival.earlyDepartureTime != null && arrival.warehouseManagerMarkedEarlyDeparture != null">
                <td colspan="2">
                  <div class="d-flex justify-content-center align-items-center">
                    <ng-template #reasonForDepartureTip2>
                      <ng-container i18n="See reason for early departure">See reason for early departure</ng-container>
                    </ng-template>

                    <div style="cursor: pointer" [ngbTooltip]="reasonForDepartureTip2" (click)="openViewEarlyDepartureModal(reasonContent, arrival)">
                      <span i18n="Early departure">Early departure</span>:&nbsp;
                      <span>
                        {{ arrival.earlyDepartureTime | date : "HH:mm" }}
                      </span>
                      <fa-icon style="color: black; margin-left: 5px" [icon]="faInfoCircle"></fa-icon>&nbsp;
                    </div>
                    <div>
                      <ng-template #warehouseManagerMarkedEarlyDepartureTip>{{ arrival.warehouseManagerMarkedEarlyDeparture.name }}</ng-template>

                      <ng-container i18n="by">by</ng-container>
                      <span [ngbTooltip]="warehouseManagerMarkedEarlyDepartureTip">
                        {{
                          arrival.warehouseManagerMarkedEarlyDepartureUserId
                            ? userUtils.renderUserId(arrival.warehouseManagerMarkedEarlyDepartureUserId)
                            : arrival.warehouseManagerMarkedEarlyDeparture.name
                        }}
                      </span>
                    </div>
                  </div>
                </td>
              </ng-container>

              <ng-container *ngIf="isArchive">
                <td *ngIf="arrival.departureTime && arrival.gateManagerMarkedDeparture">
                  <fa-icon style="color: green" [icon]="faCheck"></fa-icon>

                  <editable (update)="OnUpdateArrival(arrival)" (modeChange)="OnEditableModeChange($event)">
                    <ng-template viewMode>{{ arrival.departureTime | date : "HH:mm" }}</ng-template>
                    <ng-template editMode>
                      <input focusable editableOnEnter [(ngModel)]="arrival.arrivalTime" />
                    </ng-template>
                  </editable>
                  <ng-template>
                    <ng-container i18n="by">by</ng-container>
                    {{ arrival.gateManagerMarkedDeparture.name }}</ng-template
                  >
                </td>
                <td *ngIf="!(arrival.departureTime && arrival.gateManagerMarkedDeparture)">
                  <fa-icon style="color: red" [icon]="faTimes"></fa-icon>
                </td>
              </ng-container>

              <td>{{ arrival.driver.contract ? arrival.driver.code : "/" }}</td>

              <td>
                <editable (update)="OnUpdateArrival(arrival)" (modeChange)="OnEditableModeChange($event)">
                  <ng-template viewMode>{{ arrival.driver.name }}</ng-template>
                  <ng-template editMode>
                    <input focusable editableOnEnter [(ngModel)]="arrival.driver.name" />
                  </ng-template>
                </editable>

                <ng-template #CommentPresentTip>
                  <ng-container i18n="Comment is present. Please see details.">Comment is present. Please see details.</ng-container>
                </ng-template>

                <fa-icon
                  *ngIf="arrival.comment != null && arrival.comment.length > 0"
                  style="color: orange; margin-left: 5px; cursor: pointer"
                  [icon]="faCommentAlt"
                  [ngbTooltip]="CommentPresentTip"
                  (click)="SeeDetails(arrival)"
                ></fa-icon>
              </td>

              <td>
                <editable (update)="OnUpdateArrival(arrival)" (modeChange)="OnEditableModeChange($event)">
                  <ng-template viewMode>{{ arrival.driver.transportCompany }}</ng-template>
                  <ng-template editMode>
                    <input focusable editableOnEnter [(ngModel)]="arrival.driver.transportCompany" />
                  </ng-template>
                </editable>
              </td>

              <td>
                <editable (update)="OnUpdateArrival(arrival)" (modeChange)="OnEditableModeChange($event)">
                  <ng-template viewMode>{{ arrival.registrationNumber }}</ng-template>
                  <ng-template editMode>
                    <input focusable editableOnEnter [(ngModel)]="arrival.registrationNumber" />
                  </ng-template>
                </editable>
              </td>

              <td>
                <editable (update)="OnUpdateArrival(arrival)" (modeChange)="OnEditableModeChange($event, arrival)">
                  <ng-template viewMode>{{ arrival.driver.transportCompanyCountry.name }}</ng-template>
                  <ng-template editMode>
                    <div class="autocomplete-container">
                      <ng-autocomplete
                        [(ngModel)]="arrival.transportCompanyCountryInput"
                        [searchKeyword]="'name'"
                        (selected)="onSelectTransportCompanyCountry(arrival, $event)"
                        (inputChanged)="onSelectTransportCompanyCountry(arrival, null)"
                        (inputCleared)="onSelectTransportCompanyCountry(arrival, null)"
                        [data]="countriesService.countries"
                        [itemTemplate]="itemTemplateCountry"
                      >
                      </ng-autocomplete>

                      <ng-template #itemTemplateCountry let-item>
                        <a [innerHTML]="item.name"></a>
                      </ng-template>
                    </div>
                  </ng-template>
                </editable>
                <div *ngIf="arrival.countryCombination && arrival.status === ArrivalStatus.Arrived && !arrival.isModified" class="text-danger">
                  <fa-icon [icon]="faExclamationTriangle"></fa-icon>
                  (<a i18n="combination" target="_blank" class="text-danger" [routerLink]="['/country-combination/' + arrival.countryCombination.id]"
                    ><u>combination</u></a
                  >)
                </div>
              </td>

              <td>
                <editable (update)="OnUpdateArrival(arrival)" (modeChange)="OnEditableModeChange($event, arrival)">
                  <ng-template viewMode>{{ arrival.loadingCountry.name }}</ng-template>
                  <ng-template editMode>
                    <div class="autocomplete-container">
                      <ng-autocomplete
                        [(ngModel)]="arrival.loadingCountryInput"
                        [searchKeyword]="'name'"
                        (selected)="onSelectLoadingCountry(arrival, $event)"
                        (inputChanged)="onSelectLoadingCountry(arrival, null)"
                        (inputCleared)="onSelectLoadingCountry(arrival, null)"
                        [data]="countriesService.countries"
                        [itemTemplate]="itemTemplateCountry"
                      >
                      </ng-autocomplete>

                      <ng-template #itemTemplateCountry let-item>
                        <a [innerHTML]="item.name"></a>
                      </ng-template>
                    </div>
                  </ng-template>
                </editable>
              </td>

              <td *ngIf="arrival.driver.contract != null">
                <span [ngStyle]="{ color: contractExpiryColor(arrival.driver) }">
                  <ng-container i18n="Signed">Signed</ng-container>:
                  {{ arrival.driver.contract.dateSigned | date : "dd.MM.yyyy" }}
                </span>

                <ng-container *ngIf="!arrival.contractMarkedAsUploaded && auth.IsWarehouseManager()">
                  <ng-container *ngIf="arrival.driver.contract.hasExpired">
                    <ng-container *ngIf="arrival.status !== ArrivalStatus.EarlyDeparture && arrival.status !== ArrivalStatus.Departed">
                      <button (click)="onPrepareContractClick(arrival)" i18n class="btn btn-primary d-block btn-wrap mt-2">Update contract</button>

                      <button
                        (click)="onMarkContractSignedClick(markContractConfirmation, arrival)"
                        i18n="Mark as renewed"
                        class="btn btn-secondary d-block mt-1 btn-wrap"
                      >
                        Mark as renewed
                      </button>
                    </ng-container>

                    <ng-container *ngIf="!(arrival.status !== ArrivalStatus.EarlyDeparture && arrival.status !== ArrivalStatus.Departed)">
                      <ng-container *ngIf="!arrival.driver.contract.contractExpiresSoon">/</ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="!arrival.driver.contract.hasExpired && arrival.contractExpiresSoon">
                    <button (click)="onPrepareContractClick(arrival)" i18n class="btn btn-primary d-block btn-wrap mt-1">Update contract</button>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="arrival.contractMarkedAsUploaded">
                  <span class="line-span"><span i18n="Marked as renewed">Marked as renewed</span>.</span>
                </ng-container>
              </td>

              <td *ngIf="arrival.driver.contract == null && arrival.contractMarkedAsUploaded">
                <ng-container i18n="Marked as signed">Marked as signed</ng-container>.
              </td>

              <td *ngIf="arrival.driver.contract == null && !arrival.contractMarkedAsUploaded">
                <ng-container *ngIf="arrival.status !== ArrivalStatus.EarlyDeparture && arrival.status !== ArrivalStatus.Departed">
                  <div *ngIf="auth.IsWarehouseManager()">
                    <button (click)="onPrepareContractClick(arrival)" i18n class="btn btn-primary d-block btn-wrap">Prepare contract</button>
                    <button
                      (click)="onMarkContractSignedClick(markContractConfirmation, arrival)"
                      i18n="Mark as signed"
                      class="btn btn-secondary d-block btn-wrap mt-1"
                    >
                      Mark as signed
                    </button>
                  </div>
                  <ng-container *ngIf="!auth.IsWarehouseManager()"
                    ><span style="color: red"><ng-container i18n="Not signed yet">Not signed yet</ng-container>.</span></ng-container
                  >
                </ng-container>
                <ng-container *ngIf="arrival.status === ArrivalStatus.EarlyDeparture || arrival.status === ArrivalStatus.Departed"> / </ng-container>
              </td>

              <!-- STATUS DESKTOP -->
              <td>
                <div [ngSwitch]="arrival.status">
                  <span *ngSwitchCase="ArrivalStatus.Arrived" i18n="Arrival">Arrival</span>
                  <span *ngSwitchCase="ArrivalStatus.AtRamp">
                    <ng-container i18n="At ramp">At ramp</ng-container>
                    <span class="ramp-span">({{ arrival.currentRamp.name }} {{ arrival.warehouse.suffix }})</span>
                  </span>

                  <span *ngSwitchCase="ArrivalStatus.UnloadingStarted">
                    <ng-container i18n="Unloading start">Unloading start</ng-container>
                    <span *ngIf="arrival.currentRamp" class="ramp-span">({{ arrival.currentRamp.name }} {{ arrival.warehouse.suffix }})</span>
                  </span>

                  <span *ngSwitchCase="ArrivalStatus.UnloadingDone">
                    <ng-container i18n="Unloading end">Unloading done</ng-container>
                    <span *ngIf="arrival.arrivalsOnRamp && arrival.arrivalsOnRamp.length > 0" class="ramp-span"
                      >({{ arrival.arrivalsOnRamp[arrival.arrivalsOnRamp.length - 1].ramp.name }} {{ arrival.warehouse.suffix }})</span
                    >
                  </span>

                  <span *ngSwitchCase="ArrivalStatus.OnHold">
                    <ng-container i18n="On hold">On hold</ng-container>
                    <span *ngIf="arrival.arrivalsOnRamp && arrival.arrivalsOnRamp.length > 0" class="ramp-span"
                      >({{ arrival.arrivalsOnRamp[arrival.arrivalsOnRamp.length - 1].ramp.name }} {{ arrival.warehouse.suffix }})</span
                    >
                  </span>

                  <span *ngSwitchCase="ArrivalStatus.OnHoldEnd">
                    <ng-container i18n="Issue resolved">Issue resolved</ng-container>
                    <span *ngIf="arrival.arrivalsOnRamp && arrival.arrivalsOnRamp.length > 0" class="ramp-span"
                      >({{ arrival.arrivalsOnRamp[arrival.arrivalsOnRamp.length - 1].ramp.name }} {{ arrival.warehouse.suffix }})</span
                    >
                  </span>

                  <span *ngSwitchCase="ArrivalStatus.ReturnedToStart">
                    <ng-container i18n="Returned to start">Returned to start</ng-container>
                    <span *ngIf="arrival.arrivalsOnRamp && arrival.arrivalsOnRamp.length > 0" class="ramp-span"
                      >({{ arrival.arrivalsOnRamp[arrival.arrivalsOnRamp.length - 1].ramp.name }} {{ arrival.warehouse.suffix }})</span
                    >
                  </span>

                  <span *ngSwitchCase="ArrivalStatus.Departed" i18n="Departure">Departure</span>

                  <ng-container *ngSwitchCase="ArrivalStatus.EarlyDeparture">
                    <ng-template #reasonForDepartureTip>
                      <ng-container i18n="See reason for early departure">See reason for early departure</ng-container>
                    </ng-template>

                    <div style="cursor: pointer" [ngbTooltip]="reasonForDepartureTip" (click)="openViewEarlyDepartureModal(reasonContent, arrival)">
                      <span i18n="Early departure">Early departure</span>
                      <fa-icon style="color: black; margin-left: 5px" [icon]="faInfoCircle"></fa-icon>&nbsp;
                    </div>
                  </ng-container>
                </div>
              </td>

              <td *ngIf="isArchive">
                <ng-container *ngIf="arrival.arrivalsOnRamp.length > 0">
                  <ng-container *ngFor="let ar of arrival.arrivalsOnRamp">
                    <span class="ramp-span-archive">{{ ar.ramp.name }} {{ arrival.warehouse.suffix }}</span>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="arrival.arrivalsOnRamp.length === 0"> / </ng-container>
              </td>

              <td class="buttons-td" *ngIf="!isArchive">
                <select
                  class="form-control"
                  *ngIf="arrival.currentRamp == null && auth.IsWarehouseManager() && selectedTab !== -2 && arrival.status !== ArrivalStatus.EarlyDeparture"
                  (change)="onRampSelectChange($event.target.value, arrival)"
                >
                  <option selected value>/</option>
                  <ng-container *ngIf="freeRampsPerWarehouse[arrival.warehouse.id] && freeRampsPerWarehouse[arrival.warehouse.id].length > 0">
                    <option *ngFor="let ramp of freeRampsPerWarehouse[arrival.warehouse.id]" [value]="ramp.id">
                      {{ ramp.name }}
                      {{ ramp.warehousePart.warehouse.suffix || "" }}
                    </option>
                  </ng-container>
                  <option *ngIf="!freeRampsPerWarehouse[arrival.warehouse.id] || freeRampsPerWarehouse[arrival.warehouse.id].length === 0" disabled>
                    <ng-container i18n="No free ramps">None</ng-container>
                  </option>
                </select>
                <span
                  *ngIf="arrival.currentRamp"
                  [ngClass]="{
                    'text-danger': arrival.status === ArrivalStatus.OnHold
                  }"
                  >{{ arrival.currentRamp.name }} {{ arrival.warehouse.suffix }}</span
                >
                <ng-container
                  *ngIf="!arrival.currentRamp && (!auth.IsWarehouseManager() || selectedTab === -2 || arrival.status === ArrivalStatus.EarlyDeparture)"
                  >/</ng-container
                >
              </td>

              <td>
                {{ arrival.warehouse.name }}<br />
                <ng-container *ngIf="canChangeWarehouse(arrival)"
                  >(<span i18n style="text-decoration: underline; cursor: pointer" (click)="openChangeWarehouseModal(changeWarehouseContent, arrival)"
                    >change</span
                  >)</ng-container
                >
              </td>

              <td class="buttons-td">
                <button type="button" (click)="applyRampSelect(arrival)" *ngIf="arrival.selectedRamp" class="btn btn-primary d-block mb-1 btn-wrap">
                  <ng-container i18n="Select ramp">Select ramp</ng-container>
                </button>

                <button
                  type="button"
                  (click)="openEarlyDepartureModal(content, arrival)"
                  *ngIf="arrivalUtils.earlyDepartureAllowed(arrival) && selectedTab !== -2"
                  class="btn btn-warning d-block mb-1 btn-wrap"
                  i18n="Early departure"
                >
                  Early departure
                </button>

                <button
                  type="button"
                  (click)="onProcessButtonClick(arrival.id)"
                  *ngIf="arrivalUtils.processingAllowed(arrival) && selectedTab !== -2 && arrival.status != ArrivalStatus.OnHold"
                  class="btn btn-primary d-block mb-1 btn-wrap"
                >
                  <ng-container>
                    <ng-container *ngIf="arrival.status === ArrivalStatus.Arrived || arrival.status === ArrivalStatus.AtRamp" i18n="Unloading start"
                      >Unloading start</ng-container
                    >
                    <ng-container *ngIf="arrival.status === ArrivalStatus.UnloadingStarted || arrival.status === ArrivalStatus.OnHoldEnd" i18n="Unloading done"
                      >Unloading done</ng-container
                    >
                    <ng-container
                      *ngIf="
                        arrival.status === ArrivalStatus.UnloadingDone ||
                        arrival.status === ArrivalStatus.ReturnedToStart ||
                        arrival.status === ArrivalStatus.EarlyDeparture
                      "
                      i18n="Departure"
                      >Departure</ng-container
                    >
                    <span *ngIf="arrival.currentRamp">&nbsp;({{ arrival.currentRamp.name }} {{ arrival.warehouse.suffix }})</span>
                  </ng-container>
                  <br />
                </button>
                <ng-container
                  *ngIf="
                    arrivalUtils.processingAllowed(arrival) &&
                    selectedTab !== -2 &&
                    (arrival.status == ArrivalStatus.UnloadingStarted || arrival.status == ArrivalStatus.OnHold)
                  "
                >
                  <button
                    type="button"
                    (click)="onHoldButtonClick(arrival.id)"
                    *ngIf="
                      arrivalUtils.processingAllowed(arrival) &&
                      selectedTab !== -2 &&
                      (arrival.status == ArrivalStatus.UnloadingStarted || arrival.status == ArrivalStatus.OnHold)
                    "
                    [ngClass]="{
                      'btn-danger': arrival.status == ArrivalStatus.UnloadingStarted,
                      'btn-success': arrival.status == ArrivalStatus.OnHold
                    }"
                    class="btn d-block mb-1 btn-wrap"
                  >
                    <ng-container>
                      <ng-container [ngSwitch]="arrival.status">
                        <ng-container *ngSwitchCase="ArrivalStatus.UnloadingStarted" i18n="On hold">On hold</ng-container>
                        <ng-container *ngSwitchCase="ArrivalStatus.OnHold" i18n="Issue resolved">Issue resolved</ng-container>
                      </ng-container>
                    </ng-container>
                    <br />
                  </button>

                  <button
                    type="button"
                    (click)="onReturnedToStartClick(arrival.id)"
                    class="btn btn-warning d-block mb-1 btn-wrap"
                    *ngIf="arrival.status === ArrivalStatus.OnHold"
                  >
                    <ng-container i18n="Return to start">Return to start</ng-container>
                    <br />
                  </button>
                </ng-container>

                <button
                  type="button"
                  (click)="onUndoButtonClick(arrival)"
                  *ngIf="arrivalUtils.undoAllowed(arrival) && selectedTab === -2"
                  class="btn btn-warning d-block mb-1 btn-wrap"
                >
                  <ng-container>
                    <ng-container i18n="Undo">Undo</ng-container>:
                    <ng-container [ngSwitch]="arrival.status">
                      <ng-container *ngSwitchCase="ArrivalStatus.AtRamp">
                        <ng-container i18n="Select ramp">Select ramp</ng-container>
                        <ng-container *ngIf="arrival.currentRamp">&nbsp;({{ arrival.currentRamp.name }} {{ arrival.warehouse.suffix }})</ng-container>
                      </ng-container>

                      <ng-container *ngSwitchCase="ArrivalStatus.UnloadingStarted">
                        <ng-container i18n="Unloading start">Unloading start</ng-container>
                        <ng-container *ngIf="arrival.currentRamp">&nbsp;({{ arrival.currentRamp.name }} {{ arrival.warehouse.suffix }})</ng-container>
                      </ng-container>

                      <ng-container *ngSwitchCase="ArrivalStatus.OnHold">
                        <ng-container i18n="On hold">On hold</ng-container>
                        <ng-container *ngIf="arrival.currentRamp">&nbsp;({{ arrival.currentRamp.name }} {{ arrival.warehouse.suffix }})</ng-container>
                      </ng-container>

                      <ng-container *ngSwitchCase="ArrivalStatus.OnHoldEnd">
                        <ng-container i18n="Issue resolved">Issue resolved</ng-container>
                        <ng-container *ngIf="arrival.currentRamp">&nbsp;({{ arrival.currentRamp.name }} {{ arrival.warehouse.suffix }})</ng-container>
                      </ng-container>

                      <ng-container *ngSwitchCase="ArrivalStatus.ReturnedToStart">
                        <ng-container i18n="Return to start">Return to start</ng-container>
                      </ng-container>

                      <ng-container *ngSwitchCase="ArrivalStatus.UnloadingDone">
                        <ng-container i18n="Unloading done">Unloading done</ng-container>
                        <ng-container *ngIf="arrival.arrivalsOnRamp && arrival.arrivalsOnRamp.length > 0"
                          >&nbsp;({{ arrival.arrivalsOnRamp[arrival.arrivalsOnRamp.length - 1].ramp.name }} {{ arrival.warehouse.suffix }})</ng-container
                        >
                      </ng-container>

                      <ng-container *ngSwitchCase="ArrivalStatus.Departed" i18n="Departure">Departure</ng-container>

                      <ng-container *ngSwitchCase="ArrivalStatus.EarlyDeparture" i18n="Early departure">Early departure</ng-container>
                    </ng-container>
                  </ng-container>
                  <br />
                </button>
                <ng-container *ngIf="arrival.isModified">
                  <button type="button" (click)="ApplyChanges(arrival)" class="btn btn-primary d-block mb-1 btn-wrap">
                    <ng-container i18n="Apply changes">Apply changes</ng-container>
                  </button>
                  <button type="button" (click)="UndoChanges(arrival)" class="btn btn-primary d-block mb-1 btn-wrap">
                    <ng-container i18n="Undo changes">Undo changes</ng-container>
                  </button>
                </ng-container>
                <ng-container
                  *ngIf="
                    !(
                      arrival.selectedRamp ||
                      (arrivalUtils.earlyDepartureAllowed(arrival) && selectedTab !== -2) ||
                      (arrivalUtils.processingAllowed(arrival) && selectedTab !== -2) ||
                      (arrivalUtils.undoAllowed(arrival) && selectedTab === -2) ||
                      arrival.isModified
                    )
                  "
                  >/</ng-container
                >
              </td>

              <td>
                <button (click)="SeeDetails(arrival)" i18n="Details" class="btn btn-primary d-block btn-wrap">Details</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div *ngIf="renderingService.isMobile() || renderingService.isTablet()">
        <div
          class="card mb-4"
          style="width: 18rem"
          #arrivalTr
          *ngFor="
            let arrival of isArchive ? filteredArrivals : (filteredArrivals | slice : (pageNumber - 1) * pageSize : (pageNumber - 1) * pageSize + pageSize);
            let arrivalIndex = index
          "
        >
          <div class="card-body">
            <h5 class="card-title">{{ arrival.driver.name }} ({{ arrival.driver.transportCompany }} - {{ arrival.registrationNumber }})</h5>
            <h6 class="card-subtitle mb-2 text-muted">
              {{ arrival.arrivalTime | date : "dd.MM.yyyy" }}
            </h6>
            <p class="card-text">
              <ng-container i18n="Status">Status</ng-container>:

              <ng-container [ngSwitch]="arrival.status">
                <span *ngSwitchCase="ArrivalStatus.Arrived" i18n="Arrival">Arrival</span>
                <span *ngSwitchCase="ArrivalStatus.AtRamp">
                  <ng-container i18n="At ramp">At ramp</ng-container>
                  <span class="ramp-span">({{ arrival.currentRamp.name }} {{ arrival.warehouse.suffix }})</span>
                </span>

                <span *ngSwitchCase="ArrivalStatus.UnloadingStarted">
                  <ng-container i18n="Unloading start">Unloading start</ng-container>
                  <span *ngIf="arrival.currentRamp" class="ramp-span">({{ arrival.currentRamp.name }} {{ arrival.warehouse.suffix }})</span>
                </span>

                <span *ngSwitchCase="ArrivalStatus.UnloadingDone">
                  <ng-container i18n="Unloading end">Unloading done</ng-container>
                  <span *ngIf="arrival.arrivalsOnRamp && arrival.arrivalsOnRamp.length > 0" class="ramp-span"
                    >({{ arrival.arrivalsOnRamp[arrival.arrivalsOnRamp.length - 1].ramp.name }} {{ arrival.warehouse.suffix }})</span
                  >
                </span>

                <span *ngSwitchCase="ArrivalStatus.OnHold">
                  <ng-container i18n="On hold">On hold</ng-container>
                  <span *ngIf="arrival.arrivalsOnRamp && arrival.arrivalsOnRamp.length > 0" class="ramp-span"
                    >({{ arrival.arrivalsOnRamp[arrival.arrivalsOnRamp.length - 1].ramp.name }} {{ arrival.warehouse.suffix }})</span
                  >
                </span>

                <span *ngSwitchCase="ArrivalStatus.OnHoldEnd">
                  <ng-container i18n="Issue resolved">Issue resolved</ng-container>
                  <span *ngIf="arrival.arrivalsOnRamp && arrival.arrivalsOnRamp.length > 0" class="ramp-span"
                    >({{ arrival.arrivalsOnRamp[arrival.arrivalsOnRamp.length - 1].ramp.name }} {{ arrival.warehouse.suffix }})</span
                  >
                </span>

                <span *ngSwitchCase="ArrivalStatus.ReturnedToStart">
                  <ng-container i18n="Returned to start">Returned to start</ng-container>
                  <span *ngIf="arrival.arrivalsOnRamp && arrival.arrivalsOnRamp.length > 0" class="ramp-span"
                    >({{ arrival.arrivalsOnRamp[arrival.arrivalsOnRamp.length - 1].ramp.name }} {{ arrival.warehouse.suffix }})</span
                  >
                </span>

                <span *ngSwitchCase="ArrivalStatus.Departed" i18n="Departure">Departure</span>

                <div *ngSwitchCase="ArrivalStatus.EarlyDeparture" style="cursor: pointer" (click)="openViewEarlyDepartureModal(reasonContent, arrival)">
                  <span i18n="Early departure">Early departure</span>
                  <fa-icon style="color: black; margin-left: 5px" [icon]="faInfoCircle"></fa-icon>&nbsp;
                </div>
              </ng-container>
            </p>
            <p class="card-text d-flex align-items-center">
              <ng-container i18n="Ramp">Ramp</ng-container>:

              <ng-container *ngIf="arrival.currentRamp"
                ><span class="ramp-span">{{ arrival.currentRamp.name }} {{ arrival.warehouse.suffix }}</span></ng-container
              >

              <ng-container *ngIf="!isArchive">
                <ng-container
                  *ngIf="arrival.currentRamp == null && auth.IsWarehouseManager() && selectedTab !== -2 && arrival.status !== ArrivalStatus.EarlyDeparture"
                >
                  <select class="form-control ml-2" style="width: auto" (change)="onRampSelectChange($event.target.value, arrival)">
                    <option selected value>/</option>
                    <ng-container *ngIf="freeRampsPerWarehouse[arrival.warehouse.id] && freeRampsPerWarehouse[arrival.warehouse.id].length > 0">
                      <option *ngFor="let ramp of freeRampsPerWarehouse[arrival.warehouse.id]" [value]="ramp.id">
                        {{ ramp.name }}
                        {{ ramp.warehousePart.warehouse.suffix || "" }}
                      </option>
                    </ng-container>
                    <option *ngIf="!freeRampsPerWarehouse[arrival.warehouse.id] || freeRampsPerWarehouse[arrival.warehouse.id].length === 0" disabled>
                      <ng-container i18n="No free ramps">None</ng-container>
                    </option>
                  </select>
                  <button type="button" (click)="applyRampSelect(arrival)" *ngIf="arrival.selectedRamp" class="btn btn-primary d-block ml-2 btn-wrap">
                    <ng-container i18n="Select ramp">Select ramp</ng-container>
                  </button>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="isArchive">
                <ng-container *ngFor="let ar of arrival.arrivalsOnRamp; let i = index">
                  <span>{{ ar.ramp.name }} {{ arrival.warehouse.suffix }}</span
                  ><ng-container *ngIf="i < arrival.arrivalsOnRamp.length - 1">,</ng-container>
                </ng-container>
              </ng-container>

              <ng-container
                *ngIf="
                  !(arrival.currentRamp == null && auth.IsWarehouseManager() && selectedTab !== -2 && arrival.status !== ArrivalStatus.EarlyDeparture) &&
                  arrival.currentRamp == null
                "
                >/</ng-container
              >
            </p>

            <p>
              <ng-container i18n="Warehouse">Warehouse</ng-container>:
              {{ arrival.warehouse.name }}
              <ng-container *ngIf="canChangeWarehouse(arrival)"
                >(<span i18n style="text-decoration: underline; cursor: pointer" (click)="openChangeWarehouseModal(changeWarehouseContent, arrival)"
                  >change</span
                >)</ng-container
              >
            </p>

            <p>
              <ng-container i18n="Driver ID">Driver ID</ng-container>:
              {{ arrival.driver.contract ? arrival.driver.code : "/" }}
              <fa-icon
                *ngIf="arrival.comment != null && arrival.comment.length > 0"
                style="color: orange; margin-left: 5px; cursor: pointer"
                [icon]="faCommentAlt"
                (click)="SeeDetails(arrival)"
              ></fa-icon>
            </p>

            <span>
              <ng-container i18n="Arrival">Arrival</ng-container>: <fa-icon style="color: green" [icon]="faCheck"></fa-icon>&nbsp;
              {{ arrival.arrivalTime | date : "HH:mm" }}
              <ng-container i18n="by">by</ng-container>
              {{ arrival.gateManagerMarkedArrival.name }}<br />
            </span>

            <span
              (click)="openViewEarlyDepartureModal(reasonContent, arrival)"
              style="cursor: pointer"
              *ngIf="arrival.earlyDepartureTime != null && arrival.warehouseManagerMarkedEarlyDeparture != null"
            >
              <ng-container i18n="Arrival">Early departure</ng-container>: <fa-icon style="color: black" [icon]="faCheck"></fa-icon>&nbsp;
              {{ arrival.earlyDepartureTime | date : "HH:mm" }}
              <ng-container i18n="by">by</ng-container>
              <span>
                {{
                  arrival.warehouseManagerMarkedEarlyDepartureUserId
                    ? userUtils.renderUserId(arrival.warehouseManagerMarkedEarlyDepartureUserId)
                    : arrival.warehouseManagerMarkedEarlyDeparture.name
                }}
              </span>

              <fa-icon style="color: black; margin-left: 5px" [icon]="faInfoCircle"></fa-icon>&nbsp;
              <br />
            </span>

            <span *ngIf="arrival.departureTime && arrival.gateManagerMarkedDeparture">
              <ng-container i18n="Departure">Departure</ng-container>: <fa-icon style="color: green" [icon]="faCheck"></fa-icon>&nbsp;
              {{ arrival.departureTime | date : "HH:mm" }}
              <ng-container i18n="by">by</ng-container>
              {{ arrival.gateManagerMarkedDeparture.name }}<br />
            </span>

            <br />

            <ng-container *ngIf="arrival.arrivalsOnRamp">
              <ng-container *ngFor="let ar of arrival.arrivalsOnRamp">
                <span class="line-span"
                  ><ng-container i18n="Ramp">Ramp</ng-container> <span class="ramp-span">{{ ar.ramp.name }} {{ arrival.warehouse.suffix }}</span></span
                >

                <span i18n="Unloading start">Unloading start</span>:&nbsp;

                <span *ngIf="ar.unloadingStartTime && ar.warehouseManagerMarkedUnloadingStart">
                  <fa-icon style="color: green" [icon]="faCheck"></fa-icon>&nbsp; {{ ar.unloadingStartTime | date : "HH:mm" }}
                  <ng-container i18n="by">by</ng-container>
                  <span>
                    {{
                      ar.warehouseManagerMarkedUnloadingStartUserId
                        ? userUtils.renderUserId(ar.warehouseManagerMarkedUnloadingStartUserId)
                        : ar.warehouseManagerMarkedUnloadingStart.name
                    }}
                  </span>
                </span>

                <span *ngIf="!(ar.unloadingStartTime && ar.warehouseManagerMarkedUnloadingStart)">
                  <fa-icon style="color: red" [icon]="faTimes"></fa-icon>
                </span>

                <br />

                <ng-container *ngIf="ar.onHoldTime && ar.warehouseManagerMarkedOnHold">
                  <span i18n="On hold">On hold</span>:&nbsp;

                  <span>
                    <fa-icon style="color: red" [icon]="faExclamationTriangle"></fa-icon>&nbsp; {{ ar.onHoldTime | date : "HH:mm" }}
                    <ng-container i18n="by">by</ng-container>
                    <span>
                      {{
                        ar.warehouseManagerMarkedOnHoldUserId
                          ? userUtils.renderUserId(ar.warehouseManagerMarkedOnHoldUserId)
                          : ar.warehouseManagerMarkedOnHold.name
                      }}
                    </span>
                  </span>

                  <br />
                </ng-container>

                <ng-container *ngIf="ar.onHoldEndTime && ar.warehouseManagerMarkedOnHoldEnd">
                  <span i18n="Issue resolved">Issue resolved</span>:&nbsp;

                  <span>
                    <fa-icon style="color: green" [icon]="faExclamationTriangle"></fa-icon>&nbsp; {{ ar.onHoldEndTime | date : "HH:mm" }}
                    <ng-container i18n="by">by</ng-container>
                    <span>
                      {{
                        ar.warehouseManagerMarkedOnHoldEndUserId
                          ? userUtils.renderUserId(ar.warehouseManagerMarkedOnHoldEndUserId)
                          : ar.warehouseManagerMarkedOnHoldEnd.name
                      }}
                    </span>
                  </span>

                  <br />
                </ng-container>

                <ng-container *ngIf="ar.returnedToStartTime && ar.warehouseManagerMarkedReturnedToStart">
                  <span i18n="Returned to start">Returned to start</span>:&nbsp;

                  <span>
                    <fa-icon style="color: orange" [icon]="faUndo"></fa-icon>&nbsp; {{ ar.returnedToStartTime | date : "HH:mm" }}
                    <ng-container i18n="by">by</ng-container>
                    <span>
                      {{
                        ar.warehouseManagerMarkedReturnedToStartUserId
                          ? userUtils.renderUserId(ar.warehouseManagerMarkedReturnedToStartUserId)
                          : ar.warehouseManagerMarkedReturnedToStart.name
                      }}
                    </span>
                  </span>

                  <br />
                </ng-container>

                <ng-container *ngIf="!(ar.returnedToStartTime && ar.warehouseManagerMarkedReturnedToStart)">
                  <span i18n="Unloading end">Unloading end</span>:&nbsp;

                  <span *ngIf="ar.unloadingEndTime && ar.warehouseManagerMarkedUnloadingEnd">
                    <fa-icon style="color: green" [icon]="faCheck"></fa-icon>&nbsp; {{ ar.unloadingEndTime | date : "HH:mm" }}
                    <ng-container i18n="by">by</ng-container>
                    <span>
                      {{
                        ar.warehouseManagerMarkedUnloadingEndUserId
                          ? userUtils.renderUserId(ar.warehouseManagerMarkedUnloadingEndUserId)
                          : ar.warehouseManagerMarkedUnloadingEnd.name
                      }}
                    </span>
                  </span>

                  <span *ngIf="!(ar.unloadingEndTime && ar.warehouseManagerMarkedUnloadingEnd)">
                    <fa-icon style="color: red" [icon]="faTimes"></fa-icon>
                  </span>

                  <br />
                </ng-container>

                <br />
              </ng-container>
            </ng-container>

            <p><ng-container i18n="Registration number">Registration number</ng-container>: {{ arrival.registrationNumber }}</p>

            <span
              ><ng-container i18n="Transport company country">Transport company country</ng-container>: {{ arrival.driver.transportCompanyCountry.name }}

              <span *ngIf="arrival.countryCombinationId && arrival.status === ArrivalStatus.Arrived && !arrival.isModified" class="text-danger">
                <fa-icon [icon]="faExclamationTriangle"></fa-icon>
                (<a i18n="combination" target="_blank" class="text-danger" [routerLink]="['/country-combination/' + arrival.countryCombinationId]"
                  ><u>combination</u></a
                >)
              </span> </span
            ><br />
            <span><ng-container i18n="Loading country">Loading country</ng-container>: {{ arrival.loadingCountry.name }}</span>

            <br />

            <span
              ><ng-container i18n="Contract">Contract</ng-container>:
              <ng-container *ngIf="arrival.driver.contract">
                <span [ngStyle]="{ color: contractExpiryColor(arrival.driver) }"
                  ><ng-container i18n="Signed on">Signed on </ng-container> {{ arrival.driver.contract.dateSigned | date : "dd.MM.yyyy" }}</span
                >
                <ng-container *ngIf="arrival.contractMarkedAsUploaded">
                  <span>, <ng-container i18n="marked as signed">marked as signed</ng-container></span>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!arrival.driver.contract && arrival.contractMarkedAsUploaded">
                <span i18n="Marked as signed">Marked as signed</span>
              </ng-container>
              <ng-container *ngIf="arrival.driver.contract == null && !arrival.contractMarkedAsUploaded">
                <span><ng-container class="font-weight-bold" i18n="No contract yet">No contract yet</ng-container></span>
              </ng-container>
            </span>

            <br />
            <br />

            <button (click)="SeeDetails(arrival)" i18n="Details" class="btn btn-primary d-block mb-2 btn-wrap">Details</button>

            <button
              type="button"
              (click)="openEarlyDepartureModal(content, arrival)"
              *ngIf="arrivalUtils.earlyDepartureAllowed(arrival) && selectedTab !== -2"
              class="btn btn-warning d-block mb-1 btn-wrap"
              i18n="Early departure"
            >
              Early departure
            </button>

            <button
              type="button"
              (click)="onProcessButtonClick(arrival.id)"
              *ngIf="arrivalUtils.processingAllowed(arrival) && selectedTab !== -2 && arrival.status != ArrivalStatus.OnHold"
              class="btn btn-primary d-block mb-1 btn-wrap"
            >
              <ng-container>
                <ng-container *ngIf="arrival.status === ArrivalStatus.Arrived || arrival.status === ArrivalStatus.AtRamp" i18n="Unloading start"
                  >Unloading start</ng-container
                >
                <ng-container *ngIf="arrival.status === ArrivalStatus.UnloadingStarted || arrival.status === ArrivalStatus.OnHoldEnd" i18n="Unloading done"
                  >Unloading done</ng-container
                >
                <ng-container
                  *ngIf="
                    arrival.status === ArrivalStatus.UnloadingDone ||
                    arrival.status === ArrivalStatus.ReturnedToStart ||
                    arrival.status === ArrivalStatus.EarlyDeparture
                  "
                  i18n="Departure"
                  >Departure</ng-container
                >
                <span *ngIf="arrival.currentRamp">&nbsp;({{ arrival.currentRamp.name }} {{ arrival.warehouse.suffix }})</span>
              </ng-container>
            </button>

            <ng-container
              *ngIf="
                arrivalUtils.processingAllowed(arrival) &&
                selectedTab !== -2 &&
                (arrival.status == ArrivalStatus.UnloadingStarted || arrival.status == ArrivalStatus.OnHold)
              "
            >
              <button
                type="button"
                (click)="onHoldButtonClick(arrival.id)"
                *ngIf="
                  arrivalUtils.processingAllowed(arrival) &&
                  selectedTab !== -2 &&
                  (arrival.status == ArrivalStatus.UnloadingStarted || arrival.status == ArrivalStatus.OnHold)
                "
                [ngClass]="{
                  'btn-danger': arrival.status == ArrivalStatus.UnloadingStarted,
                  'btn-success': arrival.status == ArrivalStatus.OnHold
                }"
                class="btn d-block mb-1 btn-wrap"
              >
                <ng-container>
                  <ng-container [ngSwitch]="arrival.status">
                    <ng-container *ngSwitchCase="ArrivalStatus.UnloadingStarted" i18n="On hold">On hold</ng-container>
                    <ng-container *ngSwitchCase="ArrivalStatus.OnHold" i18n="Issue resolved">Issue resolved</ng-container>
                  </ng-container>
                </ng-container>
                <br />
              </button>

              <button
                type="button"
                (click)="onReturnedToStartClick(arrival.id)"
                class="btn btn-warning d-block mb-1 btn-wrap"
                *ngIf="arrival.status === ArrivalStatus.OnHold"
              >
                <ng-container i18n="Return to start">Return to start</ng-container>
                <br />
              </button>
            </ng-container>

            <button
              type="button"
              (click)="onUndoButtonClick(arrival)"
              *ngIf="arrivalUtils.undoAllowed(arrival) && selectedTab === -2"
              class="btn btn-warning d-block mb-1 btn-wrap"
            >
              <ng-container>
                <ng-container i18n="Undo">Undo</ng-container>:
                <ng-container [ngSwitch]="arrival.status">
                  <ng-container *ngSwitchCase="ArrivalStatus.AtRamp" i18n="Select ramp">Select ramp</ng-container>
                  <ng-container *ngSwitchCase="ArrivalStatus.UnloadingStarted" i18n="Unloading start">Unloading start</ng-container>
                  <ng-container *ngSwitchCase="ArrivalStatus.OnHold" i18n="On hold">On hold</ng-container>
                  <ng-container *ngSwitchCase="ArrivalStatus.OnHoldEnd" i18n="Issue resolved">Issue resolved</ng-container>
                  <ng-container *ngSwitchCase="ArrivalStatus.UnloadingDone" i18n="Unloading done">Unloading done</ng-container>
                  <ng-container *ngSwitchCase="ArrivalStatus.ReturnedToStart" i18n="Return to start">Return to start</ng-container>
                  <ng-container *ngSwitchCase="ArrivalStatus.Departed" i18n="Departure">Departure</ng-container>
                  <ng-container *ngSwitchCase="ArrivalStatus.EarlyDeparture" i18n="Early departure">Early departure</ng-container>
                </ng-container>
              </ng-container>
              <br />
            </button>

            <ng-container
              *ngIf="
                arrival.status !== ArrivalStatus.EarlyDeparture &&
                arrival.status !== ArrivalStatus.Departed &&
                auth.IsWarehouseManager() &&
                !arrival.contractMarkedAsUploaded
              "
            >
              <ng-container *ngIf="arrival.driver.contract == null && !arrival.contractMarkedAsUploaded">
                <ng-container>
                  <button (click)="onPrepareContractClick(arrival)" i18n class="btn btn-primary d-block btn-wrap mt-2">Prepare contract</button>

                  <button
                    (click)="onMarkContractSignedClick(markContractConfirmation, arrival)"
                    i18n="Mark contract as signed"
                    class="btn btn-secondary d-block mt-1 btn-wrap"
                  >
                    Mark contract as signed
                  </button>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="arrival.driver.contract != null && arrival.driver.contract.hasExpired">
                <button (click)="onPrepareContractClick(arrival)" i18n class="btn btn-primary d-block btn-wrap mt-2">Update contract</button>

                <button
                  (click)="onMarkContractSignedClick(markContractConfirmation, arrival)"
                  i18n="Mark contract as signed"
                  class="btn btn-secondary d-block mt-1 btn-wrap"
                >
                  Mark contract as signed
                </button>
              </ng-container>

              <ng-container *ngIf="arrival.driver.contract != null && !arrival.driver.contract.hasExpired && arrival.contractExpiresSoon">
                <button (click)="onPrepareContractClick(arrival)" i18n class="btn btn-secondary d-block btn-wrap mt-1">Update contract</button>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>

    <ngb-pagination *ngIf="!isArchive" [(page)]="pageNumber" [pageSize]="pageSize" [collectionSize]="filteredArrivals.length" [maxSize]="8"></ngb-pagination>

    <ngb-pagination
      *ngIf="isArchive"
      [collectionSize]="archivePageCount * archivePageSize"
      [size]="archivePageCount"
      [pageSize]="archivePageSize"
      [(page)]="archivePageNumber"
      [rotate]="true"
      [maxSize]="8"
      (pageChange)="loadPage($event)"
    ></ngb-pagination>
  </ng-container>

  <ng-template #content let-modal>
    <ng-container *ngIf="arrivalToMarkEarlyDeparture">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" i18n="Mark early departure">Mark early departure</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss(false)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="form-group">
          <label for="reason"><ng-container i18n="Reason">Reason</ng-container></label>
          <textarea
            id="reason"
            placeholder="Enter reason"
            i18n-placeholder
            class="md-textarea form-control"
            rows="3"
            [(ngModel)]="arrivalToMarkEarlyDeparture.earlyDepartureReason"
            [ngModelOptions]="{ standalone: true }"
          ></textarea>
        </div>
      </div>

      <div class="modal-footer d-flex justify-content-between">
        <button type="submit" class="btn" (click)="modal.close(false)">
          <ng-container i18n="Cancel">Cancel</ng-container>
        </button>
        <button
          type="submit"
          class="btn btn-warning"
          (click)="onEarlyDepartureClick(arrivalToMarkEarlyDeparture)"
          [disabled]="!arrivalToMarkEarlyDeparture.earlyDepartureReason || arrivalToMarkEarlyDeparture.earlyDepartureReason.length === 0"
        >
          <ng-container i18n="Mark early departure"> Mark early departure </ng-container>
        </button>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #reasonContent let-reasonModal>
    <ng-container *ngIf="arrivalToViewEarlyDeparture">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" i18n="Early departure">Early departure</h4>
        <button type="button" class="close" aria-label="Close" (click)="reasonModal.dismiss(false)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="form-group">
          <label for="reason" style="font-weight: bold"><ng-container i18n="Reason">Reason</ng-container></label>
          <div>
            <span style="white-space: pre-line">
              {{ arrivalToViewEarlyDeparture.earlyDepartureReason }}
            </span>
          </div>
        </div>
      </div>

      <div class="modal-footer d-flex justify-content-between">
        <button type="submit" class="btn" (click)="reasonModal.close(false)">
          <ng-container i18n="Close">Close</ng-container>
        </button>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #markContractConfirmation let-markContractConfirmationModal>
    <ng-container *ngIf="arrivalToBeMarkedAsSigned">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" i18n>Mark contract for driver {{ arrivalToBeMarkedAsSigned.driver.name }}?</h4>
        <button type="button" class="close" aria-label="Close" (click)="markContractConfirmationModal.dismiss(false)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <p>
          Do you really want to mark the contract for driver
          {{ arrivalToBeMarkedAsSigned.driver.name }} as signed / renewed?
        </p>
      </div>

      <div class="modal-footer d-flex justify-content-between">
        <button type="submit" class="btn" (click)="markContractConfirmationModal.close(false)">
          <ng-container i18n="Close">Close</ng-container>
        </button>
        <button type="submit" class="btn btn-primary" (click)="markContractAsSigned()">
          <ng-container i18n>Mark</ng-container>
        </button>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #changeWarehouseContent let-changeWarehouseModal>
    <ng-container *ngIf="arrivalToChangeWarehouse && changeWarehouseId">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" i18n="Change warehouse">Change warehouse</h4>
        <button type="button" class="close" aria-label="Close" (click)="changeWarehouseModal.dismiss(false)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="form-group">
          <label for="type"><ng-container i18n="Warehouse">Warehouse</ng-container></label>
          <select [(ngModel)]="changeWarehouseId" id="type" name="type" class="form-control">
            <option [value]="w.id" *ngFor="let w of warehouses">
              <ng-container>{{ w.name }}</ng-container>
            </option>
          </select>
        </div>
      </div>

      <div class="modal-footer d-flex justify-content-between">
        <button type="submit" class="btn" (click)="changeWarehouseModal.close(false)">
          <ng-container i18n="Close">Close</ng-container>
        </button>
        <button type="submit" class="btn btn-primary" (click)="saveChangedWarehouse()">
          <ng-container i18n="Save">Save</ng-container>
        </button>
      </div>
    </ng-container>
  </ng-template>

  <p *ngIf="filteredArrivals && filteredArrivals.length === 0" i18n="No arrivals.">No arrivals.</p>
</div>
