<div class="d-flex align-items-center mb-2 flex-column">
  <img src="/assets/logo.png" alt="Omniopti" style="width: 500px; height: 500px" />
  <h1 i18n>You cannot access this product</h1>
  <p i18n>Contact <a href="mailto:info@omniopti.eu">info@omniopti.eu</a> for more information.</p>

  <div class="mb-2">
    <a href="{{ dashboardUrl }}" target="_blank" class="btn btn-info" i18n>To Omniopti dashboard</a>
  </div>
  <div class="mb-2">
    <button target="_blank" class="btn btn-warning" i18n (click)="auth.logout()">Log out</button>
  </div>
</div>
