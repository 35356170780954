import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from "@angular/router";

import { AppComponent } from "./app.component";
import { NavMenuComponent } from "./nav-menu/nav-menu.component";
import { HomeComponent } from "./home/home.component";
import { DateInterceptor } from "./DateInterceptor";
import { ProfileComponent } from "./profile/profile.component";
import { GateManagementComponent } from "./gate-management/gate-management.component";
import { WarehouseHistoryComponent } from "./warehouse-history/warehouse-history.component";
import { WarehouseArrivalComponent } from "./warehouse-arrival/warehouse-arrival.component";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { DriverListComponent } from "./driver-list/driver-list.component";
import { NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbCollapseModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { EditableComponent } from "./editable/editable.component";
import { ViewModeDirective } from "./editable/view-mode.directive";
import { EditModeDirective } from "./editable/edit-mode.directive";
import { FocusableDirective } from "./focusable.directive";
import { EditableOnEnterDirective } from "./editable/editable-on-enter.directive";
import { RampListComponent } from "./ramp-list/ramp-list.component";
import { ForbiddenDriversListComponent } from "./forbidden-drivers-list/forbidden-drivers-list.component";
import { ForbiddenCompaniesListComponent } from "./forbidden-companies-list/forbidden-companies-list.component";
import { ForbiddenCompanyComponent } from "./forbidden-company/forbidden-company.component";
import { ForbiddenDriverComponent } from "./forbidden-driver/forbidden-driver.component";
import { CountryCombinationsListComponent } from "./country-combinations-list/country-combinations-list.component";
import { CountryCombinationComponent } from "./country-combination/country-combination.component";
import { ClearableInputComponent } from "./clearable-input/clearable-input.component";
import { AutocompleteInputComponent } from "./autocomplete-input/autocomplete-input.component";
import { ContractManagementComponent } from "./contract-management/contract-management.component";
import { SignContractComponent } from "./sign-contract/sign-contract.component";
import { PreviewContractComponent } from "./preview-contract/preview-contract.component";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { AngularSignaturePadModule } from "@almothafar/angular-signature-pad";
import { SignatureScreenComponent } from "./signature-screen/signature-screen.component";
import { RenderContractTypeComponent } from "./render-contract-type/render-contract-type.component";
import { DriverDetailsComponent } from "./driver-details/driver-details.component";
import { MultiCountryInputComponent } from "./multi-country-input/multi-country-input.component";
import { ProductNotPermittedComponent } from "./auth/product-not-permitted/product-not-permitted.component";
import { LoginErrorComponent } from "./auth/login-error/login-error.component";
import { CompanyLoginComponent } from "./auth/company-login/company-login.component";
import { LanguagePickerComponent } from "./language-picker/language-picker.component";
import { AccordionWithContentComponent } from "./shared-components/accordion-with-content/accordion-with-content.component";
import { SpinnerComponent } from "./shared-components/spinner/spinner.component";
import { ConfirmationModalComponent } from "./shared-components/confirmation-modal/confirmation-modal.component";
import { CheckboxComponent } from "./shared-components/checkbox/checkbox.component";
import { ProfileYamasSettingsComponent } from "./profile-yamas-settings/profile-yamas-settings.component";

import { CoreModule } from "./core/core.module";
import { AuthGuard } from "./core/auth-guard.service";
import { NoAuthGuard } from "./core/no-auth-guard.service";
import { NgxPermissionsModule } from "ngx-permissions";
import { ErrorInterceptor } from "./error-interceptor";
import { StatsDashboardComponent } from "./stats/stats-dashboard/stats-dashboard.component";
import { StatsRampsBookingTimeComponent } from "./stats/stats-ramps-booking-time/stats-ramps-booking-time.component";
import { PickDateComponent } from "./pick-date/pick-date.component";

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    ProfileComponent,
    GateManagementComponent,
    WarehouseHistoryComponent,
    WarehouseArrivalComponent,
    LanguagePickerComponent,
    DriverListComponent,
    EditableComponent,
    ViewModeDirective,
    EditModeDirective,
    FocusableDirective,
    EditableOnEnterDirective,
    RampListComponent,
    ForbiddenDriversListComponent,
    ForbiddenCompaniesListComponent,
    ForbiddenCompanyComponent,
    ForbiddenDriverComponent,
    CountryCombinationsListComponent,
    CountryCombinationComponent,
    ClearableInputComponent,
    AutocompleteInputComponent,
    ContractManagementComponent,
    SignContractComponent,
    PreviewContractComponent,
    SignatureScreenComponent,
    RenderContractTypeComponent,
    DriverDetailsComponent,
    MultiCountryInputComponent,
    ProductNotPermittedComponent,
    LoginErrorComponent,
    CompanyLoginComponent,
    AccordionWithContentComponent,
    SpinnerComponent,
    ProfileYamasSettingsComponent,
    CheckboxComponent,
    ConfirmationModalComponent,
    StatsDashboardComponent,
    StatsRampsBookingTimeComponent,
    PickDateComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CoreModule.forRoot(),
    PdfViewerModule,
    AngularSignaturePadModule,
    NgxPermissionsModule.forRoot(),
    RouterModule.forRoot(
      [
        {
          path: "",
          component: HomeComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
        },
        {
          path: "login-error",
          component: LoginErrorComponent,
          pathMatch: "full",
          canActivate: [],
        },
        {
          path: "login",
          component: CompanyLoginComponent,
          pathMatch: "full",
          canActivate: [NoAuthGuard],
        },
        { path: "profile", component: ProfileComponent, pathMatch: "full", canActivate: [AuthGuard] },
        {
          path: "not-permitted",
          component: ProductNotPermittedComponent,
          pathMatch: "full",
          canActivate: [],
        },
        {
          path: "gate-management",
          component: GateManagementComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
        },
        {
          path: "warehouse-history",
          component: WarehouseHistoryComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
        },
        {
          path: "warehouse-history/:arrivalId",
          component: WarehouseHistoryComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
        },
        {
          path: "warehouse-arrival/:id",
          component: WarehouseArrivalComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
        },
        {
          path: "driver-list",
          component: DriverListComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
        },
        {
          path: "warehouse-archive",
          component: WarehouseHistoryComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
        },
        { path: "ramp-list", component: RampListComponent, pathMatch: "full", canActivate: [AuthGuard] },
        {
          path: "admin/contract-management",
          component: ContractManagementComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
        },
        {
          path: "sign-contract/:arrivalId",
          component: SignContractComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
        },
        {
          path: "sign-contract",
          component: SignContractComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
        },
        {
          path: "preview-contract/:languageId/:arrivalId/:contractType",
          component: PreviewContractComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
        },
        {
          path: "forbidden-driver-list",
          component: ForbiddenDriversListComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
        },
        {
          path: "forbidden-transport-company-list",
          component: ForbiddenCompaniesListComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
        },
        {
          path: "forbidden-driver/:id",
          component: ForbiddenDriverComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
        },
        {
          path: "driver/:id",
          component: DriverDetailsComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
        },
        {
          path: "forbidden-transport-company/:id",
          component: ForbiddenCompanyComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
        },
        {
          path: "country-combinations-list",
          component: CountryCombinationsListComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
        },
        {
          path: "country-combination/:id",
          component: CountryCombinationComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
        },
        {
          path: "stats",
          component: StatsDashboardComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
        },
      ],
      { onSameUrlNavigation: "reload" }
    ),
    NgbCollapseModule,
    AutocompleteLibModule,
    FontAwesomeModule,
    NgbPaginationModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DateInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
