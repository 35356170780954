import * as moment from "moment";
import { Company } from "../models/Company";
import { Driver } from "../models/Driver";

export function contractExpiryColor(driver: Driver, company: Company) {
  if (driver.contract.hasExpired) {
    return "red";
  }

  const expiresSoon = doesContractExpireSoon(driver, company);

  if (expiresSoon) {
    return "orange";
  }

  return "";
}

export function doesContractExpireSoon(driver: Driver, company: Company) {
  const expiry = moment(driver.contract.dateExpires);
  const today = moment();
  const monthDiff = expiry.diff(today, "months");

  if (monthDiff <= company.monthsBeforeExpiryWarning) {
    return true;
  }

  return false;
}

export function contractExpiryText(driver: Driver) {
  return moment(driver.contract.dateExpires).fromNow();
}
