import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-pick-date",
  templateUrl: "./pick-date.component.html",
  styleUrls: ["./pick-date.component.css"],
})
export class PickDateComponent implements OnInit {
  @Input() date: Date;
  @Output() dateChange: EventEmitter<Date> = new EventEmitter<Date>();

  constructor() {}

  ngOnInit() {}

  IsValidDate(d) {
    return !isNaN(d) && d instanceof Date;
  }

  DateChange(inputDate: string) {
    if (!inputDate?.length) {
      this.dateChange.emit(null);
      return;
    }

    let date = new Date(inputDate);
    if (this.IsValidDate(date)) {
      this.date = date;
      this.dateChange.emit(this.date);
    }
  }
}
