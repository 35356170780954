import { Component, HostListener } from "@angular/core";
import { RenderingService } from "./rendering.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {
  title = "app";

  constructor(public renderingService: RenderingService) {
    this.onResize(null);
  }

  @HostListener("window:resize", ["$event"])
  onResize($event) {
    let size;
    if (!$event) {
      size = window.innerWidth;
    } else {
      size = $event.target.innerWidth;
    }
    this.renderingService.setCurrentSize(size);
  }
}
