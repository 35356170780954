<div class="container-fluid app-container arrival-table-container">
  <h1 class="app-heading">
    <ng-container i18n="Forbidden companies">Forbidden companies</ng-container>
  </h1>

  <ng-container *ngIf="filteredCompanies">
    <div class="d-flex" class="table-filter-container">
      <input
        class="form-control table-filter-input"
        i18n-placeholder="Filter..."
        placeholder="Filter..."
        type="text"
        (input)="filterTextChange($event.target.value)"
      />
      <button
        class="btn btn-primary table-refresh-btn"
        i18n="Refresh"
        (click)="onRefreshClick()"
      >
        Refresh
      </button>
    </div>

    <button
      class="btn btn-primary mb-2 mt-2"
      i18n="Add a forbidden company"
      (click)="open(content)"
    >
      Add a forbidden company
    </button>

    <ng-container *ngIf="filteredCompanies.length > 0">
      <div class="table-responsive">
        <p
          i18n="
            Double click on forbidden company table cells to change information
          "
        >
          Double click on table cells 'Name', 'Reason' and 'Country' to change
          information.
        </p>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th i18n="Name">Name</th>
              <th i18n="Country">Country</th>
              <th i18n="Reason">Reason</th>
              <th i18n="Date forbidden">Date forbidden</th>
              <th i18n="Added by">Added by</th>
              <th i18n="Actions">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let company of filteredCompanies
                  | slice
                    : (pageNumber - 1) * pageSize
                    : (pageNumber - 1) * pageSize + pageSize
              "
            >
              <td>
                <editable (update)="OnUpdateCompany(company)">
                  <ng-template viewMode>{{ company.name }}</ng-template>
                  <ng-template editMode>
                    <input
                      focusable
                      editableOnEnter
                      [(ngModel)]="company.name"
                    />
                  </ng-template>
                </editable>
              </td>

              <td>
                <editable
                  (update)="OnUpdateCompany(company)"
                  (modeChange)="OnEditableModeChange(company)"
                >
                  <ng-template viewMode>{{ company.country.name }}</ng-template>

                  <ng-template editMode>
                    <ng-autocomplete
                      [(ngModel)]="company.transportCompanyCountryInput"
                      [searchKeyword]="'name'"
                      (selected)="
                        onSelectTransportCompanyCountry(company, $event)
                      "
                      (inputChanged)="
                        onSelectTransportCompanyCountry(company, null)
                      "
                      (inputCleared)="
                        onSelectTransportCompanyCountry(company, null)
                      "
                      [data]="countriesService.countries"
                      [itemTemplate]="itemTemplateCountry"
                    >
                    </ng-autocomplete>

                    <ng-template #itemTemplateCountry let-item>
                      <a [innerHTML]="item.name"></a>
                    </ng-template>
                  </ng-template>
                </editable>
              </td>

              <td>
                <editable (update)="OnUpdateCompany(company)">
                  <ng-template viewMode>{{
                    truncateReason(company.reason)
                  }}</ng-template>
                  <ng-template editMode>
                    <textarea
                      focusable
                      id="reason"
                      placeholder="Enter reason"
                      i18n-placeholder
                      class="md-textarea form-control"
                      rows="3"
                      [(ngModel)]="company.reason"
                      [ngModelOptions]="{ standalone: true }"
                    ></textarea>
                  </ng-template>
                </editable>
              </td>

              <td>{{ company.forbiddenAt | date: "dd.MM.yyyy" }}</td>

              <td>{{ company.addedBy ? company.addedBy.name : "/" }}</td>

              <td>
                <button
                  type="button"
                  (click)="SeeDetails(company)"
                  i18n="Details"
                  class="btn btn-primary d-block mb-2"
                >
                  Details
                </button>
                <button
                  type="button"
                  (click)="RemoveCompany(company)"
                  class="btn btn-danger d-block mb-1"
                >
                  <ng-container i18n="Remove">Remove</ng-container>
                </button>

                <ng-container *ngIf="company.isModified">
                  <button
                    type="button"
                    (click)="ApplyChanges(company)"
                    class="btn btn-primary d-block mb-1"
                  >
                    <ng-container i18n="Apply changes"
                      >Apply changes</ng-container
                    >
                  </button>
                  <button
                    type="button"
                    (click)="UndoChanges(company)"
                    class="btn btn-primary d-block mb-1"
                  >
                    <ng-container i18n="Undo changes"
                      >Undo changes</ng-container
                    >
                  </button>
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>

    <br />

    <ngb-pagination
      [(page)]="pageNumber"
      [pageSize]="pageSize"
      [maxSize]="8"
      [collectionSize]="filteredCompanies.length"
    ></ngb-pagination>
  </ng-container>

  <p *ngIf="filteredCompanies && filteredCompanies.length === 0">
    <ng-container i18n="No companies">No companies</ng-container>.
  </p>

  <ng-template #content let-modal>
    <div class="modal-header">
      <h4
        class="modal-title"
        id="modal-basic-title"
        i18n="Add forbidden company"
      >
        Add forbidden company
      </h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss(false)"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <div class="form-group">
        <label for="name"><ng-container i18n="Name">Name</ng-container></label>
        <input
          type="text"
          placeholder="Enter company name"
          i18n-placeholder
          class="form-control"
          id="name"
          [(ngModel)]="newCompany.name"
          [ngModelOptions]="{ standalone: true }"
        />
      </div>

      <div class="form-group">
        <label for="reason"
          ><ng-container i18n="Reason">Reason</ng-container></label
        >
        <textarea
          id="reason"
          placeholder="Enter reason"
          i18n-placeholder
          class="md-textarea form-control"
          rows="3"
          [(ngModel)]="newCompany.reason"
          [ngModelOptions]="{ standalone: true }"
        ></textarea>
      </div>

      <div class="form-group">
        <label for="tCompany"
          ><ng-container i18n="Company country"
            >Company country</ng-container
          ></label
        >
        <div class="autocomplete-container">
          <ng-autocomplete
            [(ngModel)]="newCompany.transportCompanyCountryInput"
            [searchKeyword]="'name'"
            (selected)="onSelectTransportCompanyCountrySingle($event)"
            (inputChanged)="onSelectTransportCompanyCountrySingle(null)"
            (inputCleared)="onSelectTransportCompanyCountrySingle(null)"
            [data]="countriesService.countries"
            [itemTemplate]="itemTemplateCountry"
          >
          </ng-autocomplete>

          <ng-template #itemTemplateCountry let-item>
            <a [innerHTML]="item.name"></a>
          </ng-template>
        </div>
      </div>

      <div class="form-group">
        <label for="dateForbidden"
          ><ng-container i18n="Date to">Date forbidden</ng-container></label
        >
        <input
          class="form-control"
          placeholder="yyyy-mm-dd"
          name="dateForbidden"
          [(ngModel)]="newCompany.forbiddenAt"
          (click)="dF.toggle()"
          ngbDatepicker
          #dF="ngbDatepicker"
        />
      </div>
    </div>

    <div class="modal-footer d-flex justify-content-between">
      <button type="submit" class="btn" (click)="modal.close(false)">
        <ng-container i18n="Cancel">Cancel</ng-container>
      </button>
      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="!newCompanyValid()"
        (click)="AddCompany()"
      >
        <ng-container i18n="Add">Add</ng-container>
      </button>
    </div>
  </ng-template>
</div>
