import { RestService } from "../services/rest.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../core/auth.service";
import { MessageTranslationService } from "../message-translation.service";
import { DriverContractType } from "../models/Contract";
import { Driver } from "../models/Driver";
import { DriverContract } from "../models/DriverContract";
import { contractExpiryColor, contractExpiryText } from "../utils/ArrivalUtils";
import { SupportService } from "../support.service";

@Component({
  selector: "app-driver-details",
  templateUrl: "./driver-details.component.html",
  styleUrls: ["./driver-details.component.css"],
})
export class DriverDetailsComponent implements OnInit {
  DriverContractType = DriverContractType;

  driver: Driver;
  filteredContracts: DriverContract[] = [];

  deletingContract: DriverContract | null = null;
  deletingContractInProgress = false;

  contractExpiryText = contractExpiryText;

  showLongTermContracts = true;
  showShortTermContracts = true;

  constructor(
    private route: ActivatedRoute,
    private http: RestService,
    private toast: ToastrService,
    private modalService: NgbModal,
    private support: SupportService,
    private msgT: MessageTranslationService,
    public auth: AuthService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const id = params["id"];
      if (id) {
        this.http.get(`api/driver/details/${id}`).subscribe(
          (driver: Driver) => {
            this.driver = driver;
            this.onContractDisplayParamteresChange();
          },
          (error) => {
            console.log("Error fetching the driver");
            console.log(error);
            this.toast.error(this.msgT.fetchDriverError());
          }
        );
      }
    });
  }

  openContractFile(contract: DriverContract) {
    this.support.downloadFile(`api/driver/getContractFile/${contract.id}`);
  }

  onContractDisplayParamteresChange() {
    this.filteredContracts = this.driver.contracts;
    if (!this.showLongTermContracts) {
      this.filteredContracts = this.filteredContracts.filter((c) => c.type !== DriverContractType.LongTerm);
    }

    if (!this.showShortTermContracts) {
      this.filteredContracts = this.filteredContracts.filter((c) => c.type !== DriverContractType.ShortTerm);
    }
  }

  async onDeleteContract() {
    if (!this.deletingContract) {
      return;
    }

    this.deletingContractInProgress = true;

    try {
      await this.http.post(`api/driverContract/deleteContractForDriver/${this.deletingContract.id}`, {}).toPromise();
      this.toast.success(this.msgT.successDeletingContract());
      this.modalService.dismissAll();
      this.ngOnInit();
    } catch (e) {
      console.log(e);
      this.toast.error(this.msgT.errorDeletingContract());
    }

    this.deletingContractInProgress = false;
  }

  openDeleteContractModal(content: any, contract: DriverContract) {
    this.deletingContract = contract;
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" }).result.then(
      () => {
        this.deletingContract = null;
      },
      () => {
        this.deletingContract = null;
      }
    );
  }

  public contractExpiryColor(driver: Driver) {
    contractExpiryColor(driver, this.auth.loggedInUser.company);
  }
}
