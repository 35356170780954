import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChildren } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faCheck, faExclamationTriangle, faTimes, faUndo, faSignOutAlt, faInfoCircle, faCommentAlt } from "@fortawesome/free-solid-svg-icons";
import { NgbDateStruct, NgbModal, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { interval, Subject, Subscription } from "rxjs";
import { share, startWith, switchMap, takeUntil } from "rxjs/operators";
import { ArrivalUtilsService } from "../arrival-utils.service";
import { AuthService } from "../core/auth.service";
import { CountriesService } from "../countries.service";
import { MessageTranslationService } from "../message-translation.service";
import { Arrival } from "../models/Arrival";
import { ArrivalStatus } from "../models/ArrivalStatus";
import { Country } from "../models/Country";
import { Driver } from "../models/Driver";
import { Ramp } from "../models/Ramp";
import { Warehouse, WarehousePart } from "../models/WarehousePart";
import { RenderingService } from "../rendering.service";
import { RestService } from "../services/rest.service";
import { UserUtilsService } from "../user-utils.service";
import { contractExpiryColor, contractExpiryText, doesContractExpireSoon } from "../utils/ArrivalUtils";

const TABS = {
  ALL: 0,
  UNSORTED: -1,
  LATEST: -2,
};

const POLLING_INTERVAL = 30 * 1000;

class ListFinishedResponse {
  arrivals: Arrival[];
  totalPages: number;
}

@Component({
  selector: "app-warehouse-history",
  templateUrl: "./warehouse-history.component.html",
  styleUrls: ["./warehouse-history.component.css"],
})
export class WarehouseHistoryComponent implements OnInit, AfterViewInit {
  @ViewChildren("arrivalTr") arrivalTr: QueryList<ElementRef>;

  ArrivalStatus = ArrivalStatus;
  contractExpiryText = contractExpiryText;
  faCheck = faCheck;
  faTimes = faTimes;
  faUndo = faUndo;
  faSignOutAlt = faSignOutAlt;
  faInfoCircle = faInfoCircle;
  faExclamationTriangle = faExclamationTriangle;
  faCommentAlt = faCommentAlt;
  changeWarehouseId: number = null;

  arrivals: Arrival[] = null;
  filteredArrivals: Arrival[] = null;

  warehouses: Warehouse[] = null;
  warehouseFilterCheckboxes: Record<number, boolean> = {};
  warehousePartFilterCheckboxes: Record<number, boolean> = {};
  rampFilterCheckboxes: Record<number, boolean> = {};

  warehouseParts: WarehousePart[] = null;
  selectedTab: number = TABS.ALL;

  freeRamps: Ramp[] = [];

  freeRampsPerWarehouse: Record<string, Ramp[]> = {};

  filterText = "";
  filterTextRaw = "";

  pageSize = 10;
  pageNumber = 0;

  isArchive: boolean = false;
  dateFrom: NgbDateStruct;
  dateTo: NgbDateStruct;

  filterCheckboxes = {
    [ArrivalStatus.Arrived]: true,
    [ArrivalStatus.UnloadingStarted]: true,
    [ArrivalStatus.UnloadingDone]: true,
    [ArrivalStatus.Departed]: true,
    [ArrivalStatus.AtRamp]: true,
    [ArrivalStatus.OnHold]: true,
    [ArrivalStatus.OnHoldEnd]: true,
    [ArrivalStatus.ReturnedToStart]: true,
    [ArrivalStatus.EarlyDeparture]: true,
  };

  isBusy: boolean = false;

  stopPolling = new Subject();

  archivePageNumber = 1;
  archivePageCount = 0;
  previousPage = 0;
  archivePageSize = 10;
  archivePageSizeInput = 10;

  archivePreviousPage = 1;

  possibleArchivePageSIzes = [10, 20, 50, 100];

  arrivalToMarkEarlyDeparture: Arrival | null = null;
  arrivalToViewEarlyDeparture: Arrival | null = null;
  arrivalToChangeWarehouse: Arrival | null = null;
  arrivalToBeMarkedAsSigned: Arrival | null = null;

  arrivalIdToScrollTo: number | null = null;
  onFilteredArrivalsSet = new Subject();

  constructor(
    private router: Router,
    private http: RestService,
    public arrivalUtils: ArrivalUtilsService,
    private msgT: MessageTranslationService,
    private toastr: ToastrService,
    public auth: AuthService,
    public renderingService: RenderingService,
    public userUtils: UserUtilsService,
    public countriesService: CountriesService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private calendar: NgbCalendar
  ) {}

  ngOnDestroy() {
    this.stopPolling.next();
  }

  ngOnInit() {
    if (this.router.url.includes("warehouse-archive")) {
      this.isArchive = true;

      this.dateTo = this.calendar.getToday();
      this.dateFrom = this.calendar.getPrev(this.calendar.getToday(), "d", 7);
    }

    this.fetchWarehouseParts();
    this.GetWarehouses();

    if (!this.isArchive) {
      interval(POLLING_INTERVAL)
        .pipe(
          startWith(0),
          switchMap(async () => {
            if (!this.isBusy && !this.anyArrivalModified()) {
              this.onPoll();
            }
          }),
          share(),
          takeUntil(this.stopPolling)
        )
        .subscribe();
    } else {
      this.onPoll();
    }
  }

  ngAfterViewInit() {
    this.onFilteredArrivalsSet.subscribe(() => {
      this.scrollToArrival();
    });

    this.route.params.subscribe((params) => {
      if (params.arrivalId == null) {
        return;
      }

      const arrivalId = Number(params.arrivalId);
      if (isNaN(arrivalId)) {
        return;
      }

      this.arrivalIdToScrollTo = arrivalId;
      this.scrollToArrival();
    });
  }

  scrollToArrival() {
    if (this.arrivalIdToScrollTo == null || this.filteredArrivals == null) {
      return;
    }

    const arrivalIndex = this.filteredArrivals.findIndex((a) => a.id === this.arrivalIdToScrollTo);

    if (arrivalIndex < 0) {
      return;
    }

    // scroll to correct page
    const arrivalPageNumber = Math.floor(arrivalIndex / this.pageSize) + 1;
    const arrivalIndexOnPage = arrivalIndex % this.pageSize;
    this.pageNumber = arrivalPageNumber;

    this.arrivalTr.changes.subscribe(() => {
      const trElement = this.arrivalTr.toArray()[arrivalIndexOnPage];
      trElement.nativeElement.focus();
      trElement.nativeElement.scrollIntoView({
        behavior: "smooth",
      });
    });
  }

  GetWarehouses() {
    this.auth.hasLoggedInUserLoaded$.subscribe((isDone) => {
      if (isDone) {
        this.http.get(`api/authentication/listWarehouses`).subscribe(
          (w: Warehouse[]) => {
            this.warehouses = w;
            for (let i = 0; i < this.warehouses.length; i++) {
              const isSelected = this.auth.loggedInUser.defaultWarehouse?.id === this.warehouses[i].id;
              this.warehouseCheckboxChange(isSelected, this.warehouses[i].id);
            }

            this.applyFilters();
            this.updateFreeRampsPerWarehouse();
          },
          (error) => {
            console.log("Find warehouses error");
            console.log(error);
          }
        );
      }
    });
  }

  onPoll() {
    this.fetchArrivals(this.selectedTab);
    this.fetchFreeRamps();
  }

  fetchWarehouseParts() {
    if (this.isArchive) {
      return;
    }

    this.http.get(`api/yardManagement/listWarehouseParts`).subscribe(
      (wps: WarehousePart[]) => {
        this.warehouseParts = wps;
      },
      (error) => {
        console.log("Fetch history error");
        console.log(error);
        this.toastr.error(this.msgT.fetchHistoryError());
      }
    );
  }

  fetchArrivals(warehousePartId: number | null) {
    if (this.isArchive) {
      this.fetchFromArchive();
    } else {
      let url = "api/yardManagement";

      if (warehousePartId === TABS.LATEST) {
        url = `${url}/listForUndo`;
      } else {
        url = `${url}/listUnfinished`;
        if (warehousePartId) {
          url = `${url}/${warehousePartId}`;
        }
      }

      this.http.get(url).subscribe(
        (arr: Arrival[]) => {
          this.arrivals = arr;
          for (let i = 0; i < this.arrivals.length; i++) {
            this.arrivals[i].filterString = this.arrivalUtils.searchString(this.arrivals[i]);
            this.arrivals[i].loadingCountryInput = this.arrivals[i].loadingCountry.name;
            this.arrivals[i].transportCompanyCountryInput = this.arrivals[i].driver.transportCompanyCountry.name;
            this.arrivalUtils.setOriginalData(this.arrivals[i]);
            this.arrivalUtils.setTimezoneTimes(this.arrivals[i]);

            this.arrivals[i].contractExpiresSoon = this.arrivals[i].driver.contract
              ? doesContractExpireSoon(this.arrivals[i].driver, this.auth.loggedInUser.company)
              : false;
          }

          this.filteredArrivals = this.arrivals;
          this.filterTextChange(this.filterText);
          this.onFilteredArrivalsSet.next();
        },
        (error) => {
          console.log("Fetch history error");
          console.log(error);
          this.arrivals = [];
          this.filteredArrivals = [];
          this.toastr.error(this.msgT.fetchHistoryError());
        }
      );
    }
  }

  dateStructToString(d: NgbDateStruct) {
    return `${d.day}. ${d.month}. ${d.year}`;
  }

  fetchFromArchive() {
    let url = `api/yardManagement/listFinished?pageNumber=${this.archivePageNumber}&pageSize=${this.archivePageSizeInput}&searchString=${this.filterText}`;

    if (this.dateFrom) {
      url = `${url}&from=${this.dateStructToString(this.dateFrom)}`;
    }

    if (this.dateTo) {
      url = `${url}&to=${this.dateStructToString(this.dateTo)}`;
    }

    const selectedRampIds = Object.entries(this.rampFilterCheckboxes)
      .filter(([k, v]) => v)
      .map(([k, v]) => k);

    if (selectedRampIds.length > 0) {
      url = `${url}&r=${selectedRampIds.join(",")}`;
    }

    this.http.get(url).subscribe(
      (arr: ListFinishedResponse) => {
        this.arrivals = arr.arrivals;
        this.archivePageCount = arr.totalPages;
        for (let i = 0; i < this.arrivals.length; i++) {
          this.arrivals[i].filterString = this.arrivalUtils.searchString(this.arrivals[i]);
          this.arrivals[i].loadingCountryInput = this.arrivals[i].loadingCountry.name;
          this.arrivals[i].transportCompanyCountryInput = this.arrivals[i].driver.transportCompanyCountry.name;
          this.arrivalUtils.setOriginalData(this.arrivals[i]);
          this.arrivalUtils.setTimezoneTimes(this.arrivals[i]);
        }

        this.filteredArrivals = this.arrivals;
        this.filterTextChange(this.filterText);
        this.archivePageSize = this.archivePageSizeInput;
      },
      (error) => {
        console.log("Fetch history error");
        console.log(error);
        this.arrivals = [];
        this.filteredArrivals = [];
        this.toastr.error(this.msgT.fetchHistoryError());
      }
    );
  }

  fetchFreeRamps() {
    if (this.isArchive) {
      return;
    }

    this.http.get(`api/ramps/listFree`).subscribe(
      (ramps: Ramp[]) => {
        this.freeRamps = ramps;
        this.updateFreeRampsPerWarehouse();
      },
      (error) => {}
    );
  }

  updateFreeRampsPerWarehouse() {
    if (!this.warehouses) {
      this.freeRampsPerWarehouse = {};
      return;
    }

    for (let w of this.warehouses) {
      this.freeRampsPerWarehouse[w.id] = this.freeRamps.filter((r) => r.warehousePart.warehouse.id === w.id);
    }
  }

  public filterFullText() {
    if (this.filterText.length === 0) {
      this.filteredArrivals = this.arrivals;
      return;
    }

    this.filteredArrivals = this.arrivals.filter((a) => a.filterString.includes(this.filterText));
  }

  public filterStatusCheckboxes() {
    if (!this.filteredArrivals) {
      return;
    }

    this.filteredArrivals = this.filteredArrivals.filter((a) => this.filterCheckboxes[a.status]);
  }

  public filterWarehouseCheckboxes() {
    if (!this.filteredArrivals) {
      return;
    }

    this.filteredArrivals = this.filteredArrivals.filter((a) => this.warehouseFilterCheckboxes[a.warehouse.id]);
  }

  public onMarkContractSignedClick(content: any, arrival: Arrival) {
    this.arrivalToBeMarkedAsSigned = arrival;
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" }).result.then(
      (_) => {
        this.arrivalToBeMarkedAsSigned = null;
      },
      (_) => {
        this.arrivalToBeMarkedAsSigned = null;
      }
    );
  }

  public markContractAsSigned() {
    this.http
      .post(`api/yardManagement/markContractUploaded/${this.arrivalToBeMarkedAsSigned.id}`, {
        userId: this.auth.userId,
      })
      .subscribe(
        (arr: Arrival) => {
          this.toastr.success(this.msgT.markContractSuccess());
          this.arrivalToBeMarkedAsSigned.contractMarkedAsUploaded = arr.contractMarkedAsUploaded;
          this.arrivalToBeMarkedAsSigned.contractMarkedAsUploadedBy = arr.contractMarkedAsUploadedBy;
          this.arrivalToBeMarkedAsSigned.permitFurtherActions = arr.permitFurtherActions;
          this.arrivalToBeMarkedAsSigned = null;
          this.modalService.dismissAll();
        },
        (error) => {
          console.log("Error marking contract");
          console.log(error);
          this.toastr.error(this.msgT.markContractError());
        }
      );
  }

  public onPrepareContractClick(arrival: Arrival) {
    this.router.navigate(["/sign-contract", arrival.id]);
  }

  public applyFilters() {
    if (this.isArchive) {
      this.fetchFromArchive();
      return;
    }

    this.filterFullText();

    if (!this.isArchive) {
      this.filterStatusCheckboxes();
      this.filterWarehouseCheckboxes();
    }
  }

  public resetFilters() {
    this.dateFrom = null;
    this.dateTo = null;
    this.filterTextRaw = "";
    this.filterText = "";
    this.applyFilters();
  }

  public SeeDetails(arrival: Arrival) {
    this.router.navigate([`warehouse-arrival`, arrival.id]);
  }

  public filterTextChange(txt) {
    const txtProcessed = txt.trim().toLowerCase();
    this.filterText = txtProcessed;

    if (this.isArchive) {
      return;
    }

    this.applyFilters();
  }

  public onRefreshClick() {
    this.stopPolling.next();
    this.ngOnInit();
  }

  public onProcessButtonClick(arrivalId: number) {
    const arrival: Arrival = this.arrivals.find((a) => a.id === arrivalId);

    if (!arrival) {
      return;
    }

    if (!this.arrivalUtils.processingAllowed(arrival)) {
      return;
    }

    let newStatus = null;
    if (arrival.status === ArrivalStatus.AtRamp) {
      newStatus = ArrivalStatus.UnloadingStarted;
    } else if (arrival.status === ArrivalStatus.UnloadingStarted || arrival.status === ArrivalStatus.OnHoldEnd) {
      newStatus = ArrivalStatus.UnloadingDone;
    } else if (
      arrival.status === ArrivalStatus.UnloadingDone ||
      arrival.status === ArrivalStatus.ReturnedToStart ||
      arrival.status === ArrivalStatus.EarlyDeparture
    ) {
      newStatus = ArrivalStatus.Departed;
    }

    if (newStatus) {
      this.ChangeStatus(arrivalId, newStatus);
    }
  }

  public onHoldButtonClick(arrivalId: number) {
    const arrival: Arrival = this.arrivals.find((a) => a.id === arrivalId);

    if (!arrival) {
      return;
    }

    if (!this.arrivalUtils.processingAllowed(arrival)) {
      return;
    }

    let newStatus = null;
    if (arrival.status === ArrivalStatus.UnloadingStarted) {
      newStatus = ArrivalStatus.OnHold;
    } else if (arrival.status === ArrivalStatus.OnHold) {
      newStatus = ArrivalStatus.OnHoldEnd;
    }

    if (newStatus) {
      this.ChangeStatus(arrivalId, newStatus);
    }
  }

  public onReturnedToStartClick(arrivalId: number) {
    const arrival: Arrival = this.arrivals.find((a) => a.id === arrivalId);

    if (!arrival) {
      return;
    }

    if (!this.arrivalUtils.processingAllowed(arrival)) {
      return;
    }

    let newStatus = null;
    if (arrival.status === ArrivalStatus.OnHold) {
      newStatus = ArrivalStatus.ReturnedToStart;
    }

    if (newStatus) {
      this.ChangeStatus(arrivalId, newStatus);
    }
  }

  public onEarlyDepartureClick(arrival: Arrival) {
    if (!this.arrivalUtils.earlyDepartureAllowed(arrival)) {
      return;
    }

    let newStatus = null;
    if (arrival.status === ArrivalStatus.Arrived) {
      newStatus = ArrivalStatus.EarlyDeparture;
    }

    if (newStatus) {
      this.ChangeStatus(arrival.id, newStatus, arrival.earlyDepartureReason);
      this.modalService.dismissAll();
    }
  }

  public onUndoButtonClick(arrivalToUndo: Arrival) {
    if (!this.arrivalUtils.undoAllowed(arrivalToUndo)) {
      return;
    }

    this.http
      .post(`api/yardManagement/undoStatus/${arrivalToUndo.id}`, {
        userId: this.auth.userId,
      })
      .subscribe(
        (arrival: Arrival) => {
          if (arrival) {
            if (arrival.status === ArrivalStatus.Arrived) {
              this.fetchFreeRamps();
              this.arrivals = this.arrivals.filter((a) => a.id !== arrival.id);
              this.filteredArrivals = this.filteredArrivals.filter((a) => a.id !== arrival.id);
            } else {
              arrivalToUndo.status = arrival.status;
              arrivalToUndo.arrivalTime = arrival.arrivalTime;
              arrivalToUndo.arrivalsOnRamp = arrival.arrivalsOnRamp;
              arrivalToUndo.currentRamp = arrival.currentRamp;

              if (arrival.status === ArrivalStatus.UnloadingDone) {
                arrivalToUndo.departureTime = null;
                arrivalToUndo.gateManagerMarkedDeparture = null;
              }

              if (arrival.status === ArrivalStatus.OnHoldEnd || arrival.status === ArrivalStatus.OnHold || arrival.status === ArrivalStatus.UnloadingStarted) {
                this.fetchFreeRamps();
              }

              arrivalToUndo.filterString = this.arrivalUtils.searchString(arrivalToUndo);

              this.arrivalUtils.setOriginalData(arrivalToUndo);
              this.arrivalUtils.setTimezoneTimes(arrivalToUndo);

              this.filterTextChange(this.filterText);
            }

            this.toastr.success(this.msgT.undoStatusSuccess());
          }
        },
        (error) => {
          console.log("Undo status error");
          console.log(error);
          this.toastr.error(this.msgT.undoStatusError());
        }
      );
  }

  ChangeStatus(arrivalId: number, status: ArrivalStatus, earlyDepartureReason: string = null) {
    const arrivalToUpdate: Arrival = this.arrivals.find((a) => a.id === arrivalId);

    this.http
      .post(`api/yardManagement/changeStatus/${arrivalToUpdate.id}`, {
        status,
        userId: this.auth.userId,
        earlyDepartureReason,
      })
      .subscribe(
        (arrival: Arrival) => {
          if (arrival) {
            if (arrival.status === ArrivalStatus.Departed) {
              this.toastr.success(this.msgT.arrivalMovedToArchive());
              this.arrivals = this.arrivals.filter((a) => a.id !== arrival.id);
              this.filteredArrivals = this.filteredArrivals.filter((a) => a.id !== arrival.id);
            } else {
              this.toastr.success(this.msgT.changeStatusSuccess());
              const arrivalIndex = this.arrivals.findIndex((a) => a.id === arrivalId);
              if (arrivalIndex >= 0) {
                let rampsUpdated = false;

                // todo: temp solution
                if (arrival.arrivalsOnRamp && arrival.arrivalsOnRamp.length > 0) {
                  const lastUpdate = arrival.arrivalsOnRamp[arrival.arrivalsOnRamp.length - 1];

                  switch (lastUpdate.status) {
                    case ArrivalStatus.UnloadingStarted:
                      lastUpdate.unloadingStartTime = moment(lastUpdate.unloadingStartTime).toDate().toString();
                      break;

                    case ArrivalStatus.UnloadingDone:
                      lastUpdate.unloadingEndTime = moment(lastUpdate.unloadingEndTime).toDate().toString();
                      break;

                    case ArrivalStatus.OnHold:
                      lastUpdate.onHoldTime = moment(lastUpdate.onHoldTime).toDate().toString();
                      break;

                    case ArrivalStatus.OnHoldEnd:
                      lastUpdate.onHoldEndTime = moment(lastUpdate.onHoldEndTime).toDate().toString();
                      break;
                  }

                  if (lastUpdate.status === ArrivalStatus.OnHold || lastUpdate.status === ArrivalStatus.OnHoldEnd) {
                    this.fetchFreeRamps();
                    rampsUpdated = true;
                  }
                }

                if (!rampsUpdated && arrivalToUpdate.currentRamp != null && arrival.currentRamp == null) {
                  this.fetchFreeRamps();
                }

                this.arrivals[arrivalIndex] = arrival;

                this.arrivals[arrivalIndex].filterString = this.arrivalUtils.searchString(this.arrivals[arrivalIndex]);
                this.arrivalUtils.setOriginalData(this.arrivals[arrivalIndex]);
                this.arrivalUtils.setTimezoneTimes(this.arrivals[arrivalIndex]);

                this.filterTextChange(this.filterText);
              }
            }
          }
        },
        (error) => {
          console.log("Change status error");
          console.log(error);

          if (error.error && arrivalToUpdate.currentRamp && error.error.includes("Ramp is taken")) {
            this.toastr.error(this.msgT.rampIsTakenError(arrivalToUpdate.currentRamp.name));
          } else {
            this.toastr.error(this.msgT.changeStatusError());
          }
        }
      );
  }

  public onDateSelect(v) {}

  OnUpdateArrival(arrival: Arrival) {
    arrival.isModified = this.arrivalUtils.isModified(arrival);
    arrival.filterString = this.arrivalUtils.searchString(arrival);
    this.filterTextChange(this.filterText);
  }

  ApplyChanges(arrival: Arrival) {
    this.http
      .post(`api/yardManagement/updateArrival/${arrival.id}`, {
        driverName: arrival.driver.name,
        registrationNumber: arrival.registrationNumber,
        driverTransportCompany: arrival.driver.transportCompany,
        loadingCountryId: arrival.loadingCountry.id,
        transportCompanyCountryId: arrival.driver.transportCompanyCountry.id,
        arrivalTime: moment(arrival.arrivalTime).utc().toDate(),
      })
      .subscribe(
        (updatedArrival: Arrival) => {
          if (updatedArrival) {
            arrival.registrationNumber = updatedArrival.registrationNumber;
            arrival.countryCombinationId = updatedArrival.countryCombinationId;
            arrival.loadingCountry = updatedArrival.loadingCountry;
            arrival.arrivalTime = updatedArrival.arrivalTime;

            for (let i = 0; i < this.arrivals.length; i++) {
              if (this.arrivals[i].driver.id === updatedArrival.driver.id) {
                this.arrivals[i].driver.name = updatedArrival.driver.name;
                this.arrivals[i].driver.transportCompany = updatedArrival.driver.transportCompany;
                this.arrivals[i].driver.transportCompanyCountry = updatedArrival.driver.transportCompanyCountry;
              }
            }

            arrival.filterString = this.arrivalUtils.searchString(arrival);
            this.arrivalUtils.setOriginalData(arrival);
            this.filterTextChange(this.filterText);
            this.toastr.success(this.msgT.updateArrivalSuccess());
            arrival.isModified = false;
          }
        },
        (error) => {
          console.log("Update arrival error");
          console.log(error);
          this.toastr.error(this.msgT.updateArrivalError());
        }
      );
  }

  UndoChanges(arrival: Arrival) {
    if (arrival.isModified) {
      this.arrivalUtils.undoChanges(arrival);
      this.filterTextChange(this.filterText);
    }
  }

  public navChange(warehousePartId: number) {
    this.arrivals = [];
    this.filteredArrivals = [];
    this.fetchArrivals(warehousePartId);
    this.selectedTab = warehousePartId;
  }

  public canChangeWarehouse(a: Arrival) {
    if (a.status === ArrivalStatus.Arrived && this.auth.IsWarehouseAdmin()) {
      return true;
    }

    return false;
  }

  public warehouseCheckboxChange(isSelected: boolean, wId: number) {
    this.warehouseFilterCheckboxes[wId] = isSelected;
    this.selectAllWarehousePartsForWarehouse(wId, isSelected);

    if (!this.isArchive) {
      this.applyFilters();
    }
  }

  public warehousePartCheckboxChange(isSelected: boolean, wPartId: number) {
    this.warehousePartFilterCheckboxes[wPartId] = isSelected;
    this.selectAllRampsForWarehousePart(wPartId, isSelected);
  }

  public selectAllWarehousePartsForWarehouse(wId: number, isSelected: boolean) {
    const warehouse = this.warehouses.find((w) => w.id == wId);
    if (!warehouse) {
      return;
    }

    warehouse.warehouseParts.forEach((wp) => {
      this.warehousePartCheckboxChange(isSelected, wp.id);
    });
  }

  public rampCheckboxChange(isSelected: boolean, rampId: number) {
    this.rampFilterCheckboxes[rampId] = isSelected;
  }

  public selectAllRampsForWarehousePart(wPartId: number, isSelected: boolean) {
    const warehouseParts = this.warehouses.map((w) => w.warehouseParts).reduce((acc, val) => acc.concat(val), []);
    const part = warehouseParts.find((p) => p.id === wPartId);
    if (!part) {
      return;
    }

    part.ramps.forEach((r) => {
      this.rampCheckboxChange(isSelected, r.id);
    });
  }

  public onRampSelectChange(value, arrival: Arrival) {
    const ramp = this.freeRamps.find((r) => r.id.toString() === value);
    arrival.selectedRamp = ramp;
  }

  public applyRampSelect(arrival: Arrival) {
    if (!arrival.selectedRamp) {
      return;
    }

    this.http
      .post(`api/yardManagement/assignToRamp/${arrival.id}`, {
        rampId: arrival.selectedRamp.id,
        userId: this.auth.userId,
      })
      .subscribe(
        (updatedArrival: Arrival) => {
          if (updatedArrival) {
            if (this.selectedTab === TABS.ALL) {
              arrival.currentRamp = updatedArrival.currentRamp;
              arrival.permitFurtherActions = updatedArrival.permitFurtherActions;

              arrival.selectedRamp = null;
              arrival.status = updatedArrival.status;

              if (updatedArrival.arrivalsOnRamp && updatedArrival.arrivalsOnRamp.length > 0) {
                if (arrival.arrivalsOnRamp == null) {
                  arrival.arrivalsOnRamp = [];
                }

                arrival.arrivalsOnRamp.push(updatedArrival.arrivalsOnRamp[0]);
              }
            } else {
              this.arrivals = this.arrivals.filter((a) => a.id !== arrival.id);
            }

            this.filterTextChange(this.filterText);
            this.toastr.success(this.msgT.updateArrivalSuccess());

            this.fetchFreeRamps();
          }
        },
        (error) => {
          console.log("Update arrival error");
          console.log(error);
          this.toastr.error(this.msgT.updateArrivalError());
        }
      );
  }

  public checkboxFilterChange(event: any, status: ArrivalStatus) {
    this.filterCheckboxes[status] = event.target.checked;
    this.applyFilters();
  }

  public OnEditableModeChange(isEdit: boolean, arrival: Arrival = null) {
    this.isBusy = isEdit;

    if (arrival) {
      arrival.loadingCountryInput = arrival.loadingCountry.name;
      arrival.transportCompanyCountryInput = arrival.driver.transportCompanyCountry.name;
      arrival.arrivalTimeInput = arrival.arrivalTime;
    }
  }

  public anyArrivalModified() {
    if (!this.arrivals) {
      return false;
    }

    return this.arrivals.some((a) => a.isModified || a.selectedRamp != null);
  }

  public onSelectLoadingCountry(arrival: Arrival, country: Country | null) {
    if (country) {
      arrival.loadingCountryInput = country.name;
      console.log(arrival.loadingCountryInput);
      arrival.loadingCountry = country;
    }
  }

  public onSelectTransportCompanyCountry(arrival: Arrival, country: Country | null) {
    if (country) {
      arrival.transportCompanyCountryInput = country.name;
      arrival.driver.transportCompanyCountry = country;
    }
  }

  public loadPage(page: number) {
    if (page !== this.archivePreviousPage) {
      this.archivePreviousPage = page;
      this.archivePageNumber = page;
      this.fetchFromArchive();
    }
  }

  openEarlyDepartureModal(content, arrival: Arrival) {
    this.arrivalToMarkEarlyDeparture = arrival;
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" }).result.then(
      (_) => {
        this.arrivalToMarkEarlyDeparture = null;
      },
      (_) => {
        this.arrivalToMarkEarlyDeparture = null;
      }
    );
  }

  openViewEarlyDepartureModal(content, arrival: Arrival) {
    this.arrivalToViewEarlyDeparture = arrival;
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" }).result.then(
      (_) => {
        this.arrivalToViewEarlyDeparture = null;
      },
      (_) => {
        this.arrivalToViewEarlyDeparture = null;
      }
    );
  }

  openChangeWarehouseModal(content, arrival: Arrival) {
    if (!this.canChangeWarehouse(arrival)) {
      return;
    }

    this.arrivalToChangeWarehouse = arrival;
    this.changeWarehouseId = arrival.warehouse.id;

    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" }).result.then(
      (_) => {
        this.arrivalToChangeWarehouse = null;
      },
      (_) => {
        this.arrivalToChangeWarehouse = null;
      }
    );
  }

  saveChangedWarehouse() {
    if (!this.arrivalToChangeWarehouse || !this.changeWarehouseId) {
      return;
    }

    this.http
      .post(`api/yardManagement/changeWarehouseForArrival/${this.arrivalToChangeWarehouse.id}`, {
        warehouseId: this.changeWarehouseId,
      })
      .subscribe(
        (updatedArrival: Arrival) => {
          this.arrivalToChangeWarehouse.warehouse = updatedArrival.warehouse;
          this.toastr.success(this.msgT.updateArrivalSuccess());
          this.modalService.dismissAll();
          this.applyFilters();
        },
        (error) => {
          console.log("Update arrival error");
          console.log(error);
          this.toastr.error(this.msgT.updateArrivalError());
        }
      );
  }

  public contractExpiryColor(driver: Driver) {
    contractExpiryColor(driver, this.auth.loggedInUser.company);
  }
}
