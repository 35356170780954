<div class="autocomplete-container" style="margin-bottom: unset; display: flex; align-items: center; width: 100%">
  <div class="input-container" style="width: 100%">
    <input
      type="text"
      class="form-control mr-3"
      style="flex: 1"
      (keydown.enter)="onEnter.emit()"
      [(ngModel)]="inputModel"
      (ngModelChange)="inputModelChange.emit(inputModel)"
    />

    <fa-icon *ngIf="inputModel && inputModel.length > 0" [icon]="faTimes" class="close-icon" (click)="onClear.emit()"></fa-icon>
  </div>
</div>

<!--


      <div class="autocomplete-container">
        <div class="input-container">
          <input
            type="text"
            class="form-control mr-3"
            style="flex: 1"
            (keydown.enter)="clearDataAndsearchID()"
            [(ngModel)]="driver.driverCode"
          />
          <div class="x">
            <i class="material-icons">close</i>
          </div>
        </div>
      </div>
      
-->
