<div class="modal-header">
  <h4 class="modal-title">
    <span>{{ header }}</span>
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="onCancel.emit()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <p *ngIf="!isLoading">{{ subHeader }}</p>
  <app-spinner *ngIf="isLoading"></app-spinner>
</div>

<div class="modal-footer d-flex justify-content-between">
  <button type="submit" class="btn {{ cancelButtonClass }}" (click)="onCancel.emit()" [disabled]="isLoading">
    <ng-container>{{ cancelButtonText }}</ng-container>
  </button>
  <button class="btn {{ confirmButtonClass }}" [disabled]="isLoading" (click)="onConfirm.emit()">{{ confirmButtonText }}</button>
</div>
