import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { AuthService } from "../core/auth.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
  email: string;
  name: string;

  isWarehouseAdmin: boolean = false;

  dashboardUrl: string;

  encodeURIComponent = encodeURIComponent;

  constructor(public auth: AuthService) {
    this.dashboardUrl = environment.dashboardUrl;
  }

  ngOnInit() {
    this.auth.hasLoggedInUserLoaded$.subscribe((isDone) => {
      if (isDone) {
        this.name = this.auth.loggedInUser.name;
        this.email = this.auth.loggedInUser.email;
        this.isWarehouseAdmin = this.auth.IsWarehouseAdmin();
      }
    });
  }
}
