import { Component, Input, OnInit } from "@angular/core";
import { DriverContractType } from "../models/Contract";

@Component({
  selector: "app-render-contract-type",
  templateUrl: "./render-contract-type.component.html",
  styleUrls: ["./render-contract-type.component.css"],
})
export class RenderContractTypeComponent implements OnInit {
  DriverContractType = DriverContractType;

  @Input() contractType: DriverContractType = null;

  constructor() {}

  ngOnInit(): void {}
}
