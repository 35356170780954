<div class="container-fluid app-container arrival-table-container">
  <h1 class="app-heading">
    <ng-container i18n>Contract management</ng-container>
  </h1>

  <div class="d-flex align-items-center">
    <h4 i18n class="m-0">Contract languages</h4>
    <button class="btn btn-info ml-3" (click)="openAddLanguageModal(addLanguageModal)">
      <ng-container i18n>Add language</ng-container>
    </button>
  </div>

  <hr />

  <ng-container *ngIf="languages.length === 0">
    <p i18n>No languages yet. Please add it to create contracts.</p>
  </ng-container>

  <ng-container *ngIf="languages.length > 0">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th i18n>Name</th>
          <th i18n>Created at</th>
          <th i18n>Added by</th>
          <th i18n>Countries</th>
          <th i18n>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let language of languages">
          <td>{{ language.name }}</td>
          <td>{{ language.createdAt | date: "dd. MM. yyyy" }}</td>
          <td>{{ language.addedBy.name }}</td>
          <td>
            <ng-container *ngIf="!language.countries?.length" i18n> - </ng-container>
            <ng-container *ngIf="language.countries?.length > 0">
              <div *ngFor="let country of language.countries; let last = last">{{ country.name }}<ng-container *ngIf="!last">, </ng-container></div>
            </ng-container>
          </td>
          <td>
            <button class="btn btn-info mr-1" i18n (click)="openEditLanguageModal(editLanguageModal, language)">Edit</button>
            <button class="btn btn-danger ml-1" i18n (click)="openDeleteLanguageModal(deleteLanguageModal, language)">Delete</button>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>

  <div class="d-flex align-items-center mt-4">
    <h4 i18n class="m-0">Contract templates</h4>
    <button *ngIf="languages.length > 0" class="btn btn-info ml-3" (click)="openAddTemplateModal(addOrEditTemplateModal)">
      <ng-container i18n>Add contract template</ng-container>
    </button>
  </div>

  <hr />

  <ng-container *ngIf="languages.length === 0">
    <p i18n>Please add at least one contract language to add templates.</p>
  </ng-container>
  <ng-container *ngIf="languages.length > 0">
    <ng-container *ngIf="contractTemplates.length === 0">
      <p i18n>No templates yet.</p>
    </ng-container>

    <ng-container *ngIf="contractTemplates.length > 0">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th i18n>Name</th>
            <th i18n>Language</th>
            <th i18n>Type</th>
            <th i18n>Created at</th>
            <th i18n>Added by</th>
            <th i18n>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let template of contractTemplates">
            <td>{{ template.name }}</td>
            <td>{{ template.contractLanguage.name }}</td>
            <td>
              <ng-container *ngIf="template.contractType === DriverContractType.ShortTerm" i18n>Short term</ng-container>
              <ng-container *ngIf="template.contractType === DriverContractType.LongTerm" i18n>Long term</ng-container>
            </td>
            <td>{{ template.createdAt | date: "dd. MM. yyyy" }}</td>
            <td>{{ template.addedBy.name }}</td>
            <td>
              <button class="btn btn-secondary mr-1" i18n (click)="openContractTemplateFile(template)">View</button>
              <button class="btn btn-info mx-1" i18n (click)="openEditTemplateModal(addOrEditTemplateModal, template)">Edit</button>
              <button class="btn btn-danger ml-1" i18n (click)="openDeleteTemplateModal(deleteTemplateModal, template)">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </ng-container>
</div>

<ng-template #addLanguageModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" i18n>Add contract language</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss(false)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-group">
      <label for="name"><ng-container i18n="Name">Name</ng-container></label>
      <input
        type="text"
        placeholder="Enter language name"
        i18n-placeholder
        class="form-control"
        id="name"
        [(ngModel)]="newLanguageName"
        [ngModelOptions]="{ standalone: true }"
      />
    </div>

    <div class="form-group">
      <label class="mb-0"><ng-container i18n>Countries</ng-container></label>
      <small i18n class="d-block mb-1">This field influences how the language is automatically selected based on driver's company country.</small>
      <app-multi-country-input [(countries)]="newLanguageCountries"></app-multi-country-input>
    </div>
  </div>

  <div class="modal-footer d-flex justify-content-between">
    <button type="submit" class="btn" (click)="modal.close(false)">
      <ng-container i18n="Cancel">Cancel</ng-container>
    </button>
    <button type="submit" class="btn btn-primary" [disabled]="newLanguageName.trim().length === 0 || addingLanguageInProgress" (click)="addLanguage()">
      <ng-container i18n="Add">Add</ng-container>
    </button>
  </div>
</ng-template>

<ng-template #editLanguageModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" i18n>Edit contract language</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss(false)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-group">
      <label for="name"><ng-container i18n="Name">Name</ng-container></label>
      <input
        type="text"
        placeholder="Enter language name"
        i18n-placeholder
        class="form-control"
        id="name"
        [(ngModel)]="newLanguageName"
        [ngModelOptions]="{ standalone: true }"
      />
    </div>

    <div class="form-group">
      <label class="mb-0"><ng-container i18n>Countries</ng-container></label>
      <small i18n class="d-block mb-1">This field influences how the language is automatically selected based on driver's company country.</small>
      <app-multi-country-input [(countries)]="newLanguageCountries"></app-multi-country-input>
    </div>
  </div>

  <div class="modal-footer d-flex justify-content-between">
    <button type="submit" class="btn" (click)="modal.close(false)">
      <ng-container i18n="Cancel">Cancel</ng-container>
    </button>
    <button type="submit" class="btn btn-primary" [disabled]="newLanguageName.trim().length === 0 || editingLanguageInProgress" (click)="editLanguage()">
      <ng-container i18n="Edit">Edit</ng-container>
    </button>
  </div>
</ng-template>

<ng-template #deleteLanguageModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" i18n>Confirm deleting contract language {{ deletingLanguage?.name || "" }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss(false)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <p class="text-danger" i18n>Deleting the language will remove all associated contract templates. Already signed contracts will not be affected.</p>
  </div>

  <div class="modal-footer d-flex justify-content-between">
    <button type="submit" class="btn" (click)="modal.close(false)">
      <ng-container i18n="Cancel">Cancel</ng-container>
    </button>
    <button type="submit" class="btn btn-danger" [disabled]="deletingLanguageInProgress" (click)="deleteLanguage()">
      <ng-container i18n="Delete">Delete</ng-container>
    </button>
  </div>
</ng-template>

<!-- -->

<ng-template #addOrEditTemplateModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" i18n>{{ editingTemplate ? "Edit" : "Add" }} contract template</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss(false)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-group">
      <label for="name"><ng-container i18n="Name">Name</ng-container></label>
      <input
        type="text"
        placeholder="Enter template name"
        i18n-placeholder
        class="form-control"
        id="name"
        [(ngModel)]="newTemplate.name"
        [ngModelOptions]="{ standalone: true }"
      />
    </div>

    <div class="form-group">
      <label for="name"><ng-container i18n="Language">Language</ng-container></label>
      <select [(ngModel)]="newTemplate.languageId" id="type" name="type" class="form-control">
        <option *ngFor="let language of languages" [value]="language.id">
          <ng-container>{{ language.name }}</ng-container>
        </option>
      </select>
    </div>

    <div class="form-group">
      <label for="name"><ng-container i18n="Type">Type</ng-container></label>
      <select [(ngModel)]="newTemplate.type" id="type" name="type" class="form-control">
        <option [value]="DriverContractType.ShortTerm">
          <ng-container i18n>Short term</ng-container>
        </option>
        <option [value]="DriverContractType.LongTerm">
          <ng-container i18n>Long term</ng-container>
        </option>
      </select>
    </div>

    <div class="form-group">
      <label for="name"><ng-container i18n="File">File</ng-container></label>
      <input
        type="file"
        accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        (change)="newTemplate.contractFile = $event.target.files[0]"
        class="form-control-file"
      />
      <small *ngIf="editingTemplate" i18n
        >If you don't provide the file, the
        <span style="color: blue; cursor: pointer; text-decoration: underline" (click)="openContractTemplateFile(editingTemplate)">previous one</span>
        will be kept.</small
      >
    </div>

    <ng-container *ngIf="newTemplate.alreadyExistsError">
      <p class="text-danger" i18n>
        The contract with given type and language already exists. There can only be one contract of each type and language. If you wish to overwrite currently
        existing contract with this one, please check the box below and press "Add" again.
      </p>

      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="overwriteCheckbox" [(ngModel)]="newTemplate.overrideExistingContractTemplate" />
        <label class="form-check-label" for="overwriteCheckbox">
          <ng-container i18n>Overwrite current contract</ng-container>
        </label>
      </div>
    </ng-container>
  </div>

  <div class="modal-footer d-flex justify-content-between">
    <button type="submit" class="btn" (click)="modal.close(false)">
      <ng-container i18n="Cancel">Cancel</ng-container>
    </button>
    <button type="submit" class="btn btn-primary" [disabled]="!newTemplateInputValid() || addingOrEditingTemplateInProgress" (click)="addOrEditTemplate()">
      <ng-container i18n="Add" *ngIf="!editingTemplate">Add</ng-container>
      <ng-container i18n="Edit" *ngIf="editingTemplate">Edit</ng-container>
    </button>
  </div>
</ng-template>

<ng-template #deleteTemplateModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" i18n>Confirm deleting contract template {{ deletingTemplate?.name || "" }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss(false)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <p class="text-danger" i18n>Deleting the template will not remove any signed contracts.</p>
  </div>

  <div class="modal-footer d-flex justify-content-between">
    <button type="submit" class="btn" (click)="modal.close(false)">
      <ng-container i18n="Cancel">Cancel</ng-container>
    </button>
    <button type="submit" class="btn btn-danger" [disabled]="deletingTemplateInProgress" (click)="deleteTemplate()">
      <ng-container i18n="Delete">Delete</ng-container>
    </button>
  </div>
</ng-template>
