import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { MessageTranslationService } from "../message-translation.service";
import { BookedRampsDateFilter } from "../stats/stats-ramps-booking-time/stats-ramps-booking-time.component";
import { RestService } from "./rest.service";

export enum BookedRampsStatsDatePrecision {
  DAILY,
  WEEKLY,
  MONTHLY,
  YEARLY,
}

export interface BookedRampsStats {
  precision: BookedRampsStatsDatePrecision;
  data: BookedRampsStatsData[];
  totalBookingsSeconds: number;
}

interface BookedRampsStatsData {
  forDate: Date;
  totalBookingsSeconds: number;
  bookingsPerWarehouse: BookedRampsStatsPerWarehouse[];
}

interface BookedRampsStatsPerWarehouse {
  warehouseId: number;
  warehouseName: string;
  totalBookingsSeconds: number;
  bookingsPerWarehousePart: BookedRampsStatsPerWarehousePart[];
}

interface BookedRampsStatsPerWarehousePart {
  warehousePartId: number;
  warehousePartName: string;
  totalBookingsSeconds: number;
  bookingsPerRamp: BookedRampsStatsPerRamp[];
}

interface BookedRampsStatsPerRamp {
  rampId: number;
  rampName: string;
  totalBookingsSeconds: number;
  minBookingsSeconds: number;
  maxBookingsSeconds: number;
  avgBookingsSeconds: number;
}

@Injectable({
  providedIn: "root",
})
export class StatsService {
  constructor(private msgT: MessageTranslationService, private toast: ToastrService, private rest: RestService) {}

  public async getBookedRampsStats(filter: BookedRampsDateFilter): Promise<BookedRampsStats> {
    try {
      return await this.rest
        .post<BookedRampsStats>(`api/stats/getBookedRampsStats`, {
          precision: filter.precision,
          dateFrom: filter.dateFrom,
          dateTo: filter.dateTo,
        })
        .toPromise();
    } catch (e) {
      this.toast.error(this.msgT.unknownError());
    }

    return {
      data: [],
      precision: filter.precision,
      totalBookingsSeconds: 0,
    };
  }
}
