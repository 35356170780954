import { Warehouse } from "./WarehousePart";
import { Role } from "./Role";
import { Company } from "./Company";

export enum UserType {
  Admin,
  Warehouse,
  Carrier,
  Optimapi,
  GateManager,
  WarehouseManager,
}

export class User {
  id: number;
  email: string;
  name: string;
  passwordHasn: any;
  type: UserType;
  emailConfirmed: Date;
  confirmed: Date;
  confirmedBy: User;
  passwordReset: string;
  passwordResetTimeout: Date;
  registeredAt: Date;
  phoneNumber: string;
  defaultWarehouse: Warehouse;

  credentials: {
    password: string;
    confirmPassword: string;
  };

  originalData: {
    name: string;
  };

  isModified: boolean = false;
  filterString: string;

  roles: Role[];

  company: Company;
}
