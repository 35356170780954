import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AuthService } from "./core/auth.service";

export const isProductNotPermittedError = (err) => {
  return err.error && typeof err.error === "string" && err.error.includes("Product not permitted");
};

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err) => {
        if (isProductNotPermittedError(err)) {
          this.auth.navigateToProductNotPermitted();
        }

        return throwError(err);
      })
    );
  }
}
