import { RestService } from "../services/rest.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faCheck, faTimes, faUndo, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { ArrivalUtilsService } from "../arrival-utils.service";
import { AuthService } from "../core/auth.service";
import { MessageTranslationService } from "../message-translation.service";
import { Arrival } from "../models/Arrival";
import { ArrivalStatus } from "../models/ArrivalStatus";
import { Driver } from "../models/Driver";
import { ProcessingStatus } from "../models/ProcessingStatus";
import { Ramp } from "../models/Ramp";
import { Warehouse } from "../models/WarehousePart";
import { UserUtilsService } from "../user-utils.service";
import { contractExpiryColor, contractExpiryText } from "../utils/ArrivalUtils";
import { environment } from "src/environments/environment";
import * as moment from "moment";
import { DateService } from "../services/date.service";

@Component({
  selector: "app-warehouse-arrival",
  templateUrl: "./warehouse-arrival.component.html",
  styleUrls: ["./warehouse-arrival.component.css"],
})
export class WarehouseArrivalComponent implements OnInit {
  faCheck = faCheck;
  faTimes = faTimes;
  faExclamationTriangle = faExclamationTriangle;
  faUndo = faUndo;
  commentInput: string = "";
  ProcessingStatus = ProcessingStatus;
  arrival: Arrival = null;
  ArrivalStatus = ArrivalStatus;
  contractExpiryText = contractExpiryText;

  changeWarehouseId: number;

  freeRamps: Ramp[] = [];
  warehouses: Warehouse[] = [];

  freeRampsPerWarehouse: Record<string, Ramp[]> = {};

  showContract: boolean = false;

  constructor(
    private date: DateService,
    public auth: AuthService,
    private http: RestService,
    private route: ActivatedRoute,
    public arrivalUtils: ArrivalUtilsService,
    private toast: ToastrService,
    private msgT: MessageTranslationService,
    public userUtils: UserUtilsService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const id = params["id"];
      if (id) {
        this.http.get(`api/yardManagement/get/${id}`).subscribe(
          (arrival: Arrival) => {
            this.arrival = arrival;
            this.changeWarehouseId = this.arrival.warehouse.id;
            this.arrivalUtils.setTimezoneTimes(arrival);
          },
          (error) => {
            console.log("Error fetching arrival");
            console.log(error);
            this.toast.error(this.msgT.fetchArrivalError());
          }
        );
      }
    });

    this.fetchWarehouses();
    this.fetchFreeRamps();
  }

  fetchWarehouses() {
    this.http.get(`api/authentication/listWarehouses`).subscribe(
      (w: Warehouse[]) => {
        this.warehouses = w;
        this.updateFreeRampsPerWarehouse();
      },
      (error) => {
        console.log("Find warehouses error");
        console.log(error);
      }
    );
  }

  updateFreeRampsPerWarehouse() {
    if (!this.warehouses) {
      this.freeRampsPerWarehouse = {};
      return;
    }

    for (let w of this.warehouses) {
      this.freeRampsPerWarehouse[w.id] = this.freeRamps.filter((r) => r.warehousePart.warehouse.id === w.id);
    }
  }

  fetchFreeRamps() {
    this.http.get(`api/ramps/listFree`).subscribe(
      (ramps: Ramp[]) => {
        this.freeRamps = ramps;
        this.updateFreeRampsPerWarehouse();
      },
      (error) => {}
    );
  }

  public onProcessButtonClick() {
    let newStatus = null;

    if (this.arrival.status === ArrivalStatus.AtRamp) {
      newStatus = ArrivalStatus.UnloadingStarted;
    } else if (this.arrival.status === ArrivalStatus.UnloadingStarted || this.arrival.status === ArrivalStatus.OnHoldEnd) {
      newStatus = ArrivalStatus.UnloadingDone;
    } else if (this.arrival.status === ArrivalStatus.UnloadingDone || this.arrival.status === ArrivalStatus.ReturnedToStart) {
      newStatus = ArrivalStatus.Departed;
    }

    if (newStatus) {
      this.ChangeStatus(newStatus);
    }
  }

  public onHoldButtonClick() {
    if (!this.arrivalUtils.processingAllowed(this.arrival)) {
      return;
    }

    let newStatus = null;
    if (this.arrival.status === ArrivalStatus.UnloadingStarted) {
      newStatus = ArrivalStatus.OnHold;
    } else if (this.arrival.status === ArrivalStatus.OnHold) {
      newStatus = ArrivalStatus.OnHoldEnd;
    }

    if (newStatus) {
      this.ChangeStatus(newStatus);
    }
  }

  public onReturnedToStartClick() {
    if (!this.arrivalUtils.processingAllowed(this.arrival)) {
      return;
    }

    let newStatus = null;
    if (this.arrival.status === ArrivalStatus.OnHold) {
      newStatus = ArrivalStatus.ReturnedToStart;
    }

    if (newStatus) {
      this.ChangeStatus(newStatus);
    }
  }

  ChangeStatus(status: ArrivalStatus, earlyDepartureReason: string = null) {
    this.http
      .post(`api/yardManagement/changeStatus/${this.arrival.id}`, {
        status,
        userId: this.auth.userId,
        earlyDepartureReason,
      })
      .subscribe(
        () => {
          this.toast.success(this.msgT.changeStatusSuccess());
          this.ngOnInit();
        },
        (error) => {
          console.log("Error changing status");
          console.log(error);
          this.toast.error(this.msgT.changeStatusError());
        }
      );
  }

  public onMarkContractUploadedClick() {
    this.http
      .post(`api/yardManagement/markContractUploaded/${this.arrival.id}`, {
        userId: this.auth.userId,
      })
      .subscribe(
        () => {
          this.ngOnInit();
          this.toast.success(this.msgT.markContractSuccess());
        },
        (error) => {
          console.log("Error marking contract");
          console.log(error);
          this.toast.error(this.msgT.markContractError());
        }
      );
  }

  public onViewContractClick(driver: Driver) {
    window.open(`api/driver/getContract/${driver.id}`, "_blank");
  }

  public onRefreshClick() {
    this.ngOnInit();
  }

  public onRampSelectChange(value) {
    const ramp = this.freeRamps.find((r) => r.id.toString() === value);
    this.arrival.selectedRamp = ramp;
  }

  public applyRampSelect(arrival: Arrival) {
    if (!arrival.selectedRamp) {
      return;
    }

    this.http
      .post(`api/yardManagement/assignToRamp/${arrival.id}`, {
        rampId: arrival.selectedRamp.id,
        userId: this.auth.userId,
      })
      .subscribe(
        (_: Arrival) => {
          this.ngOnInit();
        },
        (error) => {
          console.log("Update arrival error");
          console.log(error);
          this.toast.error(this.msgT.updateArrivalError());
        }
      );
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" }).result.then(
      (result: boolean | null) => {},
      (_) => {}
    );
  }

  public RemoveArrival() {
    this.modalService.dismissAll();

    this.http.post(`api/yardManagement/deleteArrival/${this.arrival.id}`, {}).subscribe(
      (_: boolean) => {
        this.toast.success(this.msgT.deleteArrivalSuccess());
        this.router.navigate(["/warehouse-history"]);
      },
      (error) => {
        console.log("Update arrival error");
        console.log(error);
        if (error && error.error && error.error.includes("Arrival has a ramp")) {
          this.toast.error(this.msgT.deleteArrivalErrorArrivalHasRamp());
        } else {
          this.toast.error(this.msgT.deleteArrivalError());
        }
      }
    );
  }

  public onUndoButtonClick() {
    if (!this.arrivalUtils.undoAllowed(this.arrival)) {
      return;
    }

    this.http
      .post(`api/yardManagement/undoStatus/${this.arrival.id}`, {
        userId: this.auth.userId,
      })
      .subscribe(
        (arrival: Arrival) => {
          if (arrival) {
            this.ngOnInit();
            this.toast.success(this.msgT.undoStatusSuccess());
          }
        },
        (error) => {
          console.log("Undo status error");
          console.log(error);
          this.toast.error(this.msgT.undoStatusError());
        }
      );
  }

  openEarlyDepartureModal(content) {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" }).result.then(
      (_) => {},
      (_) => {}
    );
  }

  public onEarlyDepartureClick() {
    if (!this.arrivalUtils.earlyDepartureAllowed(this.arrival)) {
      return;
    }

    let newStatus = null;
    if (this.arrival.status === ArrivalStatus.Arrived) {
      newStatus = ArrivalStatus.EarlyDeparture;
    }

    if (newStatus) {
      this.ChangeStatus(newStatus, this.arrival.earlyDepartureReason);
      this.modalService.dismissAll();
    }
  }
  public onSaveCommentClick() {
    this.http
      .post(`api/yardManagement/addArrivalComment/${this.arrival.id}`, {
        comment: this.commentInput,
      })
      .subscribe(
        (_: Arrival) => {
          this.ngOnInit();
          this.modalService.dismissAll();
        },
        (error) => {
          console.log("Update arrival error");
          console.log(error);
          this.toast.error(this.msgT.updateArrivalError());
        }
      );
  }

  public canChangeWarehouse() {
    if (this.arrival.status === ArrivalStatus.Arrived && this.auth.IsWarehouseAdmin()) {
      return true;
    }

    return false;
  }

  openChangeWarehouseModal(content) {
    if (!this.canChangeWarehouse()) {
      return;
    }

    this.changeWarehouseId = this.arrival.warehouse.id;

    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" }).result.then(
      (_) => {},
      (_) => {}
    );
  }

  saveChangedWarehouse() {
    if (!this.changeWarehouseId) {
      return;
    }

    this.http
      .post(`api/yardManagement/changeWarehouseForArrival/${this.arrival.id}`, {
        warehouseId: this.changeWarehouseId,
      })
      .subscribe(
        (updatedArrival: Arrival) => {
          this.arrival.warehouse = updatedArrival.warehouse;
          this.toast.success(this.msgT.updateArrivalSuccess());
          this.modalService.dismissAll();
        },
        (error) => {
          console.log("Update arrival error");
          console.log(error);
          this.toast.error(this.msgT.updateArrivalError());
        }
      );
  }

  navigateToReservation() {
    let linkPart = "reservation";
    if (this.arrival.isReservationRecurring === true) {
      linkPart = "reservation-recurring";
    }

    window.open(`${environment.owlUrl}/${linkPart}/${this.arrival.reservationId}/${this.arrival.reservationCode}`, "_blank");
  }

  getDurationOnRamp(start: Date, end: Date) {
    if (!(start instanceof Date)) {
      return "0 min";
    }

    if (!(end instanceof Date)) {
      return "0 min";
    }

    return this.date.humanizeDuration(moment(end).diff(start, "milliseconds"));
  }

  public contractExpiryColor(driver: Driver) {
    contractExpiryColor(driver, this.auth.loggedInUser.company);
  }
}
