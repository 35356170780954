import {
  NgSignaturePadOptions,
  SignaturePadComponent,
} from "@almothafar/angular-signature-pad";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { fromEvent, Observable, Subscription } from "rxjs";

@Component({
  selector: "app-signature-screen",
  templateUrl: "./signature-screen.component.html",
  styleUrls: ["./signature-screen.component.css"],
})
export class SignatureScreenComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild(SignaturePadComponent)
  signaturePad: SignaturePadComponent;

  signaturePadOptions: NgSignaturePadOptions = {
    canvasWidth: 0,
    canvasHeight: 0,
    maxWidth: 5,
    minWidth: 3,
    backgroundColor: "white",
  };

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  isLoaded = false;

  @Output()
  drawFinish: EventEmitter<File> = new EventEmitter<File>();

  @ViewChild("container") container: ElementRef;

  constructor() {}

  ngOnInit(): void {
    this.resizeObservable$ = fromEvent(window, "resize");
    this.resizeSubscription$ = this.resizeObservable$.subscribe((evt) => {
      this.resizeCanvas();
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.resizeCanvas();
      this.isLoaded = true;
    }, 1000);
  }

  ngOnDestroy() {
    this.resizeSubscription$.unsubscribe();
  }

  resizeCanvas() {
    this.signaturePad.set(
      "canvasWidth",
      this.container.nativeElement.offsetWidth
    );
    this.signaturePad.set(
      "canvasHeight",
      this.container.nativeElement.offsetHeight
    );
  }

  drawComplete() {
    this.signaturePad.getCanvas().toBlob((blob) => {
      this.drawFinish.emit(new File([blob], "signature.png"));
    });
  }

  clear() {
    this.signaturePad.clear();
    this.drawFinish.emit(null);
  }
}
